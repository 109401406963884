import { lazy } from 'react';
import { NavLink } from '../../config/navigation';
import { UserRoute } from './user/UserRoute';
const Position = lazy(() => import('./position/Position'));
const Company = lazy(() => import('./company/Company'));
const Department = lazy(() => import('./department/Department'));
const RoleAndPermission = lazy(
  () => import('./role_and_permission/RoleAndPermission')
);

export const settingLink = {
  company: '/setting/companies',
  department: '/setting/departments',
  position: '/setting/positions',
  user: '/setting/users',
  role_and_permission: '/setting/role_and_permissions',
};

export const SettingRoute = [
  {
    path: '/setting/',
    children: [
      {
        path: 'companies',
        element: <Company />,
      },
      {
        path: 'departments',
        element: <Department />,
      },
      {
        path: 'positions',
        element: <Position />,
      },
      ...UserRoute,
      {
        path: 'role_and_permissions',
        element: <RoleAndPermission />,
      },
    ],
  },
];

export const SettingNavigator: NavLink[] = [
  {
    label: 'setting',
    name: 'setting_menu',
    domain: '*',
    item: [
      {
        label: 'company',
        link: settingLink.company,
        name: settingLink.company,
        domain: '*',
      },
      {
        label: 'department',
        link: settingLink.department,
        name: settingLink.department,
        domain: '*',

      },
      {
        label: 'position',
        link: settingLink.position,
        name: settingLink.position,
        domain: '*',
      },
      {
        label: 'user',
        link: settingLink.user,
        name: '/setting/users',
        domain: '*',
      },
      {
        label: 'role_and_permission',
        link: settingLink.role_and_permission,
        name: '/setting/role_and_permissions',
        domain: '*',
      },
    ],
  },
];
