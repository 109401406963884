import {
  InboxActions,
  InboxActionTypes,
  IInboxState,
  IFileState,
  FileActionTypes,
  FileActions,
} from './type';

export const initialInboxState: IInboxState = {
  inboxes: [],
  category: {
    external_documents: undefined,
    advance_clearance_documents: undefined,
    advance_request_documents: undefined,
    payment_voucher_documents: undefined,
    purchase_order_documents: undefined,
  },
  loading: false,
};

export const inboxReducer = (
  state = initialInboxState,
  action: InboxActions
): IInboxState => {
  switch (action.type) {
    case InboxActionTypes.GET_INBOX:
      return {
        ...state,
        loading: true,
      };
    case InboxActionTypes.GET_INBOX_SUCCESS:
      return {
        ...state,
        inboxes: action.payload,
        loading: false,
      };
    case InboxActionTypes.GET_INBOX_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case InboxActionTypes.GET_INBOX_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        category: { ...action.payload },
      };
    default:
      return state;
  }
};

export const initialFileState: IFileState = {
  file: undefined,
};

export const fileReducer = (
  state = initialFileState,
  action: FileActions
): IFileState => {
  switch (action.type) {
    case FileActionTypes.GET_FILE_SUCCESS:
      return {
        ...state,
        file: action.payload,
      };
    default:
      return state;
  }
};
