import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { IDocoutState } from '../../../store/docout';
import { formatDateToInformal } from '../../../Utils';
import { DetailList, ListType } from './DetailList';

export const ExternalDetail: React.FC = () => {
  const { t } = useTranslation(['document', 'button']);
  const [data, setData] = React.useState<ListType[]>([]);
  const [data2, setData2] = React.useState<ListType[]>([]);
  const {
    category_detail: { external_documents },
  } = useSelector<AppState, IDocoutState>((state) => state.docout);
  React.useEffect(() => {
    let data: ListType[] = [],
      data2: ListType[] = [];
    if (external_documents && Object.values(external_documents).length > 0) {
      data.push(
        {
          id: 1,
          title: t('from_company'),
          content: external_documents.from_company.name,
        },
        {
          id: 2,
          title: t('created_by'),
          content: external_documents.created_by_name,
        },
        {
          id: 3,
          title: t('document_date'),
          content: formatDateToInformal(external_documents.document_date),
        },
        {
          id: 4,
          title: t('reference_no'),
          content: external_documents.doc_out.reference_no,
        },
        {
          id: 5,
          title: t('document_category'),
          content: external_documents.document_category.name,
        }
      );
      setData(data);
      data2.push(
        {
          id: 1,
          title: t('document_from'),
          content: external_documents.document_from,
        },
        {
          id: 2,
          title: t('document_refer'),
          content: external_documents.document_refer,
        },
        {
          id: 3,
          title: t('document_title'),
          content: external_documents.document_title,
        },
        {
          id: 4,
          title: t('contact_number'),
          content: external_documents.contact_number,
        }
      );
      setData2(data2);
    }
  }, [external_documents, t]);
  return (
    <>
      <DetailList data={data} data2={data2} />
    </>
  );
};
