import * as types from './type';
export const docinInitialState: types.IDocinState = {
  category: {
    external_documents: [],
    advance_clearance_documents: [],
    advance_request_documents: [],
    payment_voucher_documents: [],
    purchase_order_documents: [],
  },
  category_detail: {},
  loading: false,
};
export const docinReducer = (
  state = docinInitialState,
  action: types.DocinActions
): types.IDocinState => {
  switch (action.type) {
    case types.DocinActionTypes.GET_DOCIN_BY_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case types.DocinActionTypes.GET_DOCIN_BY_CATEGORY_SUCCESS:
      
      return {
        ...state,
        category: { ...state.category, ...action.payload },
        loading: false,
      };
    case types.DocinActionTypes.RECEIVE_DOCIN_BY_DOCUMENT_DOCIN:
      return {
        ...state,
        loading: true,
      };
    case types.DocinActionTypes.PATCH_DOCIN_STATUS:
      return {
        ...state,
        loading: true,
      };
    case types.DocinActionTypes.GET_DOCIN_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case types.DocinActionTypes.GET_DOCIN_BY_ID_SUCCESS:
      const payload = action.payload;
      if (payload.payment_voucher_documents) {
        payload.payment_voucher_documents.payment_voucher_document_items.forEach(
          (item) => {
            item.sub_total =
              item.amount * payload.payment_voucher_documents.amount;
            item.price = payload.payment_voucher_documents.amount;
          }
        );
      }
      if (payload.advance_clearance_documents) {
        payload.advance_clearance_documents.advance_clearance_document_items.forEach(
          (item) => {
            item.currency_code =
              payload.advance_clearance_documents.currency.code;
          }
        );
      }
      return {
        ...state,
        category_detail: { ...payload },
        loading: false,
      };

    case types.DocinActionTypes.POST_DOCIN_TO_DOCOUT:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
