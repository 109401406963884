import * as React from 'react';
import { Col, List, Row } from 'antd';

export interface ListType {
  id: number;
  title: string;
  content: React.ReactNode;
}
interface Props {
  data: ListType[];
  data2: ListType[];
}
export const DetailList: React.FC<Props> = ({ data, data2 }) => {
  return (
    <Row gutter={24}>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta title={`${item.title} :`} />
              <List.Item.Meta title={item.content} />
            </List.Item>
          )}
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <List
          itemLayout="horizontal"
          dataSource={data2}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta title={`${item.title} :`} />
              <List.Item.Meta title={item.content} />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};
