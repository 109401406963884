import * as React from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Modal,
} from 'antd';
import { LaoITDevCardAdd } from '../../utility';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import {
  getDocumentRanksAction,
  IDocumentState,
} from '../../../store/document';
import moment from 'moment';
import { dateInformal } from '../../../Utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { IDocinState } from '../../../store/docin';
import {
  ErrorState,
  handleError,
  handleSuccess,
  initialSuccessState,
  SuccessState,
} from '../../../store/utility';
import { history } from '../../../config/configureStore';
import { patchExternalDocinAction } from "../../../store/docin/action";
const { Item } = Form;
const { Option } = Select;
const { confirm } = Modal;
export const EditExternal: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { docin_id, category } = useParams<{ docin_id: string, category: string }>();
  const { t } = useTranslation(['document', 'button']);

  const [submit, setSubmit] = React.useState(false);

  const { document_rank } = useSelector<AppState, IDocumentState>(
    ({ document }) => document
  );
  const {
    category_detail: { external_documents },
  } = useSelector<AppState, IDocinState>((state) => state.docin);
  const submitSuccess = useSelector<AppState, SuccessState>(
    ({ success }) => success
  );
  const errorOnSubmit = useSelector<AppState, ErrorState>(({ error }) => error);

  const handleFinish = (value) => {
    confirm({
      title: t('confirm_submit_document'),
      icon: <ExclamationCircleOutlined />,
      okText: t('yes', { ns: 'button' }),
      cancelText: t('no', { ns: 'button' }),
      onOk() {
        setSubmit(true);
        if (category && docin_id && external_documents) {
          dispatch(patchExternalDocinAction(value, category, external_documents.id, docin_id));
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  React.useEffect(() => {
    dispatch(getDocumentRanksAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (document_rank.document_ranks.length > 0) {
      form.setFieldsValue({
        document_rank_id: document_rank.document_ranks[0].id,
      });
    }
  }, [form, document_rank]);

  React.useEffect(() => {
    if (submitSuccess.status && submitSuccess.name === 'EDIT_EXTERNAL_DOCIN') {
      dispatch(
        handleSuccess({
          type: 'SET_SUCCESS',
          payload: initialSuccessState,
        })
      );
      history.push('docin');
    }
    if (errorOnSubmit.status && errorOnSubmit.name === 'EDIT_EXTERNAL_DOCIN') {
      dispatch(
        handleError({
          type: 'SET_ERROR',
          payload: initialSuccessState,
        })
      );
    }
    setSubmit(false);
  }, [dispatch, submitSuccess, errorOnSubmit]);

  React.useEffect(() => {
    form.setFieldsValue({
      document_date: moment(external_documents?.document_date),
      document_rank_id: external_documents?.document_rank.id,
      contact_number: external_documents?.contact_number,
      document_refer: external_documents?.document_refer,
      document_title: external_documents?.document_title,
    });
  }, [external_documents, dispatch, form]);

  return (
    <>
      <LaoITDevCardAdd title={t('edit_external_document')}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" plain>
            {t('external_document_list')}
          </Divider>
          <Row gutter={[24, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('document_date')}
                name="document_date"
                required
                initialValue={moment()}
                rules={[
                  { required: true, message: t('document_date_required') },
                ]}
              >
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_document_date')}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('document_rank')} name="document_rank_id">
                <Select>
                  {document_rank &&
                    document_rank.document_ranks.map((document_rank) => (
                      <Option key={document_rank.id} value={document_rank.id}>
                        {document_rank.name}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('phone')} name="contact_number">
                <Input placeholder={t('input_phone')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('document_from')} name="document_from">
                <Input placeholder={t('input_document_from')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('document_refer')} name="document_refer">
                <Input placeholder={t('input_document_refer')} />
              </Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[24, 0]}>
            <Col xxl={12} lg={12} md={12} sm={24} xs={24}>
              <Item label="ເລື່ອງ" name="document_title">
                <Input.TextArea placeholder="ປ້ອນເລື່ອງ" />
              </Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col>
              <Button onClick={() => history.back()} size="large" loading={submit}>
                {t('cancel', { ns: 'button' })}
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={submit}
              >
                {t('save', { ns: 'button' })}
              </Button>
            </Col>
          </Row>
        </Form>
      </LaoITDevCardAdd>
    </>
  );
};
