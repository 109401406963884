import * as types from './type';
import * as services from './../../service/Inbox';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  getFileSuccessAction,
  getInboxByIdSuccessAction,
  getInboxSuccessAction,
} from './action';
import { AppState } from '..';
import { channel } from 'redux-saga';
import { subscribeErrorMessage, subscribeSuccessMessage } from '../saga';
function* handleGetInbox({ payload }: types.IGetInboxAction) {
  try {
    const { status, data } = (yield call(services.getInbox, payload.query_params)) as AxiosResponse<
      types.IInbox[]
    >;
    if (status === 200) {
      yield put(getInboxSuccessAction(data));
    }
  } catch (error) { }
}

function* handleGetInboxById(action: types.IGetDocumentByIdAction) {
  try {
    const { status, data } = (yield call(
      services.getInboxById,
      action.payload.id,
      action.payload.category_id
    )) as AxiosResponse<types.IInbox>;
    if (status === 200) {
      const category = (yield select(
        (app: AppState) => app.inbox.category
      )) as types.IInboxCategory;
      const newCategory = {
        ...category,
        [data.document_category.table_forms]: data,
      };
      yield put(getInboxByIdSuccessAction(newCategory));
    }
  } catch (error) { }
}

function* handlePatchInboxById({ payload }: types.IPatchInboxByIdAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchInboxByID,
      payload.id,
      payload.sign_status
    )) as AxiosResponse<types.IInbox>;
    if (status === 200) {
      const category = (yield select(
        (app: AppState) => app.inbox.category
      )) as types.IInboxCategory;
      const obj = category[payload.table_forms];
      if (obj) {
        obj.sign_status = payload.sign_status;
      }
      const newCategory = { ...category, [payload.table_forms]: obj };
      yield put(getInboxByIdSuccessAction(newCategory));
      yield put(chan, { name: 'SIGN_INBOX', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* watchHandlerGetInbox() {
  yield takeLatest(types.InboxActionTypes.GET_INBOX, handleGetInbox);
}

function* watchHandlerGetInboxById() {
  yield takeLatest(types.InboxActionTypes.GET_INBOX_BY_ID, handleGetInboxById);
}

function* watchHandlerPatchInboxById() {
  yield takeLatest(
    types.InboxActionTypes.PATCH_INBOX_BY_ID,
    handlePatchInboxById
  );
}

function* handleDeleteFile({ payload }: types.IDeleteFileAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.deleteFile,
      payload
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'DELETE_FILE', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostFile({ payload }: types.IPostFileAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status, data } = (yield call(
      services.postFile,
      payload
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_FILE', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(getFileSuccessAction(data));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* watchHandlerDeleteFile() {
  yield takeLatest(types.FileActionTypes.DELETE_FILE, handleDeleteFile);
}
function* watchHandlerPostFile() {
  yield takeLatest(types.FileActionTypes.POST_FILE, handlePostFile);
}

export function* inboxSaga() {
  yield all([
    fork(watchHandlerGetInbox),
    fork(watchHandlerGetInboxById),
    fork(watchHandlerPatchInboxById),
    fork(watchHandlerDeleteFile),
    fork(watchHandlerPostFile),
  ]);
}
