import * as types from './type';
export const getCommentSuccessAction = (
  payload: types.IGetCommentSuccessAction['payload']
) => ({
  type: types.CommentActionsType.GET_COMMENT_SUCCESS,
  payload,
});
export const postCommentTextAction = (
  payload: types.IPostCommentTextAction['payload']
): types.IPostCommentTextAction => ({
  type: types.CommentActionsType.POST_COMMENT_TEXT,
  payload,
});
