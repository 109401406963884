export interface ExternalUserId {
  external_id: string;
  external_id_hash: string;
}

export interface OneSignalState {
  data?: ExternalUserId;
  loading: boolean;
}

export enum OneSignalActionTypes {
  GET_EXTERNAL_USER_ID = '@onesignal/GET_EXTERNAL_USER_ID',
  GET_EXTERNAL_USER_ID_SUCCESS = '@onesignal/GET_EXTERNAL_USER_ID_SUCCESS',
}

export interface IGetExternalAction {
  type: OneSignalActionTypes.GET_EXTERNAL_USER_ID;
}
export interface IGetExternalSuccessAction {
  type: OneSignalActionTypes.GET_EXTERNAL_USER_ID_SUCCESS;
  payload: ExternalUserId;
}

export type OneSignalActions =
  | IGetExternalAction
  | IGetExternalSuccessAction;
