import * as React from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  DatePicker,
  Select,
  InputNumber,
  Modal,
} from 'antd';
import { LaoITDevCardAdd } from '../../utility';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import {
  getCurrenciesAction,
  IDocumentState,
  getDocumentRanksAction,
} from './../../../store/document';
import moment from 'moment';
import { dateInformal, formatMoney } from '../../../Utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { IPostAdvanceRequest } from '../../../store/docout';
import {
  handleSuccess,
  initialSuccessState,
  SuccessState,
  ErrorState,
  handleError,
} from '../../../store/utility';
import { history } from '../../../config/configureStore';
import { postAdvanceRequestDocinAction } from '../../../store/docin';
const { Item } = Form;
const { Option } = Select;
const { confirm } = Modal;
export const AddAdvanceRequest: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['document', 'button']);
  const [total, setTotal] = React.useState(0);
  const [submit, setSubmit] = React.useState(false);
  const { id } = useParams<{ id }>();
  const dispatch = useDispatch();
  const { currency, document_rank } = useSelector<AppState, IDocumentState>(
    ({ document }) => document
  );
  const submitSuccess = useSelector<AppState, SuccessState>(
    ({ success }) => success
  );
  const errorOnSubmit = useSelector<AppState, ErrorState>(({ error }) => error);
  const handleBlurAmount = (): void => {
    const { borrow_money_addition, borrow_money_before } =
      form.getFieldsValue();
    const total = borrow_money_before + borrow_money_addition;
    setTotal(total);
  };
  const handleFinish = (value: IPostAdvanceRequest) => {
    confirm({
      title: t('confirm_submit_document'),
      icon: <ExclamationCircleOutlined />,
      okText: t('yes', { ns: 'button' }),
      cancelText: t('no', { ns: 'button' }),
      onOk() {
        setSubmit(true);
        if (id) {
          dispatch(postAdvanceRequestDocinAction(id, value));
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  React.useEffect(() => {
    dispatch(getCurrenciesAction());
    dispatch(getDocumentRanksAction());
  }, [dispatch]);
  React.useEffect(() => {
    if (currency.currencies.length > 0) {
      form.setFieldsValue({
        currency_id: currency.currencies[0].id,
      });
    }
    if (document_rank.document_ranks.length > 0) {
      form.setFieldsValue({
        document_rank_id: document_rank.document_ranks[0].id,
      });
    }
  }, [currency, form, document_rank]);
  React.useEffect(() => {
    if (
      submitSuccess.status &&
      submitSuccess.name === 'ADD_ADVANCE_REQUEST_DOCIN'
    ) {
      dispatch(
        handleSuccess({
          type: 'SET_SUCCESS',
          payload: initialSuccessState,
        })
      );
      history.push('/docin');
    }
    if (
      errorOnSubmit.status &&
      errorOnSubmit.name === 'ADD_ADVANCE_REQUEST_DOCIN'
    ) {
      dispatch(
        handleError({
          type: 'SET_ERROR',
          payload: initialSuccessState,
        })
      );
    }
    setSubmit(false);
  }, [dispatch, submitSuccess, errorOnSubmit]);

  return (
    <>
      <LaoITDevCardAdd title={t('add_advance_request')}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" plain>
            {t('advance_request_detail')}
          </Divider>
          <Row gutter={[24, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('document_date')}
                name="document_date"
                required
                initialValue={moment()}
                rules={[
                  { required: true, message: t('document_date_required') },
                ]}>
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_document_date')}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('document_rank')} name="document_rank_id">
                <Select>
                  {document_rank &&
                    document_rank.document_ranks.map((document_rank) => (
                      <Option key={document_rank.id} value={document_rank.id}>
                        {document_rank.name}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            {/* <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                name="payment_method"
                label={t('payment_method')}
                initialValue={'cash'}
                required
              >
                <Select>
                  {payment_methods.map((payment_method) => (
                    <Option
                      key={payment_method.value}
                      value={payment_method.value}
                    >
                      {t(payment_method.label)}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col> */}
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('advance_request_amount')}
                name="amount"
                initialValue={0}
                rules={[{ required: true, message: t('amount_required') }]}>
                <InputNumber
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  style={{ width: '100%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  placeholder={t('input_advance_request_amount')}
                />
              </Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('repayment_date')}
                name="repayment_date"
                >
                <DatePicker
                  placeholder={t('input_repayment_date')}
                  style={{ width: '100%' }}
                  format={dateInformal}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('condition_1')} name="condition_1">
                <Input placeholder={t('input_condition_1')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('condition_2')} name="condition_2">
                <Input placeholder={t('input_condition_2')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('condition_3')} name="condition_3">
                <Input placeholder={t('input_condition_3')} />
              </Item>
            </Col>
          </Row>
          <Divider />
          <Col xxl={10} lg={10} md={12} sm={24} xs={24}>
            <Item label={t('advance_request_reason')} name="reason">
              <Input.TextArea placeholder={t('input_advance_request_reason')} />
            </Item>
          </Col>
          <Divider />
          <Row>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item>{t('currency')}</Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={24} xs={24}>
              <Item name="currency_id" required>
                <Select>
                  {currency &&
                    currency.currencies.map((currency) => (
                      <Option key={currency.id} value={currency.id}>
                        {currency.code}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item>{t('borrow_money_before')}</Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                initialValue={0}
                name="borrow_money_before"
                rules={[{ required: true, message: t('amount_required') }]}>
                <InputNumber
                  onBlur={handleBlurAmount}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  style={{ width: '100%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  placeholder={t('quantity')}
                />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item>{t('borrow_money_addition')}</Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                name="borrow_money_addition"
                rules={[{ required: true, message: t('amount_required') }]}
                initialValue={0}>
                <InputNumber
                  onBlur={handleBlurAmount}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  style={{ width: '100%' }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  placeholder={t('quantity')}
                />
              </Item>
            </Col>
          </Row>
          <Divider style={{ margin: '24px 0 5px 0' }} />
          <div className="summary-payment">
            <Row gutter={[16, 0]}>
              <Col xxl={6} lg={6} md={8} sm={16} xs={24}>
                <Item>{t('total')}</Item>
              </Col>
              <Col xxl={6} lg={6} md={8} sm={16} xs={24}>
                <Item>{formatMoney(total)}</Item>
              </Col>
            </Row>
          </div>
          <Divider />
          <Row justify="space-between">
            <Col>
              <Button
                onClick={() => history.back()}
                size="large"
                loading={submit}>
                {t('cancel', { ns: 'button' })}
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={submit}>
                {t('save', { ns: 'button' })}
              </Button>
            </Col>
          </Row>
        </Form>
      </LaoITDevCardAdd>
    </>
  );
};
