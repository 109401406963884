import { lazy } from 'react';
const UserProfile = lazy(() => import('./UserProfile'));
const EditProfile = lazy(() => import('./EditProfile'));
const SignatureUpload = lazy(() => import('./EditSignature'));
const ChangePassword = lazy(() => import('./ChangePassword'));

export const profileLink = {
  profile: '/profile',
  edit_profile: '/profile/edit_profile',
  edit_signature: '/profile/edit_signature',
  change_password: '/profile/change_password',
};

export const ProfileRoute = [
  {
    path: '/profile/',
    children: [
      { path: '', element: <UserProfile /> },
      { path: 'edit_profile', element: <EditProfile /> },
      { path: 'edit_signature', element: <SignatureUpload /> },
      { path: 'change_password', element: <ChangePassword /> },
    ]
  },
];
