export type TableForms =
  | 'advance_request_documents'
  | 'payment_voucher_documents'
  | 'purchase_order_documents'
  | 'advance_clearance_documents'
  | 'external_documents';
export interface ICategory {
  id: string;
  name: string;
  table_forms: TableForms;
}

export interface ICategoryState {
  categories: ICategory[];
  loading: boolean;
}

export enum CategoryActionTypes {
  GET_CATEGORIES = '@categories/GET',
  GET_CATEGORIES_SUCCESS = '@categories/GET_SUCCESS',
}

export interface IGetCategoriesAction {
  type: CategoryActionTypes.GET_CATEGORIES;
}
export interface IGetCategoriesSuccessAction {
  type: CategoryActionTypes.GET_CATEGORIES_SUCCESS;
  payload: ICategory[];
}

export type CategoryActions =
  | IGetCategoriesAction
  | IGetCategoriesSuccessAction;
