import { axiosInstant } from '../axiosInstant';
import { ICompany } from '../store/company/type';

export const fetchCompany = () =>
  axiosInstant.get('/companies').then((res) => res);

export const saveCompany = (data: ICompany) =>
  axiosInstant
    .post('/companies', {
      name: data.name,
      docin_prefix: data.docin_prefix,
      docout_prefix: data.docout_prefix,
    })
    .then((res) => res);

export const updateCompany = (data: ICompany) =>
  axiosInstant
    .put(`/companies/${data.id}`, {
      name: data.name,
      docin_prefix: data.docin_prefix,
      docout_prefix: data.docout_prefix,
    })
    .then((res) => res);

export const updateStatusCompany = (data: ICompany) =>
  axiosInstant
    .patch(
      `/companies/${data.id}/status/${
        data.status === 'ACTIVE' ? 'inactive' : 'active'
      }`,
      data
    )
    .then((res) => res);
