import * as React from 'react';
import { NavLink } from '../../config/navigation';

const Docin = React.lazy(() => import('./docin/Docin'));
const Docout = React.lazy(() => import('./docout/Docout'));
const DocinDetail = React.lazy(() => import('./docin/detail/Detail'));
const DocoutDetail = React.lazy(() => import('./docout/detail/Detail'));

export const archiveLink = {
  docin: '/archive/docin',
  docout: '/archive/docout',
};

export const archiveRoute = [
  {
    path: '/archive/',
    children: [
      {
        path: 'docin/',
        children: [
          {
            path: '',
            element: <Docin />,
          },
          {
            path: ':id/detail/:category/category/:table_form',
            element: <DocinDetail />,
          },
        ],
      },
      {
        path: 'docout/',
        children: [
          {
            path: '',
            element: <Docout />,
          },
          {
            path: ':id/detail/:category/category/:table_form',
            element: <DocoutDetail />,
          },
        ],
      },
    ],
  },
];

export const ArchiveNavigator: NavLink[] = [
  {
    label: 'archive',
    name: 'archive_menu',
    domain: 'archive',
    item: [
      {
        label: 'docin',
        link: archiveLink.docin,
        name: archiveLink.docin,
        domain: 'archive',
      },
      {
        label: 'docout',
        link: archiveLink.docout,
        name: archiveLink.docout,
        domain: 'archive',
      },
    ],
  },
];
