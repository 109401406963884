import { Table, Typography } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { formatDateToInformal, formatMoney } from '../../../Utils';
import { DetailList, ListType } from './DetailList';
import { LaoITDevTable, LaoITDevColumn } from '../../utility';
import { IPurchaseOrderDocumentItem } from '../../../store/document/type';
import { IDocoutState } from '../../../store/docout';
const { Text } = Typography;
export const PurchaseOrderDetail: React.FC = () => {
  const { t } = useTranslation(['document', 'button']);
  const [data, setData] = React.useState<ListType[]>([]);
  const [data2, setData2] = React.useState<ListType[]>([]);
  const {
    category_detail: { purchase_order_documents },
  } = useSelector<AppState, IDocoutState>((state) => state.docout);
  const columns: LaoITDevColumn<IPurchaseOrderDocumentItem>[] = [
    {
      title: t('content_list'),
      name: 'detail',
    },
    {
      title: t('quantity'),
      name: 'quantity',
      align: 'right',
      render: (item) => formatMoney(item),
    },
    {
      title: t('price'),
      name: 'price',
      align: 'right',
      render: (item) => formatMoney(item),
    },
    {
      title: t('sub_total'),
      name: 'sub_total',
      align: 'right',
      render: (item) => formatMoney(item),
    },
    {
      title: t('purchase_purpose'),
      name: 'purchase_purpose',
    },
  ];
  React.useEffect(() => {
    let data: ListType[] = [],
      data2: ListType[] = [];
    if (
      purchase_order_documents &&
      Object.values(purchase_order_documents).length > 0
    ) {
      data.push(
        {
          id: 1,
          title: t('from_company'),
          content: purchase_order_documents.from_company.name,
        },
        {
          id: 2,
          title: t('created_by'),
          content: purchase_order_documents.created_by_name,
        },
        {
          id: 3,
          title: t('document_date'),
          content: formatDateToInformal(purchase_order_documents.document_date),
        },
        {
          id: 4,
          title: t('reference_no'),
          content: purchase_order_documents.doc_out.reference_no,
        },
        {
          id: 5,
          title: t('document_category'),
          content: purchase_order_documents.document_category.name,
        },
        {
          id: 6,
          title: t('supplier'),
          content: purchase_order_documents.supplier_name,
        },
        {
          id: 7,
          title: t('supplier_code'),
          content: purchase_order_documents.supplier_code,
        },
        {
          id: 8,
          title: t('supplier_address'),
          content: purchase_order_documents.supplier_address,
        },
        {
          id: 9,
          title: t('phone'),
          content: purchase_order_documents.supplier_phone,
        }
      );
      setData(data);
      data2.push(
        {
          id: 1,
          title: t('purchaser'),
          content: `${purchase_order_documents.purchaser.first_name} ${purchase_order_documents.purchaser.last_name}`,
        },
        {
          id: 2,
          title: t('receive_date'),
          content: purchase_order_documents.receive_date
            ? formatDateToInformal(purchase_order_documents.receive_date)
            : '',
        },
        {
          id: 3,
          title: t('debt_time'),
          content: purchase_order_documents.debt_time
            ? formatDateToInformal(purchase_order_documents.debt_time)
            : '',
        },
        {
          id: 4,
          title: t('payment_date'),
          content: purchase_order_documents.payment_date
            ? formatDateToInformal(purchase_order_documents.payment_date)
            : '',
        },
        {
          id: 5,
          title: t('currency'),
          content: purchase_order_documents.currency.code,
        },
        {
          id: 6,
          title: t('payment_method'),
          content: t(`${purchase_order_documents.payment_method}`),
        },
        {
          id: 7,
          title: t('total'),
          content: (
            <Text strong>{formatMoney(purchase_order_documents.total)}</Text>
          ),
        },
        {
          id: 8,
          title: t('bank'),
          content: purchase_order_documents.bank_name,
        },

        {
          id: 9,
          title: t('account_number'),
          content: purchase_order_documents.account_number,
        }
      );
      setData2(data2);
    }
  }, [purchase_order_documents, t]);
  return (
    <>
      <DetailList data={data} data2={data2} />
      <LaoITDevTable
        loading={false}
        dataSource={
          purchase_order_documents &&
            purchase_order_documents.purchase_order_items
            ? purchase_order_documents.purchase_order_items.map((item) => ({
              key: item.id,
              ...item,
            }))
            : []
        }
        summary={(pageData) => {
          let total_quantity = 0,
            total_price = 0,
            total_sub_total = 0;
          pageData.forEach(({ quantity, price }) => {
            total_quantity += quantity;
            total_price += price;
            total_sub_total += quantity * price;
          });
          return (
            <>
              <Table.Summary.Row style={{ textAlign: 'right' }}>
                <Table.Summary.Cell index={1}>
                  <Text strong>{t('total')}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Text type="danger">{formatMoney(total_quantity)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <Text type="danger">{formatMoney(total_price)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Text type="danger">{formatMoney(total_sub_total)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              {purchase_order_documents && (
                <>
                  <Table.Summary.Row style={{ textAlign: 'right' }}>
                    <Table.Summary.Cell index={1}>
                      <Text strong>{t('discount')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} colSpan={3}>
                      <Text strong type="danger">
                        {formatMoney(purchase_order_documents.discount)}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row style={{ textAlign: 'right' }}>
                    <Table.Summary.Cell index={2}>
                      <Text strong>{t('net_price')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} colSpan={3}>
                      <Text strong type="danger">
                        {formatMoney(purchase_order_documents.net_price)}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row style={{ textAlign: 'right' }}>
                    <Table.Summary.Cell index={4}>
                      <Text strong>{t('vat')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} colSpan={3}>
                      <Text strong type="danger">
                        {purchase_order_documents.vat} %
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row style={{ textAlign: 'right' }}>
                    <Table.Summary.Cell index={1}>
                      <Text strong>{t('grand_total')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} colSpan={3}>
                      <Text strong type="danger">
                        {formatMoney(purchase_order_documents.grand_total)}
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )}
            </>
          );
        }}
        pagination={false}
        columns={columns}
      />
    </>
  );
};
