import { AxiosError, AxiosResponse } from 'axios';
import { channel } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { AppState } from '..';
import { subscribeErrorMessage, subscribeSuccessMessage } from '../saga';
import * as services from '../../service/User';
import { handleUser } from './action';
import * as types from './type';
import { handlePosition } from '../position/action';
import { handleDepartment } from '../department/action';
import { history } from "../../config/configureStore";

import { Logout } from "../../Utils";
function* handlerFetch() {
  try {
    const res = (yield call(services.fetchUser)) as AxiosResponse<
      types.IUser[]
    >;
    if (res.status === 200) {
      yield put(handleUser({ type: 'RECEIVE_IUSER', payload: res.data }));
    }
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 403) {
      history.push('/unauthorized')
    } else if (err.response?.status === 401) {
      Logout()
    }
  }
}

function* handlerSave({ payload }: types.Action<'SAVE_USER'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.saveUser,
      payload
    )) as AxiosResponse<types.IUser>;
    if (res.status === 201) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'ADD_USER', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlerUpdate({ payload }: types.Action<'UPDATE_USER'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateUser,
      payload
    )) as AxiosResponse<types.IUser>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'EDIT_USER', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(handleUser({ type: 'FETCH_USER', payload: payload }));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlerUpdateStatus({ payload }: types.Action<'UPDATE_STATUS_USER'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateStatusUser,
      payload
    )) as AxiosResponse<types.IUser>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'EDIT_STATUS_USER', status: true });
      yield fork(subscribeSuccessMessage, chan);
      const users = (yield select(
        (app: AppState) => app.user.users
      )) as types.IUser[];
      const idx = yield users.findIndex((i) => i.id === payload.id);
      users[idx] = {
        ...users[idx],
        status: payload.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      };
      yield put(
        handleUser({
          type: 'RECEIVE_IUSER',
          payload: [...users],
        })
      );
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlerFetchByEmail({
  payload,
}: types.Action<'FETCH_USER_BY_EMAIL'>) {
  try {
    const res = (yield call(
      services.fetchUserByEmail,
      payload
    )) as AxiosResponse<types.IUser>;
    if (res.status === 200) {
      const exist = yield select((app: AppState) => app.user.exist);
      yield put(
        handleUser({
          type: 'RECEIVE_EXIST_USER',
          payload: { ...exist, email: true },
        })
      );
    }
  } catch (e) { }
}
function* handlerFetchByUsername({
  payload,
}: types.Action<'FETCH_USER_BY_USERNAME'>) {
  try {
    const res = (yield call(
      services.fetchUserByUsername,
      payload
    )) as AxiosResponse<types.IUser>;
    if (res.status === 200) {
      const exist = yield select((app: AppState) => app.user.exist);
      yield put(
        handleUser({
          type: 'RECEIVE_EXIST_USER',
          payload: { ...exist, username: true },
        })
      );
    }
  } catch (e) { }
}
function* handlerFetchByID({ payload }: types.Action<'FETCH_USER_BY_ID'>) {
  try {
    const res = (yield call(
      services.fetchUserByID,
      payload
    )) as AxiosResponse<types.IUser>;
    if (res.status === 200) {
      yield put(handleUser({ type: 'RECEIVE_IUSER', payload: [res.data] }));
      const chan = (yield call(channel)) as any;
      yield put(chan, res.data.company.id);
      yield fork(subscribeFetchByID, chan);
    }
  } catch (e) { }
}
function* handlerFetchBySearch({
  payload,
}: types.Action<'FETCH_USER_BY_SEARCH'>) {
  try {
    const res = (yield call(
      services.fetchUserBySearch,
      payload
    )) as AxiosResponse<types.IUser>;
    if (res.status === 200) {
      yield put(handleUser({ type: 'RECEIVE_IUSER', payload: [res.data] }));
    }
  } catch (e) { }
}
function* subscribeFetchByID(chan) {
  const payload = (yield take(chan)) as string;
  yield put(
    handlePosition({
      type: 'FETCH_POSITION_BY_COMPANY',
      payload,
    })
  );
  yield put(
    handleDepartment({
      type: 'FETCH_DEPARTMENT_BY_COMPANY',
      payload,
    })
  );
}


function* handlerUploadProfileImage({ payload }: types.Action<'UPLOAD_PROFILE_IMAGE_FOR_ADMIN'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateProfileImage,
      payload
    )) as AxiosResponse<types.IUploadProfileImageForAdmin>;
    if (res.status === 201) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'UPLOAD_PROFILE_IMAGE_FOR_ADMIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(handleUser({ type: 'FETCH_USER_BY_ID', payload: payload.id }));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlerUploadSignatureImage({ payload }: types.Action<'UPLOAD_SIGNATURE_IMAGE_FOR_ADMIN'>) {
  const chan = (yield call(channel)) as any;

  try {
    const res = (yield call(
      services.updateSignatureImage,
      payload
    )) as AxiosResponse<types.IUploadSignatureImageForAdmin>;

    if (res.status === 201) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'UPLOAD_SIGNATURE_IMAGE_FOR_ADMIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(handleUser({ type: 'FETCH_USER_BY_ID', payload: payload.id }));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlerFetchAssignees() {
  try {
    const res = (yield call(services.fetchAssignees)) as AxiosResponse<
      types.IAssignee[]
    >;
    if (res.status === 200) {
      yield put(handleUser({ type: 'RECEIVE_IASSIGNEES', payload: res.data }));
    }
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 403) {
      history.push('/unauthorized')
    } else if (err.response?.status === 401) {
      Logout()
    }
  }
}

function* watchHandlerFetch() {
  yield takeLatest('FETCH_USER', handlerFetch);
}
function* watchHandlerSave() {
  yield takeLatest('SAVE_USER', handlerSave);
}
function* watchHandlerUpdate() {
  yield takeLatest('UPDATE_USER', handlerUpdate);
}
function* watchhandlerUpdateStatus() {
  yield takeLatest('UPDATE_STATUS_USER', handlerUpdateStatus);
}
function* watchHandleFetchByEmail() {
  yield takeLatest('FETCH_USER_BY_EMAIL', handlerFetchByEmail);
}
function* watchHandleFetchByUsername() {
  yield takeLatest('FETCH_USER_BY_USERNAME', handlerFetchByUsername);
}
function* watchHandleFetchByID() {
  yield takeLatest('FETCH_USER_BY_ID', handlerFetchByID);
}
function* watchHandlerFetchBySearch() {
  yield takeLatest('FETCH_USER_BY_SEARCH', handlerFetchBySearch);
}
function* watchHandlerUploadProfileImage() {
  yield takeLatest('UPLOAD_PROFILE_IMAGE_FOR_ADMIN', handlerUploadProfileImage);
}
function* watchHandlerUploadSignatureImage() {
  yield takeLatest('UPLOAD_SIGNATURE_IMAGE_FOR_ADMIN', handlerUploadSignatureImage);
}
function* watchHandlerFetchAssignee() {
  yield takeLatest('FETCH_ASSIGNEES', handlerFetchAssignees);
}
export function* userSaga() {
  yield all([
    fork(watchHandlerFetch),
    fork(watchHandlerSave),
    fork(watchHandlerUpdate),
    fork(watchhandlerUpdateStatus),
    fork(watchHandleFetchByEmail),
    fork(watchHandleFetchByUsername),
    fork(watchHandleFetchByID),
    fork(watchHandlerFetchBySearch),
    fork(watchHandlerUploadProfileImage),
    fork(watchHandlerUploadSignatureImage),
    fork(watchHandlerFetchAssignee),
  ]);
}
