import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as services from '../../service/Category';
import { getCategoriesSuccessAction } from './action';
import * as types from './type';

function* handleGetCategories(): Generator {
  try {
    const { data } = (yield call(services.getCategories)) as AxiosResponse<
      types.ICategory[]
    >;
    yield put(getCategoriesSuccessAction(data));
  } catch (err) {}
}

function* watchHandlerGetCategories(): Generator {
  yield takeLatest(
    types.CategoryActionTypes.GET_CATEGORIES,
    handleGetCategories
  );
}

export function* categorySaga() {
  yield all([fork(watchHandlerGetCategories)]);
}
