import { TableForms } from '../category';
import * as types from './type';

export const getInboxAction = (
  query_params?: types.IboxGetQueryParams,
): types.IGetInboxAction => ({
  type: types.InboxActionTypes.GET_INBOX,
  payload: { query_params }
});

export const getInboxSuccessAction = (
  inboxes: types.IInbox[]
): types.IGetInboxSuccessAction => ({
  type: types.InboxActionTypes.GET_INBOX_SUCCESS,
  payload: inboxes,
});

export const getInboxByIdAction = (
  id: string,
  category_id: string
): types.IGetDocumentByIdAction => ({
  type: types.InboxActionTypes.GET_INBOX_BY_ID,
  payload: {
    id,
    category_id,
  },
});
export const getInboxByIdSuccessAction = (
  category: types.IInboxCategory
): types.IGetDocumentByIdSuccessAction => ({
  type: types.InboxActionTypes.GET_INBOX_BY_ID_SUCCESS,
  payload: category,
});

export const patchInboxByIdAction = (
  id: string,
  sign_status: types.SignStatus,
  table_forms: TableForms
): types.IPatchInboxByIdAction => ({
  type: types.InboxActionTypes.PATCH_INBOX_BY_ID,
  payload: {
    id,
    sign_status,
    table_forms,
  },
});

// file

export const getFileSuccessAction = (
  file: types.File
): types.IGetFileSuccessAction => ({
  type: types.FileActionTypes.GET_FILE_SUCCESS,
  payload: file,
});

export const deleteFileAction = (
  payload: types.IDeleteFileAction['payload']
): types.IDeleteFileAction => ({
  type: types.FileActionTypes.DELETE_FILE,
  payload,
});

export const postFileAction = (
  payload: types.IPostFileAction['payload']
): types.IPostFileAction => ({
  type: types.FileActionTypes.POST_FILE,
  payload,
});
