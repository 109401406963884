import { formatMinutes, formatSeconds } from './../../../../../Utils';
import { RecorderControlsProps } from './../../../../../store/record/type';
import {
  FieldTimeOutlined,
  SaveOutlined,
  AudioOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';

export default function RecorderControls({
  recorderState,
  handlers,
}: RecorderControlsProps) {
  const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording, cancelRecording } = handlers;
  return (
    <Row justify="center">
      <Col>
        <Space direction="horizontal">
          <div className="recorder-display">
            {initRecording && (
              <div className="cancel-button-container">
                <Button
                  size="large"
                  className="cancel-button"
                  title="Cancel recording"
                  onClick={cancelRecording}
                >
                  <FieldTimeOutlined />
                </Button>
              </div>
            )}
          </div>
          <div className="start-button-container">
            {initRecording ? (
              <Button
                size="large"
                type="primary"
                className="start-button"
                title="Save recording"
                disabled={recordingSeconds === 0}
                onClick={saveRecording}
              >
                <SaveOutlined />
              </Button>
            ) : (
              <Button
                type="primary"
                size="large"
                title="Start recording"
                onClick={startRecording}
              >
                <AudioOutlined />
              </Button>
            )}
          </div>
        </Space>
        <div
          className="recording-time"
          style={{ textAlign: 'center', marginLeft: '8px' }}
        >
          {initRecording && <div className="recording-indicator"></div>}
          <span>{formatMinutes(recordingMinutes)}</span>
          <span>:</span>
          <span>{formatSeconds(recordingSeconds)}</span>
        </div>
      </Col>
    </Row>
  );
}
