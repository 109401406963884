import * as React from 'react';
import { ISavePaymentsVoucherItem } from '../../../store/docout/type';
import {
  Form,
  Input,
  Divider,
  Button,
  Typography,
  Row,
  Col,
  InputNumber,
} from 'antd';
import { MinusSquareOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
const { Item, List } = Form;
const { Text } = Typography;
interface Props {
  handleBlurPrice: () => void;
}
export const AddPaymentVoucherItem: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation(['document', 'button']);
  const [rows] = React.useState<ISavePaymentsVoucherItem[]>([
    {
      id: 0,
      detail: '',
      amount: 0,
      sub_total: 0,
      price: 0,
    },
  ]);
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('content_list')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('amount')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('action', { ns: 'button' })}</Item>
        </Col>
      </Row>
      <List name="save_payment_voucher_items" initialValue={rows}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => (
                <Row key={index} gutter={[16, 0]}>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item {...field} name={[field.name, 'detail']}>
                      <Input
                        placeholder={t('input_payment_purchase_item_detail')}
                      />
                    </Item>
                  </Col>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item {...field} name={[field.name, 'amount']}>
                      <InputNumber
                        placeholder={t('input_quantity')}
                        style={{ width: '100%' }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) =>
                          value ? value.replace(/\$\s?|(,*)/g, '') : ''
                        }
                        onBlur={(e) => props.handleBlurPrice()}
                      />
                    </Item>
                  </Col>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item>
                      <Text
                        type="danger"
                        style={{ cursor: 'pointer', fontSize: 24 }}
                        onClick={() => remove(field.name)}
                      >
                        <MinusSquareOutlined />
                      </Text>
                    </Item>
                  </Col>
                </Row>
              ))}
              <Divider />
              <Button
                htmlType="button"
                type="primary"
                onClick={() => add(rows[0])}
              >
                {t('add_content')}
              </Button>
              <Divider style={{ margin: '24px 0 5px 0' }} />
            </>
          );
        }}
      </List>
    </>
  );
};
