import { Moment } from 'moment';
import { ICategory, TableForms } from '../category';
import { Comments } from '../comment';
import { IPublicCompany } from '../company';
import { IDocumentRank } from '../document';
import {
  DocumentStatus,
  IAdvanceClearance,
  IAdvanceRequest,
  IExternal,
  IPaymentVoucher,
  IPurchaseOrder,
  PaymentMethod,
} from '../document/type';
import { IPublicUser } from '../user/type';
export type SignStatus = 'pending' | 'signed' | 'rejected';
export type DocumentType = 'docin' | 'docout';
export interface IInbox {
  id: string;
  sign_id: string;
  signer: boolean;
  sign_status: SignStatus;
  sign_date?: Moment;
  document_id: string;
  company: IPublicCompany;
  created_at: Moment;
  created_by: string;
  created_by_name: string;
  updated_at?: Moment;
  updated_by?: string;
  document_category: ICategory;
  document_date: Moment;
  document_rank: IDocumentRank;
  from_company: IPublicCompany;
  reference_no: string;
  document_status: DocumentStatus;
  type: DocumentType;
}
export interface Assign {
  id: string;
  assigned: IPublicUser;
  signer: boolean;
  signStatus: SignStatus;
  signDate?: Moment;
  documentID: string;
  company: IPublicCompany;
  department_name: string;
  created_at: Moment;
  created_by: string;
  updated_at?: Moment;
  updated_by?: string;
}

export interface Assigns {
  assigns: Assign[];
}

export interface IExternalDocument
  extends IExternal,
  IInbox,
  Assigns,
  Partial<Comments>,
  Partial<Files> { }

export interface IAdvanceClearanceDocument
  extends IAdvanceClearance,
  IInbox,
  Assigns,
  Partial<Comments>,
  Partial<Files> { }
export interface IAdvanceRequestDocument
  extends IAdvanceRequest,
  IInbox,
  Assigns,
  Partial<Comments>,
  Partial<Files> { }

export interface IPaymentVoucherDocument
  extends IPaymentVoucher,
  IInbox,
  Assigns,
  Partial<Comments>,
  Partial<Files> { }

export interface IPurchaseOrderDocument
  extends IPurchaseOrder,
  IInbox,
  Assigns,
  Partial<Comments>,
  Partial<Files> { }

export interface IInboxCategory {
  external_documents?: IExternalDocument;
  advance_clearance_documents?: IAdvanceClearanceDocument;
  advance_request_documents?: IAdvanceRequestDocument;
  payment_voucher_documents?: IPaymentVoucherDocument;
  purchase_order_documents?: IPurchaseOrderDocument;
}

export interface IboxGetQueryParams {
  document_status?: DocumentStatus;
  reference_no?: string;
  applicant?: string;
  supplier_name?: string;
  payment_method?: PaymentMethod;
  currency?: number;
  total_money?: number;
  account_number?: string;
}

export interface IInboxState {
  readonly inboxes: IInbox[];
  readonly category: IInboxCategory;
  readonly loading: boolean;
}

export enum InboxActionTypes {
  GET_INBOX = '@inbox/GET_INBOX',
  GET_INBOX_SUCCESS = '@inbox/GET_INBOX_SUCCESS',
  GET_INBOX_BY_ID = '@inbox/GET_INBOX_BY_ID',
  GET_INBOX_BY_ID_SUCCESS = '@inbox/GET_INBOX_BY_ID_SUCCESS',
  PATCH_INBOX_BY_ID = '@inbox/PATCH_INBOX_BY_ID',
}

export interface IGetInboxAction {
  type: InboxActionTypes.GET_INBOX;
  payload: { query_params?: IboxGetQueryParams }
}

export interface IGetInboxSuccessAction {
  type: InboxActionTypes.GET_INBOX_SUCCESS;
  payload: IInbox[];
}

export interface IGetDocumentByIdAction {
  type: InboxActionTypes.GET_INBOX_BY_ID;
  payload: {
    id: string;
    category_id: string;
  };
}
export interface IGetDocumentByIdSuccessAction {
  type: InboxActionTypes.GET_INBOX_BY_ID_SUCCESS;
  payload: IInboxCategory;
}

export interface IPatchInboxByIdAction {
  type: InboxActionTypes.PATCH_INBOX_BY_ID;
  payload: {
    id: string;
    sign_status: SignStatus;
    table_forms: TableForms;
  };
}

export type InboxActions =
  | IGetInboxAction
  | IGetDocumentByIdAction
  | IGetInboxSuccessAction
  | IGetDocumentByIdSuccessAction
  | IPatchInboxByIdAction;

// Files
export interface File {
  id: string;
  Company: IPublicCompany;
  created_at: Moment;
  created_by: string;
  name: string;
  link: string;
}

export interface Files {
  files: File[];
}

export enum FileActionTypes {
  GET_FILE_SUCCESS = 'GET_FILE_SUCCESS',
  DELETE_FILE = '@file/DELETE_FILE',
  POST_FILE = '@file/POST_FILE',
}

export interface IFileState {
  readonly file?: File;
}

export interface IGetFileSuccessAction {
  type: FileActionTypes.GET_FILE_SUCCESS;
  payload: File;
}

export interface IDeleteFileAction {
  type: FileActionTypes.DELETE_FILE;
  payload: {
    id: string;
  };
}

export interface IPostFileAction {
  type: FileActionTypes.POST_FILE;
  payload: {
    document_id: string;
    form: FormData;
  };
}

export type FileActions =
  | IGetFileSuccessAction
  | IDeleteFileAction
  | IPostFileAction;
