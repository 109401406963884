import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { history } from '../../config/configureStore';
import { getCategoriesAction, TableForms } from '../../store/category';
import {
  AddPaymentVoucher,
  AddPurchaseOrder,
  AddAdvanceRequest,
  AddAdvanceClearance,
  AddExternal,
} from './add';

const AddDocin: React.FC = () => {
  const { table_forms } = useParams<{
    id: string;
    table_forms: TableForms;
  }>();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getCategoriesAction());
  }, [dispatch]);
  const handleTableForm = (table_forms?: TableForms): JSX.Element => {
    if (table_forms) {
      switch (table_forms) {
        case 'purchase_order_documents':
          return <AddPurchaseOrder />;
        case 'payment_voucher_documents':
          return <AddPaymentVoucher />;
        case 'advance_request_documents':
          return <AddAdvanceRequest />;
        case 'advance_clearance_documents':
          return <AddAdvanceClearance />;
        case 'external_documents':
          return <AddExternal />;
        default:
          history.push('/docout');
          return <></>;
      }
    }
    return <></>;
  };
  return <>{handleTableForm(table_forms)}</>;
};

export default AddDocin;
