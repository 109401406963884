import { Modal, ModalProps } from 'antd';
import * as React from 'react';

interface Props extends ModalProps {
  visible?: boolean;
  footer?: React.ReactNode;
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string | React.ReactNode;
  width?: string | number;
}
export const LaoITDevModal: React.FC<Props> = ({
  visible,
  footer,
  onCancel,
  title,
  width = 520,
  children,
  bodyStyle,
  closable,
  destroyOnClose,
}) => {
  return (
    <Modal
      destroyOnClose={destroyOnClose}
      width={width}
      className="laoitdev"
      title={title}
      visible={visible}
      footer={footer}
      onCancel={onCancel}
      bodyStyle={bodyStyle}
      closable={closable}
    >
      {children}
    </Modal>
  );
};
