import { lazy } from 'react';
import { NavLink } from '../../config/navigation';
import Detail from './detail/Detail';
import EditDocout from './edit/EditDocout';

export const docoutLink = {
  list: '/docout',
};
const Docout = lazy(() => import('./Docout'));
const AddDocout = lazy(() => import('./AddDocout'));
export const docoutRoute = [
  {
    path: '/docout/',
    children: [
      {
        path: '',
        element: <Docout />,
        index: true,
      },
      {
        path: 'add/',
        children: [
          {
            path: ':id/category/',
            children: [
              {
                path: ':table_forms',
                element: <AddDocout />,
              },
            ],
          },
        ],
      },
      {
        path: ':id/detail/:category/category/:table_form',
        element: <Detail />,
      },
      {
        path: ':docout_id/edit/:category/category/:table_form',
        element: <EditDocout />,
      },
    ],
  },
];

export const docoutNavigator: NavLink[] = [
  {
    label: 'docout',
    name: '/docout',
    link: '/docout',
    domain: 'docout',
  },
];
