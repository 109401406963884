import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { dateInformal, formatMoney } from '../../../Utils';
import { IDocumentState, ISavePurchaseOrderDocumentItem, payment_methods } from '../../../store/document/type';
import { IDocoutState } from '../../../store/docout';
import { bank_in_laos, ErrorState, handleError, handleSuccess, initialSuccessState, SuccessState } from "../../../store/utility";
import { EditPurchaseOrderItem } from "./EditPurchaseOrderItem";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import { useParams } from "react-router";
import { getCurrenciesAction, getDocumentRanksAction } from "../../../store/document";
import { LaoITDevCardAdd } from "../../utility";
import { history } from "../../../config/configureStore";
import { patchPurchaseOrderDocoutAction } from "../../../store/docout/action";

const { Item } = Form;
const { Option } = Select;
const { confirm } = Modal;

export const EditPurchaseOrder: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation(['document', 'button']);
  const { docout_id, category } = useParams<{ docout_id: string, category: string }>();

  const [total, setTotal] = React.useState(0);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const [vat, setVat] = React.useState(0);
  const [net_price, setNetPrice] = React.useState(0);
  const [submit, setSubmit] = React.useState<boolean>(false);

  const {
    category_detail: { purchase_order_documents },
  } = useSelector<AppState, IDocoutState>((state) => state.docout);

  const { currency, document_rank } = useSelector<AppState, IDocumentState>(
    ({ document }) => document
  );

  const submitSuccess = useSelector<AppState, SuccessState>(
    ({ success }) => success
  );
  const errorOnSubmit = useSelector<AppState, ErrorState>(({ error }) => error);

  const handleFinish = (value) => {
    confirm({
      title: t('confirm_submit_document'),
      icon: <ExclamationCircleOutlined />,
      okText: t('yes', { ns: 'button' }),
      cancelText: t('no', { ns: 'button' }),
      onOk() {
        setSubmit(true);
        if (category && docout_id && purchase_order_documents) {
          dispatch(patchPurchaseOrderDocoutAction(value, category, purchase_order_documents.id, docout_id));
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const handleBlurPrice = () => {
    const purchase_order_items: ISavePurchaseOrderDocumentItem[] =
      form.getFieldValue('save_purchase_order_items');
    const new_purchase_order_items = purchase_order_items.map((item) => {
      const { price, quantity } = item;
      const sub_total = price * quantity;
      return { ...item, sub_total };
    });
    form.setFieldsValue({
      save_purchase_order_items: new_purchase_order_items,
    });
    const discount = form.getFieldValue('discount');
    const vat = form.getFieldValue('vat');
    const t = purchase_order_items.reduce((prev, curr) => {
      if (curr.quantity > 0 && curr.price > 0) {
        return (prev += curr.price * curr.quantity);
      }
      return prev;
    }, 0);
    const net_price = t - discount;
    const gt = net_price + net_price * (vat / 100);
    setTotal(t);
    setGrandTotal(gt);
    setDiscount(discount);
    setVat(vat);
    setNetPrice(net_price);
  };

  React.useEffect(() => {
    if (submitSuccess.status && submitSuccess.name === 'EDIT_PURCHASE_ORDER_DOCOUT') {
      dispatch(
        handleSuccess({
          type: 'SET_SUCCESS',
          payload: initialSuccessState,
        })
      );
      history.push('/docout');
    }
    if (errorOnSubmit.status && errorOnSubmit.name === 'EDIT_PURCHASE_ORDER_DOCOUT') {
      dispatch(
        handleError({
          type: 'SET_ERROR',
          payload: initialSuccessState,
        })
      );
    }
    setSubmit(false);
  }, [dispatch, submitSuccess, errorOnSubmit]);

  React.useEffect(() => {
    form.setFieldsValue({
      document_rank_id: purchase_order_documents?.document_rank.id,
      supplier_name: purchase_order_documents?.supplier_name,
      supplier_code: purchase_order_documents?.supplier_code,
      supplier_address: purchase_order_documents?.supplier_address,
      supplier_phone: purchase_order_documents?.supplier_phone,
      document_date: moment(purchase_order_documents?.document_date),
      purchase_date: moment(purchase_order_documents?.purchase_date),
      receive_date: moment(purchase_order_documents?.receive_date),
      debt_time: moment(purchase_order_documents?.debt_time),
      payment_date: moment(purchase_order_documents?.payment_date),
      payment_method: purchase_order_documents?.payment_method,
      currency_id: purchase_order_documents?.currency.id,
      bank_name: purchase_order_documents?.bank_name,
      account_number: purchase_order_documents?.account_number,
    });

    setTotal(purchase_order_documents?.total ? purchase_order_documents.total : 0);
    setGrandTotal(purchase_order_documents?.grand_total ? purchase_order_documents.grand_total : 0);
    setDiscount(purchase_order_documents?.discount ? purchase_order_documents.discount : 0);
    setVat(purchase_order_documents?.vat ? purchase_order_documents.vat : 0);
    setNetPrice(purchase_order_documents?.net_price ? purchase_order_documents.net_price : 0);

  }, [purchase_order_documents, dispatch, form]);

  React.useEffect(() => {
    if (currency.currencies.length > 0) {
      form.setFieldsValue({
        currency_id: currency.currencies[0].id,
      });
    }
    if (document_rank.document_ranks.length > 0) {
      form.setFieldsValue({
        document_rank_id: document_rank.document_ranks[0].id,
      });
    }
  }, [dispatch, form, currency, document_rank]);

  React.useEffect(() => {
    dispatch(getCurrenciesAction());
    dispatch(getDocumentRanksAction());
  }, [dispatch]);

  return (
    <>
      <LaoITDevCardAdd title={t('edit_purchase_order')}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" plain>
            {t('edit_purchase_order')}
          </Divider>
          <Row gutter={[24, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('document_rank')} name="document_rank_id">
                <Select>
                  {document_rank &&
                    document_rank.document_ranks.map((document_rank) => (
                      <Option key={document_rank.id} value={document_rank.id}>
                        {document_rank.name}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('supplier')} name="supplier_name">
                <Input placeholder={t('input_supplier')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('supplier_code')} name="supplier_code">
                <Input placeholder={t('input_supplier_code')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('address')} name="supplier_address">
                <Input placeholder={t('input_address')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('phone')} name="supplier_phone">
                <Input placeholder={t('input_phone')} />
              </Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            {t('date')}
          </Divider>
          <Row gutter={[24, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('document_date')}
                name="document_date"
                required
                initialValue={moment()}
                rules={[
                  { required: true, message: t('document_date_required') },
                ]}
              >
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_document_date')}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('purchase_date')}
                name="purchase_date"
                rules={[
                  { required: true, message: t('document_date_required') },
                ]}
              >
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_purchase_date')}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('receive_date')} name="receive_date">
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_receive_date')}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('debt_time')} name="debt_time">
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_debt_time')}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('payment_date')} name="payment_date">
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_payment_date')}
                />
              </Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            {t('account')}
          </Divider>
          <Row gutter={[24, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                name="payment_method"
                label={t('payment_method')}
                initialValue={'cash'}
                required
              >
                <Select>
                  {payment_methods.map((payment_method) => (
                    <Option
                      key={payment_method.value}
                      value={payment_method.value}
                    >
                      {t(payment_method.label)}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('currency')}
                name="currency_id"
                rules={[{ required: true, message: t('currency_required') }]}
              >
                <Select placeholder={t('select_currency')}>
                  {currency &&
                    currency.currencies.map((currency) => (
                      <Option key={currency.id} value={currency.id}>
                        {currency.code}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('bank')} name="bank_name">
                <Select placeholder={t('select_bank')}>
                  {bank_in_laos.map((bank, index) => (
                    <Option key={index} value={bank}>
                      {bank}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('account_name')} name="account_name">
                <Input placeholder={t('input_account_name')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('account_number')} name="account_number">
                <Input placeholder={t('input_account_number')} />
              </Item>
            </Col>
          </Row>
          <Divider />
          <EditPurchaseOrderItem
            handleBlurPrice={handleBlurPrice}
            purchase_order_items={purchase_order_documents?.purchase_order_items}
          />
          <div className="summary-purchase">
            <Row gutter={[16, 0]}>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{t('total')}</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{formatMoney(total)}</Item>
              </Col>
            </Row>
            <Divider style={{ margin: '5px 0' }} />
            <Row gutter={[16, 0]}>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{t('discount')}</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{formatMoney(discount)}</Item>
              </Col>
            </Row>
            <Divider style={{ margin: '5px 0' }} />
            <Row gutter={[16, 0]}>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{t('net_price')}</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{formatMoney(net_price)}</Item>
              </Col>
            </Row>
            <Divider style={{ margin: '5px 0' }} />
            <Row gutter={[16, 0]}>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{t('vat')}</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{formatMoney(vat)}</Item>
              </Col>
            </Row>
            <Divider style={{ margin: '5px 0' }} />
            <Row gutter={[16, 0]}>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{t('grand_total')}</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{formatMoney(grandTotal)}</Item>
              </Col>
            </Row>
          </div>
          <Divider />
          <Row justify="space-between">
            <Col>
              <Button onClick={() => history.back()} size="large" loading={submit}>
                {t('cancel', { ns: 'button' })}
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={submit}
              >
                {t('save', { ns: 'button' })}
              </Button>
            </Col>
          </Row>
        </Form>
      </LaoITDevCardAdd>
    </>
  );
};
