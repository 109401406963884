import { Col, Row, Skeleton } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { history } from '../../../config/configureStore';
import { AppState } from '../../../store';
import { TableForms } from '../../../store/category';
import { EditPurchaseOrder } from './EditPurchaseOrder';
import { getDocoutByIdAction, IDocoutState } from '../../../store/docout';
import { EditPaymentVoucher } from "./EditPaymentVoucher";
import { EditAdvanceRequest } from "./EditAdvanceRequest";
import { EditAdvanceClearance } from "./EditAdvanceClearance";
import { EditExternal } from "./EditExternal";

const EditDocout: React.FC = () => {
  const dispatch = useDispatch();
  const { docout_id, category, table_form } = useParams<{
    docout_id: string;
    category: string;
    table_form: TableForms;
  }>();

  const { loading, category_detail } = useSelector<AppState, IDocoutState>(
    (state) => state.docout
  );

  const docoutCategory = category_detail;
  const editDocument = (): JSX.Element => {
    if (!table_form) {
      history.push('/docout');
    }
    if (table_form && docoutCategory[table_form]) {
      const obj = docoutCategory[table_form];
      if (obj && Object.values(obj).length > 0) {
        switch (table_form) {
          case 'external_documents':
            return <EditExternal />;
          case 'advance_clearance_documents':
            return <EditAdvanceClearance />;
          case 'advance_request_documents':
            return <EditAdvanceRequest />;
          case 'payment_voucher_documents':
            return <EditPaymentVoucher />;
          case 'purchase_order_documents':
            return <EditPurchaseOrder />;
          default:
            return <></>;
        }
      }
    }
    return <></>;
  };

  React.useEffect(() => {
    if (docout_id && category) {
      dispatch(getDocoutByIdAction(docout_id, category));
    }
  }, [dispatch, docout_id, category]);

  return (
    <>
      {loading && <Skeleton active />}
      {!loading && (
        <Row gutter={[24, 24]}>
          <Col xxl={24} lg={24} md={24} sm={24} xs={24}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                {editDocument()}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EditDocout;
