import { CommentActions, ICommentState } from './type';

export const initialCommentState: ICommentState = {
  comment: undefined,
};

export const commentReducer = (
  state: ICommentState = initialCommentState,
  action: CommentActions
) => {
  switch (action.type) {
    case 'GET_COMMENT_SUCCESS':
      return {
        ...state,
        comment: action.payload,
      };
    default:
      return state;
  }
};
