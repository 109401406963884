import { Typography } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { IDocinState } from '../../../store/docin';
import { formatDateToInformal, formatMoney } from '../../../Utils';
import { DetailList, ListType } from './DetailList';
const { Text } = Typography;
export const AdvanceRequestDetail: React.FC = () => {
  const { t } = useTranslation(['document', 'button']);
  const [data, setData] = React.useState<ListType[]>([]);
  const [data2, setData2] = React.useState<ListType[]>([]);
  const {
    category_detail: { advance_request_documents },
  } = useSelector<AppState, IDocinState>((state) => state.docin);
  React.useEffect(() => {
    let data: ListType[] = [],
      data2: ListType[] = [];
    if (
      advance_request_documents &&
      Object.values(advance_request_documents).length > 0
    ) {
      data.push(
        {
          id: 1,
          title: t('from_company'),
          content: advance_request_documents.from_company.name,
        },
        {
          id: 2,
          title: t('created_by'),
          content: `${advance_request_documents.created_by_name} - ${advance_request_documents.created_by_phone}`,
        },
        {
          id: 3,
          title: t('document_date'),
          content: formatDateToInformal(
            advance_request_documents.document_date
          ),
        },
        {
          id: 4,
          title: t('reference_no'),
          content: advance_request_documents.doc_in.reference_no,
        },
        {
          id: 5,
          title: t('document_category'),
          content: advance_request_documents.document_category.name,
        },
        {
          id: 6,
          title: t('condition_1'),
          content: advance_request_documents.condition_1,
        },
        {
          id: 7,
          title: t('condition_2'),
          content: advance_request_documents.condition_2,
        },
        {
          id: 8,
          title: t('condition_3'),
          content: advance_request_documents.condition_3,
        }
      );
      setData(data);
      data2.push(
        {
          id: 1,
          title: t('clearer'),
          content: `${advance_request_documents.applicant.first_name} ${advance_request_documents.applicant.last_name}`,
        },
        {
          id: 2,
          title: t('currency'),
          content: advance_request_documents.currency.code,
        },
        {
          id: 3,
          title: t('amount'),
          content: (
            <Text strong>{formatMoney(advance_request_documents.amount)}</Text>
          ),
        },
        {
          id: 4,
          title: t('reason'),
          content: advance_request_documents.reason,
        },
        {
          id: 5,
          title: t('borrow_money_before'),
          content: (
            <Text strong>
              {formatMoney(advance_request_documents.borrow_money_before)}
            </Text>
          ),
        },
        {
          id: 6,
          title: t('borrow_money_addition'),
          content: (
            <Text strong>
              {formatMoney(advance_request_documents.borrow_money_addition)}
            </Text>
          ),
        },
        {
          id: 7,
          title: t('total'),
          content: (
            <Text strong>{formatMoney(advance_request_documents.total)}</Text>
          ),
        }
      );
      setData2(data2);
    }
  }, [advance_request_documents, t]);
  return (
    <>
      <DetailList data={data} data2={data2} />
    </>
  );
};
