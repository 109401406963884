import { axiosInstant } from '../axiosInstant';
import { IPostCommentTextAction } from '../store/comment/type';

export const postCommentText = (payload: IPostCommentTextAction['payload']) => {
  if (payload.content_type === 'text') {
    return axiosInstant
      .post(`/comment/${payload.content_type}/${payload.id}`, {
        content: payload.content,
      })
      .then((res) => res);
  } else {
    return axiosInstant
      .post(`/comment/${payload.content_type}/${payload.id}`, payload.content)
      .then((res) => res);
  }
};
