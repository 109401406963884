import { Reducer } from 'redux';
import { Action, DepartmentState, IDepartment } from './type';

const initialState: DepartmentState = {
  departments: [],
  loading: false,
};
export const reducer: Reducer<DepartmentState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'RECEIVE_IDEPARTMENT':
      return {
        ...state,
        departments: action.payload as IDepartment[],
        loading: false,
      };
    case 'FETCH_DEPARTMENT':
      return {
        ...state,
        loading: true,
      };
    case 'UPDATE_STATUS_DEPARTMENT':
      return { ...state, loading: true };
    case 'FETCH_DEPARTMENT_BY_COMPANY':
      return { ...state, loading: true };
    default:
      return state;
  }
};
