import * as React from 'react';
import { ISavePurchaseOrderDocumentItem } from '../../../store/document/type';
import {
  Form,
  Input,
  Divider,
  Button,
  Typography,
  Row,
  Col,
  InputNumber,
} from 'antd';
import { MinusSquareOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Item, List } = Form;
const { Text } = Typography;
interface Props {
  handleBlurPrice: () => void;
  purchase_order_items?: ISavePurchaseOrderDocumentItem[];
}
export const EditPurchaseOrderItem: React.FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation(['document', 'button']);
  const [rows] = React.useState<ISavePurchaseOrderDocumentItem[]>([
    {
      id: 0,
      detail: '',
      quantity: 0,
      price: 0,
      sub_total: 0,
      purchase_purpose: '',
    },
  ]);
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('content_list')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('quantity')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('price')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('sub_total')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('purchase_purpose')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('action', { ns: 'button' })}</Item>
        </Col>
      </Row>
      <List name="save_purchase_order_items" initialValue={props.purchase_order_items}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field) => (
                <Row key={field.key} gutter={[16, 0]}>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item
                      {...field}
                      name={[field.name, 'detail']}
                      rules={[
                        {
                          required: true,
                          message: t('content_list_required'),
                        },
                      ]}
                    >
                      <Input placeholder={t('input_content_list')} />
                    </Item>
                  </Col>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item {...field} name={[field.name, 'quantity']}>
                      <InputNumber
                        type="number"
                        placeholder={t('input_quantity')}
                        style={{ width: '100%' }}
                        onFocus={(e) => e.target.select()}
                        onBlur={(_) => props.handleBlurPrice()}
                      />
                    </Item>
                  </Col>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item {...field} name={[field.name, 'price']}>
                      <InputNumber
                        placeholder={t('input_price')}
                        style={{ width: '100%' }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) =>
                          value ? value.replace(/\$\s?|(,*)/g, '') : ''
                        }
                        onBlur={(_) => props.handleBlurPrice()}
                      />
                    </Item>
                  </Col>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item name={[field.name, 'sub_total']}>
                      <InputNumber
                        placeholder={t('input_sub_total')}
                        style={{ width: '100%' }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) =>
                          value ? value.replace(/\$\s?|(,*)/g, '') : ''
                        }
                        onBlur={(_) => props.handleBlurPrice()}
                      />
                    </Item>
                  </Col>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item {...field} name={[field.name, 'purchase_purpose']}>
                      <Input placeholder={t('input_purchase_purpose')} />
                    </Item>
                  </Col>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    {field.key !== 0 && (
                      <Item>
                        <Text
                          type="danger"
                          style={{ cursor: 'pointer', fontSize: 24 }}
                          onClick={() => remove(field.name)}
                        >
                          <MinusSquareOutlined />
                        </Text>
                      </Item>
                    )}
                  </Col>
                </Row>
              ))}
              <Divider />
              <Button
                htmlType="button"
                type="primary"
                onClick={() => add(rows[0])}
              >
                {t('add_content')}
              </Button>
            </>
          );
        }}
      </List>
      <Divider style={{ margin: '24 0 5px 0' }} />
      <Row gutter={[26, 0]}>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item label={t('discount')} name="discount" initialValue={0}>
            <InputNumber
              onFocus={(e) => {
                e.target.select();
              }}
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) =>
                value ? value.replace(/\$\s?|(,*)/g, '') : ''
              }
              onBlur={(e) => props.handleBlurPrice()}
            />
          </Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item label={`${t('vat')} (%  )`} name="vat" initialValue={0}>
            <InputNumber
              onFocus={(e) => {
                e.target.select();
              }}
              style={{ width: '100%' }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) =>
                value ? value.replace(/\$\s?|(,*)/g, '') : ''
              }
              onBlur={(e) => props.handleBlurPrice()}
            />
          </Item>
        </Col>
      </Row>
      <Divider style={{ margin: '24px 0 5px 0' }} />
    </>
  );
};
