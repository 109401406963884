import { Table, Typography } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { IDocoutState } from '../../../store/docout';
import { IPaymentVoucherDocumentItem } from '../../../store/document/type';
import { formatDateToInformal, formatMoney } from '../../../Utils';
import { LaoITDevTable, LaoITDevColumn } from '../../utility';
import { DetailList, ListType } from './DetailList';
const { Text } = Typography;
export const PaymentVoucherDetail: React.FC = () => {
  const { t } = useTranslation(['document', 'button']);
  const [data, setData] = React.useState<ListType[]>([]);
  const [data2, setData2] = React.useState<ListType[]>([]);
  const {
    category_detail: { payment_voucher_documents },
  } = useSelector<AppState, IDocoutState>((state) => state.docout);
  const columns: LaoITDevColumn<IPaymentVoucherDocumentItem>[] = [
    {
      title: t('payment_voucher_detail'),
      name: 'detail',
    },
    {
      title: t('amount'),
      name: 'amount',
      align: 'right',
      render: (item) => formatMoney(item),
      width: '200px',
    },
  ];
  React.useEffect(() => {
    let data: ListType[] = [],
      data2: ListType[] = [];
    if (
      payment_voucher_documents &&
      Object.values(payment_voucher_documents).length > 0
    ) {
      data.push(
        {
          id: 1,
          title: t('from_company'),
          content: payment_voucher_documents.from_company.name,
        },
        {
          id: 2,
          title: t('created_by'),
          content: payment_voucher_documents.created_by_name,
        },
        {
          id: 3,
          title: t('document_date'),
          content: formatDateToInformal(
            payment_voucher_documents.document_date
          ),
        },
        {
          id: 4,
          title: t('reference_no'),
          content: payment_voucher_documents.doc_out.reference_no,
        },
        {
          id: 5,
          title: t('document_category'),
          content: payment_voucher_documents.document_category.name,
        },
        {
          id: 6,
          title: t('cheque_number'),
          content: payment_voucher_documents.cheque_number,
        },

        {
          id: 7,
          title: t('account_number'),
          content: payment_voucher_documents.account_number,
        }
      );
      setData(data);
      data2.push(
        {
          id: 1,
          title: t('clearer'),
          content: `${payment_voucher_documents.payer.first_name} ${payment_voucher_documents.payer.last_name}`,
        },
        {
          id: 2,
          title: t('currency'),
          content: payment_voucher_documents.currency.code,
        },
        {
          id: 3,
          title: t('payment_method'),
          content: t(`${payment_voucher_documents.payment_method}`),
        },
        {
          id: 4,
          title: t('amount'),
          content: (
            <Text strong>{formatMoney(payment_voucher_documents.amount)}</Text>
          ),
        },
        {
          id: 5,
          title: t('total'),
          content: (
            <Text strong>{formatMoney(payment_voucher_documents.total)}</Text>
          ),
        },
        {
          id: 6,
          title: t('cheque_number'),
          content: payment_voucher_documents.cheque_number,
        },
        {
          id: 7,
          title: t('payment_voucher_cheque_date'),
          content:
            payment_voucher_documents && payment_voucher_documents.cheque_date
              ? formatDateToInformal(payment_voucher_documents.cheque_date)
              : '',
        }
      );
      setData2(data2);
    }
  }, [payment_voucher_documents, t]);
  return (
    <>
      <DetailList data={data} data2={data2} />
      <LaoITDevTable
        loading={false}
        dataSource={
          payment_voucher_documents &&
          payment_voucher_documents.payment_voucher_document_items
            ? payment_voucher_documents.payment_voucher_document_items.map(
                (item) => ({
                  key: item.id,
                  ...item,
                })
              )
            : []
        }
        summary={(pageData) => {
          let total = 0;
          pageData.forEach(({ amount }) => {
            total += amount;
          });
          return (
            <>
              <Table.Summary.Row style={{ textAlign: 'right' }}>
                <Table.Summary.Cell index={1}>{t('total')}</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Text type="danger">{formatMoney(total)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
        pagination={false}
        columns={columns}
      />
    </>
  );
};
