import { Reducer } from 'redux';
import { Action, ProfileState, IPublicProfile } from './type';

const initialState: ProfileState = {
  loading: false,
};

export const reducer: Reducer<ProfileState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'RECEIVE_ME':
      return {
        ...state,
        me: action.payload as IPublicProfile,
      };
    default:
      return state;
  }
};
