import { AxiosError, AxiosResponse } from 'axios';
import { channel } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { AppState } from '..';
import { subscribeErrorMessage, subscribeSuccessMessage } from '../saga';
import * as services from '../../service/Department';
import { handleDepartment } from './action';
import * as types from './type';
import { history } from "../../config/configureStore";
import { Logout } from "../../Utils";
function* handlerFetch() {
  try {
    const res = (yield call(services.fetchDepartment)) as AxiosResponse<
      types.IDepartment[]
    >;
    if (res.status === 200) {
      yield put(
        handleDepartment({ type: 'RECEIVE_IDEPARTMENT', payload: res.data })
      );
    }
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 403) {
      history.push('/unauthorized')
    } else if (err.response?.status === 401) {
      Logout()
    }
  }
}

function* handlerSave({ payload }: types.Action<'SAVE_DEPARTMENT'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.saveDepartment,
      payload
    )) as AxiosResponse<types.IDepartment>;
    if (res.status === 201) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'ADD_DEPARTMENT', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(
        handleDepartment({ type: 'FETCH_DEPARTMENT', payload: payload })
      );
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlerUpdate({ payload }: types.Action<'UPDATE_DEPARTMENT'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateDepartment,
      payload
    )) as AxiosResponse<types.IDepartment>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'EDIT_DEPARTMENT', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(
        handleDepartment({ type: 'FETCH_DEPARTMENT', payload: payload })
      );
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlerUpdateStatus({
  payload,
}: types.Action<'UPDATE_STATUS_DEPARTMENT'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateStatusDepartment,
      payload
    )) as AxiosResponse<types.IDepartment>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'EDIT_STATUS_DEPARTMENT', status: true });
      yield fork(subscribeSuccessMessage, chan);
      const departments = (yield select(
        (app: AppState) => app.department.departments
      )) as types.IDepartment[];
      const idx = yield departments.findIndex((i) => i.id === payload.id);
      departments[idx] = {
        ...departments[idx],
        status: payload.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      };
      yield put(
        handleDepartment({
          type: 'RECEIVE_IDEPARTMENT',
          payload: [...departments],
        })
      );
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlerFetchByCompany({
  payload,
}: types.Action<'FETCH_DEPARTMENT_BY_COMPANY'>) {
  try {
    const res = (yield call(
      services.fetchDepartmentByCompany,
      payload
    )) as AxiosResponse<types.IDepartment[]>;
    if (res.status === 200) {
      yield put(
        handleDepartment({ type: 'RECEIVE_IDEPARTMENT', payload: res.data })
      );
    }
  } catch (e) { }
}

function* watchHandlerFetch() {
  yield takeLatest('FETCH_DEPARTMENT', handlerFetch);
}
function* watchHandlerSave() {
  yield takeLatest('SAVE_DEPARTMENT', handlerSave);
}
function* watchHandlerUpdate() {
  yield takeLatest('UPDATE_DEPARTMENT', handlerUpdate);
}
function* watchhandlerUpdateStatus() {
  yield takeLatest('UPDATE_STATUS_DEPARTMENT', handlerUpdateStatus);
}
function* watchHandlerFetchByCompany() {
  yield takeLatest('FETCH_DEPARTMENT_BY_COMPANY', handlerFetchByCompany);
}
export function* departmentSaga() {
  yield all([
    fork(watchHandlerFetch),
    fork(watchHandlerSave),
    fork(watchHandlerUpdate),
    fork(watchhandlerUpdateStatus),
    fork(watchHandlerFetchByCompany),
  ]);
}
