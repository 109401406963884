import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import Navbar from './Navbar';
import { Notification } from './Notification';
import { ModalDialog } from './ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { handleProfile } from '../../store/profile';
import LocalStorageService from '../../LocalStorageService';
import { runOneSignal } from './OneSignal';
import { AppState } from "../../store";
import { getExternalUserIdAction } from "../../store/onesignal";
export const AdminTemplate: React.FC = (props) => {
  const dispatch = useDispatch();

  const {
    one_signal: { data }
  } = useSelector<AppState, AppState>((state) => state);

  useEffect(() => {
    dispatch(handleProfile({ type: 'FETCH_ME', payload: null }));
    dispatch(getExternalUserIdAction())
  }, [dispatch]);

  useEffect(() => {
    let isSetExternalId = true;
    if (data && isSetExternalId) {
      runOneSignal(data);
    }
    return () => {
      isSetExternalId = false
    }
  }, [data])

  return (
    <>
      <Layout
        hidden={!LocalStorageService.getAccessToken()}
      >
        <Suspense fallback={''}>
          <Navbar />
        </Suspense>
        <Layout.Content style={{ padding: '20px' }}>
          <Suspense fallback={'loading...'}>
            <Notification />
            <ModalDialog />
            <TransitionGroup>
              <CSSTransition classNames="fade" timeout={2000}>
                <Outlet />
              </CSSTransition>
            </TransitionGroup>
          </Suspense>
        </Layout.Content>
      </Layout>
    </>
  );
};
