import { useRoutes } from 'react-router-dom';
import Login from '../../components/auth/Login';
import PrivacyPolicy from '../../components/Privacy_Policy/PrivacyPolicy';
export const AuthConfig = () => {
  let routes = useRoutes([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/policy',
      element: <PrivacyPolicy />,
    },
  ]);
  return routes;
};
