import React from 'react';
import {
  Layout,
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Image,
  Typography,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CSS from 'csstype';
import { useDispatch } from 'react-redux';
import { handleAuth } from '../../store/auth/action';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
// import "../../style/Login.css";
const { Footer } = Layout;
const { Text } = Typography;
const LoginBody: CSS.Properties = {
  height: '100vh',
  minHeight: '100%',
  overflow: 'hidden',
};
const LoginBg: CSS.Properties = {
  backgroundImage: `url(${process.env.PUBLIC_URL}/background.jpg)`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  ...LoginBody,
  // position: "relative",
};
export default function Login() {
  const { t } = useTranslation(['auth', 'button']);
  const dispatch = useDispatch();
  const onFinish = (values: any) => {
    dispatch(handleAuth({ type: 'LOGIN_AUTH', payload: values }));
  };
  return (
    <Layout
      style={
        localStorage.getItem('theme-name') &&
        localStorage.getItem('theme-name') === 'dark'
          ? LoginBody
          : LoginBg
      }>
      <Row justify="space-around" align="middle" style={{ height: '100%' }}>
        <Col>
          <Card style={{ width: 350 }}>
            <div className="avatar">
              <Image
                src={`${process.env.PUBLIC_URL}/login-logo.jpg`}
                preview={false}
                width={'60%'}
              />
            </div>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}>
              <Form.Item
                name="identified"
                rules={[
                  {
                    required: true,
                    message: t('please_input_user_name', { ns: 'auth' }),
                  },
                ]}>
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  size="large"
                  placeholder={t('user_name', { ns: 'auth' })}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('please_input_password', { ns: 'auth' }),
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  size="large"
                  type="password"
                  placeholder={t('password', { ns: 'auth' })}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  size="large">
                  {t('login', { ns: 'button' })}
                </Button>
              </Form.Item>
            </Form>
            <Row justify="center" className="case_forgetting">
              <Col span={24}>
                <Text type="danger">{t('case_forgetting_password')}</Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Footer className="footer">
        <small>POWERED BY LAOITDEV VERSION 1.1</small>
      </Footer>
    </Layout>
  );
}
