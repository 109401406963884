import { Reducer } from 'redux';
import {
  Action,
  IDashboardState,
  IDocCateStatusCount,
  IDocMonthCount,
} from './type';

const initialState: IDashboardState = {
  doc_cate_status_count: [],
  doc_months_count: [],
  loading: false,
};
export const reducer: Reducer<IDashboardState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_DOCUMENT_CATEGORY_STATUS_COUNT':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_DOCUMENT_CATEGORY_STATUS_COUNT':
      return {
        ...state,
        doc_cate_status_count: action.payload as IDocCateStatusCount[],
        loading: true,
      };

    case 'FETCH_DOCUMENT_MONTHS_COUNT':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_DOCUMENT_MONTHS_COUNT':
      return {
        ...state,
        doc_months_count: action.payload as IDocMonthCount[],
        loading: true,
      };

    default:
      return state;
  }
};
