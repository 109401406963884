import { Button, Card, Popconfirm, Space, Tooltip } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { File } from '../../../../store/inbox/type';
import { formatDatetimeToInformal } from '../../../../Utils';
import { LaoITDevColumn, LaoITDevTable } from '../../../utility';
import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  ErrorState,
  handleError,
  handleSuccess,
  initialSuccessState,
  SuccessState,
} from '../../../../store/utility';
import { AppState } from '../../../../store';
import { deleteFileAction, postFileAction } from '../../../../store/inbox';
import { AddFile } from './AddFile';
interface Props {
  files: File[];
  documentID: string;
  deleteFile: (id: string) => void;
  postFile: (file: File) => void;
}
export const FileDetail: React.FC<Props> = ({
  documentID,
  files,
  deleteFile,
  postFile,
}) => {
  const { t } = useTranslation(['document', 'button']);
  const dispatch = useDispatch();
  const [submit, setSubmit] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [id, setID] = React.useState('');
  const submitSuccess = useSelector<AppState, SuccessState>(
    ({ success }) => success
  );
  const errorOnSubmit = useSelector<AppState, ErrorState>(({ error }) => error);
  const file = useSelector<AppState, File | undefined>(({ file }) => file.file);
  const columns: LaoITDevColumn<File>[] = [
    {
      title: '#',
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: t('file_name'),
      name: 'name',
      render: (_, record) => (
        <>
          <Button type="link" href={record.link} target="_blank">
            {record.name}
          </Button>
        </>
      ),
    },
    {
      title: t('created_at'),
      name: 'created_at',
      render: (item) => formatDatetimeToInformal(item),
    },
    {
      title: t('created_by'),
      name: 'created_by',
    },
    {
      title: t('action'),
      render: (_, record) => (
        <Space>
          <Tooltip placement="top" title={t('delete_file')}>
            <Popconfirm
              placement="top"
              title={t('confirm_delete_file')}
              onConfirm={() => confirmDelete(record)}
              okText={t('yes', { ns: 'button' })}
              cancelText={t('no', { ns: 'button' })}
            >
              <Button type="primary" icon={<DeleteOutlined />}></Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const confirmDelete = (record: File) => {
    setSubmit(true);
    setID(record.id);
    dispatch(deleteFileAction({ id: record.id }));
  };
  const handleAddFile = (form: FormData) => {
    setSubmit(true);
    dispatch(postFileAction({ document_id: documentID, form }));
  };
  const handleCloseModal = () => {
    setVisible(false);
  };
  React.useEffect(() => {
    if (submitSuccess.status && submitSuccess.name === 'DELETE_FILE') {
      dispatch(
        handleSuccess({
          type: 'SET_SUCCESS',
          payload: initialSuccessState,
        })
      );

      deleteFile(id);
    }
    if (errorOnSubmit.status && errorOnSubmit.name === 'DELETE_FILE') {
      dispatch(
        handleError({
          type: 'SET_ERROR',
          payload: initialSuccessState,
        })
      );
    }
    setSubmit(false);
  }, [dispatch, submitSuccess, errorOnSubmit, deleteFile, id]);

  React.useEffect(() => {
    if (submitSuccess.status && submitSuccess.name === 'ADD_FILE') {
      dispatch(
        handleSuccess({
          type: 'SET_SUCCESS',
          payload: initialSuccessState,
        })
      );
      if (file) {
        postFile(file);
        setVisible(false);
      }
    }
    if (errorOnSubmit.status && errorOnSubmit.name === 'ADD_FILE') {
      dispatch(
        handleError({
          type: 'SET_ERROR',
          payload: initialSuccessState,
        })
      );
    }
    setSubmit(false);
  }, [dispatch, submitSuccess, errorOnSubmit, file, postFile]);
  return (
    <>
      <Card
        title={t('file_list')}
        extra={
          <Button
            type="primary"
            icon={<FileAddOutlined />}
            onClick={() => {
              setVisible(true);
            }}
          >
            {t('add_file')}
          </Button>
        }
      >
        <LaoITDevTable
          loading={submit}
          dataSource={files.map((item) => ({
            ...item,
            key: item.id,
          }))}
          columns={columns}
        />
        <AddFile
          submit={submit}
          visible={visible}
          handleFinish={handleAddFile}
          handleCloseModal={handleCloseModal}
        />
      </Card>
    </>
  );
};
