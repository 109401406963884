import { lazy } from 'react';
const UserProfile = lazy(() => import('./details/UserProfile'));
const User = lazy(() => import('./User'));
const UserAdd = lazy(() => import('./UserAdd'));
const UserEdit = lazy(() => import('./UserEdit'));
const EditSignature = lazy(() => import('./details/EditSignature'));

export const userLink = {
  user: '/setting/users',
  user_add: '/setting/users/add',
  user_edit: '/setting/users/edit',
  user_detail: '/setting/users/detail',
  user_signature_edit: '/setting/users/edit-signature',
};

export const UserRoute = [
  {
    path: 'users/',
    children: [
      {
        path: '',
        element: <User />,
      },
      {
        path: 'add',
        element: <UserAdd />,
      },
      {
        path: 'edit',
        children: [
          {
            path: ':id',
            element: <UserEdit />,
          },
        ],
      },
      {
        path: 'detail',
        children: [
          {
            path: ':id',
            element: <UserProfile />,
          },
        ],
      },
      {
        path: 'edit-signature',
        children: [
          {
            path: ':id',
            element: <EditSignature />,
          },
        ],
      },
    ]
  }
]
