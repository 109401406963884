import { Reducer } from 'redux';
import { Action, ReportState, IUserReport, ICategoryReport, IDocumentRankReport, ICompanyReport } from './type';

const initialState: ReportState = {
  docin_category_report: [],
  docout_category_report: [],
  docin_document_rank_report: [],
  docout_document_rank_report: [],
  docin_company_report: [],
  docout_company_report: [],
  user_report: [],
  loading: false,
};
export const reducer: Reducer<ReportState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'FETCH_DOCIN_CATEGORY_REPORT':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_DOCIN_CATEGORY_REPORT':
      return {
        ...state,
        docin_category_report: action.payload as ICategoryReport[],
        loading: false,
      };
    case 'FETCH_DOCOUT_CATEGORY_REPORT':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_DOCOUT_CATEGORY_REPORT':
      return {
        ...state,
        docout_category_report: action.payload as ICategoryReport[],
        loading: false,
      };
    case 'FETCH_DOCIN_DOCUMENT_RANK_REPORT':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_DOCIN_DOCUMENT_RANK_REPORT':
      return {
        ...state,
        docin_document_rank_report: action.payload as IDocumentRankReport[],
        loading: false,
      };
    case 'FETCH_DOCOUT_DOCUMENT_RANK_REPORT':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_DOCOUT_DOCUMENT_RANK_REPORT':
      return {
        ...state,
        docout_document_rank_report: action.payload as IDocumentRankReport[],
        loading: false,
      };
    case 'FETCH_DOCIN_COMPANY_REPORT':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_DOCIN_COMPANY_REPORT':
      return {
        ...state,
        docin_company_report: action.payload as ICompanyReport[],
        loading: false,
      };
    case 'FETCH_DOCOUT_COMPANY_REPORT':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_DOCOUT_COMPANY_REPORT':
      return {
        ...state,
        docout_company_report: action.payload as ICompanyReport[],
        loading: false,
      };
    case 'FETCH_USER_REPORT':
      return {
        ...state,
        loading: true,
      };
    case 'RECEIVE_USER_REPORT':
      return {
        ...state,
        user_report: action.payload as IUserReport[],
        loading: false,
      };
    default:
      return state;
  }
};
