import { axiosInstant } from '../axiosInstant';
import { DocinGetQueryParams } from '../store/docin/type';
import {
  IPostAdvanceClearanceDocument,
  IPostAdvanceRequest,
  IPostExternalDocument,
  IPostPaymentVoucher,
  IPostPurchaseOrderDocout,
  IPostSignDocument,
} from '../store/docout';
import {
  IPatchAdvanceRequest,
  IUpdateSignDocument,
} from '../store/docout/type';

export const getDocinByCategory = (
  category_id: string,
  query_params?: DocinGetQueryParams
) =>
  axiosInstant
    .get(`/documents/docin/${category_id}/category`, {
      params: query_params,
    })
    .then((res) => res);

export const receiveDocinBydocinId = (document_id: string, docin_id: string) =>
  axiosInstant
    .patch(`/documents/send/${document_id}/document/${docin_id}/docin`)
    .then((res) => res);

export const postSignDocin = (
  docin_id: string,
  category_id: string,
  form: IPostSignDocument[]
) =>
  axiosInstant
    .post(`/assigns/${docin_id}/docin/${category_id}`, form)
    .then((res) => res);

export const updateSignDocin = (
  docin_id: string,
  category_id: string,
  form: IUpdateSignDocument[]
) =>
  axiosInstant
    .put(`/assigns/${docin_id}/docin/${category_id}`, form)
    .then((res) => res);

export const deleteSignDocin = (docin_id: string, assigned_id: string) =>
  axiosInstant
    .delete(`/assigns/${docin_id}/docin/${assigned_id}`)
    .then((res) => res);

export const patchDocinStatus = (docin_id: string, category: string) =>
  axiosInstant
    .patch(`/documents/docin/${docin_id}/completed`)
    .then((res) => res);

export const getDocinById = (id: string, category_id: string) =>
  axiosInstant
    .get(`/documents/docin/${id}/detail/${category_id}/category`)
    .then((res) => res);
export const postDocin = (
  category_id: string,
  form:
    | IPostPurchaseOrderDocout
    | IPostPaymentVoucher
    | IPostAdvanceRequest
    | IPostExternalDocument
    | IPostAdvanceClearanceDocument
) => axiosInstant.post(`/documents/docin/${category_id}/category`, form);

export const postDocinToDocout = (docin_id: string) =>
  axiosInstant.post(`/documents/docout/${docin_id}/docin`).then((res) => res);

export const patchDocin = (
  form:
    | IPostPurchaseOrderDocout
    | IPostPaymentVoucher
    | IPatchAdvanceRequest
    | IPostExternalDocument
    | IPostAdvanceClearanceDocument,
  category_id: string,
  id: string
) => axiosInstant.patch(`/documents/${id}/${category_id}/category`, form);
