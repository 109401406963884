import * as React from 'react';
import {
  Form,
  Input,
  Divider,
  Button,
  Typography,
  Row,
  Col,
  DatePicker,
  InputNumber,
} from 'antd';
import { MinusSquareOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { dateInformal } from "../../../Utils";
const { Item, List } = Form;
const { Text } = Typography;
interface Props {
  handleBlurPrice: () => void;
}
export const AddAdvanceClearanceItem: React.FC<Props> = (
  props
): JSX.Element => {
  const { t } = useTranslation(['document', 'button']);
  const [rows] = React.useState([
    {
      id: 0,
      detail: '',
      advance_clearance_document_date: undefined,
      remark: '',
      amount: 0,
    },
  ]);
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('content_list')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('remark')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('date')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('amount')}</Item>
        </Col>
        <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
          <Item>{t('action', { ns: 'button' })}</Item>
        </Col>
      </Row>
      <List name={'save_advance_clearance_document_items'} initialValue={rows}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => (
                <Row key={index} gutter={[16, 0]}>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item
                      {...field}
                      name={[field.name, 'detail']}
                      rules={[
                        {
                          required: true,
                          message: t('content_list_required'),
                        },
                      ]}>
                      <Input placeholder="ເນຶ້ອໃນລາຍການ" />
                    </Item>
                  </Col>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item {...field} name={[field.name, 'remark']}>
                      <Input placeholder="ປ້ອນໝາຍເຫດ" />
                    </Item>
                  </Col>
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item
                      {...field}
                      name={[field.name, 'advance_clearance_document_date']}>
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder="ປ້ອນວັນທີ"
                        format={dateInformal}
                      />
                    </Item>
                  </Col>{' '}
                  <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                    <Item name={[field.name, 'amount']}>
                      <InputNumber
                        placeholder="ປ້ອນຈຳນວນ"
                        style={{ width: '100%' }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) =>
                          value ? value.replace(/\$\s?|(,*)/g, '') : ''
                        }
                        onBlur={props.handleBlurPrice}
                      />
                    </Item>
                  </Col>
                  {field.key !== 0 && (
                    <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                      <Item>
                        <Text
                          type="danger"
                          style={{ cursor: 'pointer', fontSize: 24 }}
                          onClick={() => remove(field.name)}>
                          <MinusSquareOutlined />
                        </Text>
                      </Item>
                    </Col>
                  )}
                </Row>
              ))}
              <Divider />
              <Button
                htmlType="button"
                type="primary"
                onClick={() => add(rows[0])}>
                {t('add_content')}
              </Button>
            </>
          );
        }}
      </List>
    </>
  );
};
