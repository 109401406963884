export type StatusType = 'ACTIVE' | 'INACTIVE';
export interface IError {
  status: boolean;
  name: string;
}

export interface ErrorState {
  readonly status: boolean;
  readonly name: string;
}
export type ActionError = { SET_ERROR: IError };

export interface IErrorState {
  error: IError;
}
export type ActionMap = ActionError;
export type Action<K extends keyof ActionMap = keyof ActionMap> = {
  [P in K]: {
    type: K;
    payload: ActionMap[K];
  };
}[K];

export interface ISuccess extends IError {}
export interface SuccessState {
  readonly status: boolean;
  readonly name: string;
}
export type ActionSuccessMap = { SET_SUCCESS: ISuccess };
export type ActionSuccess<
  K extends keyof ActionSuccessMap = keyof ActionSuccessMap
> = {
  [P in K]: {
    type: K;
    payload: ActionSuccessMap[P];
  };
}[K];

export type ModalDialogIcon = 'error' | 'warning' | 'success' | 'info';
export interface ModalDialog {
  icon: ModalDialogIcon;
  message: string;
  description?: string;
}
export interface ModalDialogState {
  readonly show: boolean;
  readonly data: ModalDialog;
}

export type ActionModalDialogMap = {
  OPEN_MODAL_DIALOG: ModalDialog;
  CLOSE_MODAL_DIALOG: ModalDialog;
};
export type ActionModalDialog<
  K extends keyof ActionModalDialogMap = keyof ActionModalDialogMap
> = {
  [P in K]: {
    type: K;
    payload: ActionModalDialogMap[K];
  };
}[K];

export type NotificationIcon = 'success' | 'info' | 'warning' | 'error';
export interface Notification {
  icon: NotificationIcon;
  message: string;
  description?: string;
}
export interface NotificationState {
  readonly show: boolean;
  readonly data: Notification;
}

export type ActionNotificationMap = { OPEN_NOTIFICATION: Notification };
export type ActionNotification<
  K extends keyof ActionNotificationMap = keyof ActionNotificationMap
> = {
  [P in K]: {
    type: K;
    payload: ActionNotificationMap[K];
  };
}[K];

export interface ModalState<T> {
  open: boolean;
  modal_name: string;
  data?: T;
}
export interface ActionModalData {
  data: any;
  modal_name: string;
}

export type ActionModalMap = {
  OPEN_MODAL: ActionModalData;
  CLOSE_MODAL: ActionModalData;
};

export type ActionModal<K extends keyof ActionModalMap = keyof ActionModalMap> =
  {
    [P in K]: {
      type: K;
      payload: ActionModalMap[K];
    };
  }[K];

export const bank_in_laos = [
  'ທະນາຄານຮ່ວມພັດທະນາ JOINT DEVELOPMENT BANK',
  'ທະນາຄານ ການຄ້າຕ່າງປະເທດລາວ ມະຫາຊົນ BANQUE POUR LE COMMERCE EXTERIEUR LAO PUBLIC',
  'ທະ​ນາ​ຄານ​ພັດ​ທະ​ນາ​ລາວ Lao Development Bank',
  'ທະນາຄານ ສົ່ງເສີມກະສິກໍາ AGRICULTURAL PROMOTION BANK',
  'ທະນາຄານນະໂຍບາຍ NAYOBY BANK',
  '​ທະ​ນາ​ຄານ​ຮ່ວມ​ທຸ​ລະ​ກິດ​ລາວ-ຫວຽດ LAO-VIET BANK CO,LTD',
  'ທະນາຄານ ລາວ-ຝລັ່ງ ຈຳກັດ Banque Franco - Lao Ltd',
  'ທະນາຄານລາວຈີນ ຈໍາກັດ Lao China Bank Co.,Ltd',
  'ທະນາຄານພົງສະຫວັນ ຈໍາກັດ PHONGSAVANH BANK LTD',
  'ທະນາຄານອິນໂດຈີນ ຈຳກັດ INDOCHINA BANK LTD',
  'ທະນາຄານບູຢອງລາວ ຈໍາກັດ Booyoung Lao Bank Ltd',
  'ທະນາຄານລາວກໍ່ສ້າງ ຈໍາກັດ Lao Construction Bank Limited',
  'ທະນາຄານມາຣູຮານ ເຈແປນລາວ ຈໍາກັດ MARUHAN Japan Bank Lao Co., ltd',
  'ທະນາຄານ ບີໄອຊີ ລາວ ຈໍາກັດ BIC Bank Lao Co., Ltd',
  'ທະນາຄານ ເອັສທີ ຈໍາກັດ ST BANK LTD',
  'ທະນາຄານເອຊີລິດາລາວ ຈໍາກັດ ACLEDA BANK LAO., LTD',
  'ທະນາຄານ ອາເຮັສບີ ລາວ ຈໍາກັດ RHB Bank Lao Limited',
  'ທະນາຄານກະສິກອນໄທ ຈໍາກັດ KASIKORNTHAI BANK Limited',
  'ທະນາຄານ ໄຊງ່ອນເທື່ອງຕີ່ນ ລາວ ຈໍາກັດ SAIGON THUONG TIN BANK LAO CO., LTD',
  'ທະນາຄານ ຫວຽດຕິນ ລາວ ຈຳກັດ VietinBank Lao limited',
  'ທະນາຄານ ການຄ້າຕ່າງປະເທດຫວຽດນາມ ປະຈໍາລາວ ຈໍາກັດ Vietcombank Laos Limited',
  'ທະນາຄານ ແຄນນາເດຍ ລາວ ຈຳກັດ CANADIA BANK LAO CO., LTD',
  'ທະນາຄານ ໄຊງ່ອນ-ຮ່າໂນ້ຍ ລາວ ຈຳກັດ Saigon - Hanoi Bank Lao Limited',
  'ທະນາຄານ ກຸງເທບ ຈໍາກັດ (ມະຫາຊົນ) ສາຂານະຄອນຫຼວງວຽງຈັນ Bangkok Bank public co., ltd Vientiane Branch',
  'ທະນາຄານ ກຸງໄທ ຈໍາກັດ(ມະຫາຊົນ) ສາຂານະຄອນຫຼວງວຽງຈັນ KRUNG THAI BANK LIMITED VIENTIANE BRANCH',
  'ທະນາຄານ ກຸງສີອະຍຸດທະຍາ ຈໍາກັດ (ມະຫາຊົນ) ສາຂານະຄອນຫຼວງວຽງຈັນ BANK OF AYUDHYA PCL., VIENTIANE BRANCH',
  'ທະນາຄານ ທະຫານໄທ ຈໍາກັດ (ມະຫາຊົນ) ສາຂານະຄອນຫຼວງວຽງຈັນ TMB BNAK PUBLIC COMPANY LIMITED',
  'ທະນາຄານ ໄທພານິດ ຈໍາກັດ (ມະຫາຊົນ) ສາຂານະຄອນຫຼວງວຽງຈັນ SIAM COMMERCE BANK LTD VIENTIANE BRANCH',
  'ທະນາຄານ ພາບລິກ ເບີຣາດ ມະຫາຊົນ ສາຂານະຄອນຫຼວງວຽງຈັນ PUBLIC BANK BERHAD',
  'ທະນາຄານ ພາບລິກ ສາຂາ ວັດ​ໄຕ Public Bank Wattay Branch',
  'ທະນາຄານ ພາບລິກ ສາຂາ ສະຫວັນນະເຂດ Public Berhad Bank Ltd, Savannakhet Branch',
  'ທະນາຄານ ກຸງສີອາຍຸດທະຍາ ຈໍາກັດ (ມະຫາຊົນ) ສາຂາສະຫວັນນະເຂດ BANK OF AYUDHYA PCL., SAVANNAKHET BRANCH',
  'ທະນາຄານ ຫຸ້ນສ່ວນການຄ້າທະຫານ ສາຂາລາວ Military Commercial Joint Stock Bank - Lao Branch',
  'ທະນາຄານ ອຸດສະຫະກໍາ ແລະ ການຄ້າຈີນ ຈຳກັດ ສາຂານະຄອນຫຼວງ (ໄອຊີບີຊີ) Industrial and Commercial Bank Of China Limited Vientiane Branch',
  'ທະນາຄານ ພາບລິກ ຈໍາກັດ ສາຂາປາກເຊ Public Bank, Pakse Branch',
  'ທະນາຄານ CIMB Thai ຈໍາກັດ (ມະຫາຊົນ) ສາຂາວຽງຈັນ CIMB THAI BANK PUBLIC COMPANY LIMITED - VIENTIANE BRANCH',
  'ທະນາຄານ ຄາເທ ຢູໄນເຕັດ ສາຂານະຄອນຫຼວງວຽງຈັນ Cathay United bank - Vientiane capital Branch',
  'ທະນາຄານ ແຫ່ງ ປະເທດຈີນ ຈຳກັດ ສາຂານະຄອນຫຼວງວຽງຈັນ Bank of China Limited, Vientiane branch',
  'ເຟີດ ຄອມເມີໂຊ ແບັງ ຈຳກັດ ສາຂານະຄອນຫຼວງວຽງຈັນ First Comercial Bank LTD, Vientiane Branch',
  'ທະນາຄານ ກຸງເທບ ຈໍາກັດ (ມະຫາຊົນ) ສາຂາປາກເຊ Bangkok Bank Pcl, Pakse Branch',
  'ທະນາຄານ ມາເລຍັນ ເບີຫັດ ສາຂາໜອງດ້ວງ Malayan Banking Berhad Nongduang Branch',
  'ທະນາຄານ ມາເລຍັນ ມະຫາຊົນ ສາຂາລາວ (ທະນາຄານ ເມແບັງ ສາຂາລາວ) Malayan Banking Public Limited Company Lao Branch',
  'ກຸ່ມທະນາຄານ ອົດສະຕາລີ ແລະ ນີວຊີແລນ ຈຳກັດ ສາຂາລາວ Australia and New Zealand Banking Group Limited, Lao Branch',
  'ທະນາຄານ ໄຕ້ຫວັນ ໂຄໂອເປີເຣທິບ ຈຳກັດ ສາຂານະຄອນຫຼວງວຽງຈັນ Taiwan Cooperative Bank, Vientiane Capital Branch',
];
