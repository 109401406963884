import React from 'react'
import './Detail.css'
import { Layout } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'

const { Header, Sider, Content } = Layout

export const CustomerDetail = () => {
    const [collaped, setCollaped] = React.useState(false)

    const toggle = () => {
        setCollaped(!collaped)
    }

    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collaped}>
                <div className='logo' />
            </Sider>
            <Layout className='site-layout'>
                <Header
                    className='site-layout-background'
                    style={{ padding: 0 }}
                >
                    {React.createElement(
                        collaped ? MenuUnfoldOutlined : MenuFoldOutlined,
                        {
                            className: 'trigger',
                            onClick: toggle,
                        }
                    )}
                </Header>
                <Content
                    className='site-layout-background'
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    Content
                </Content>
            </Layout>
        </Layout>
    )
}
