import { Button, Form, message, Upload } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LaoITDevModal } from './../../../utility';
import { InboxOutlined } from '@ant-design/icons';
import antd from 'antd/lib/upload/interface';
import { CommentType } from '../../../../store/comment';
interface Props {
  visible: boolean;
  handleCloseModal: () => void;
  handleFinish: (value: FormData) => void;
  submit: boolean;
  segmentValue: CommentType;
  name: CommentType;
}
const { Dragger } = Upload;
export const UploadFile: React.FC<Props> = ({
  visible,
  handleCloseModal,
  handleFinish,
  submit,
  segmentValue,
  name,
}): JSX.Element => {
  const { t } = useTranslation(['document', 'button']);
  const [fileList, setFileList] = React.useState<antd.UploadFile<any>[]>([]);
  const [form] = Form.useForm();
  const handleUpload = (info: antd.UploadChangeParam<antd.UploadFile<any>>) => {
    const fileList = info.fileList;
    setFileList(fileList);
  };
  const props = {
    name: 'content',
    onChange: handleUpload,
    beforeUpload: (file) => {
      let checkExtension = ['jpg', 'jpeg', 'png'];
      if (segmentValue === 'file') {
        checkExtension.push(...['xls', 'xlsx', 'pdf', 'doc', 'docx']);
      }
      const type = file.name.split('.').pop();
      if (!checkExtension.includes(type ? type : '')) {
        message.error(`${file.name} is not a image or xls, xlsx, pdf file`);
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
    onPreview: () => false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };
  const handleSubmit = () => {
    if (fileList.length > 0) {
      let formData = new FormData();
      formData.append('content', fileList[fileList.length - 1].originFileObj!);
      handleFinish(formData);
    }
  };
  React.useEffect(() => {
    if (submit) {
      setFileList([]);
    }
  }, [submit]);

  return (
    <LaoITDevModal
      destroyOnClose={true}
      closable={false}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setFileList([]);
            handleCloseModal();
          }}
          size="large"
          loading={submit}
        >
          {t('cancel', { ns: 'button' })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          size="large"
          disabled={fileList.length === 0}
          onClick={form.submit}
          loading={submit}
        >
          {t('upload')}
        </Button>,
      ]}
      visible={visible && ['file', 'image'].includes(name)}
      title={''}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Dragger
          listType="picture-card"
          className={fileList.length > 0 ? 'drag-active' : 'drag-inactive'}
          {...props}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('drag_file')}</p>
          <p className="ant-upload-hint">{t('support_single_upload')}</p>
        </Dragger>
      </Form>
    </LaoITDevModal>
  );
};
