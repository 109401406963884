import * as React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Modal,
} from 'antd';
import { LaoITDevCardAdd } from '../../utility';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import {
  getCurrenciesAction,
  IDocumentState,
  getDocumentRanksAction,
  payment_methods,
} from './../../../store/document';
import { AddPaymentVoucherItem } from './AddPaymentVoucherItem';
import { dateInformal, formatMoney } from '../../../Utils';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import {
  ErrorState,
  SuccessState,
  bank_in_laos,
  initialSuccessState,
  handleSuccess,
  handleError,
} from '../../../store/utility';
import { history } from '../../../config/configureStore';
import { ISavePaymentsVoucherItem } from '../../../store/docout';
import { postPaymentVoucherDocinAction } from '../../../store/docin';
const { Item } = Form;
const { Option } = Select;
const { confirm } = Modal;
export const AddPaymentVoucher: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['document', 'button']);
  const [total, setTotal] = React.useState(0);
  const [submit, setSubmit] = React.useState(false);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { currency, document_rank } = useSelector<AppState, IDocumentState>(
    ({ document }) => document
  );
  const submitSuccess = useSelector<AppState, SuccessState>(
    ({ success }) => success
  );
  const errorOnSubmit = useSelector<AppState, ErrorState>(({ error }) => error);
  const handleFinish = (value) => {
    confirm({
      title: t('confirm_submit_document'),
      icon: <ExclamationCircleOutlined />,
      okText: t('yes', { ns: 'button' }),
      cancelText: t('no', { ns: 'button' }),
      onOk() {
        setSubmit(true);
        if (id) {
          if (!value.money_treasury) {
            delete value['money_treasury'];
          }
          dispatch(postPaymentVoucherDocinAction(id, value));
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  const handleBlurPrice = () => {
    const {
      save_payment_voucher_items,
    }: { save_payment_voucher_items: ISavePaymentsVoucherItem[] } =
      form.getFieldsValue();
    const t = save_payment_voucher_items.reduce((prev, curr) => {
      return (prev += curr.amount);
    }, 0);
    setTotal(t);
  };
  React.useEffect(() => {
    dispatch(getCurrenciesAction());
    dispatch(getDocumentRanksAction());
  }, [dispatch]);
  React.useEffect(() => {
    if (currency.currencies.length > 0) {
      form.setFieldsValue({
        currency_id: currency.currencies[0].id,
      });
    }
    if (document_rank.document_ranks.length > 0) {
      form.setFieldsValue({
        document_rank_id: document_rank.document_ranks[0].id,
      });
    }
  }, [form, currency, document_rank]);
  React.useEffect(() => {
    if (submitSuccess.status && submitSuccess.name === 'ADD_PAYMENT_DOCIN') {
      dispatch(
        handleSuccess({
          type: 'SET_SUCCESS',
          payload: initialSuccessState,
        })
      );
      history.push('/docin');
    }
    if (errorOnSubmit.status && errorOnSubmit.name === 'ADD_PAYMENT_DOCIN') {
      dispatch(
        handleError({
          type: 'SET_ERROR',
          payload: initialSuccessState,
        })
      );
    }
    setSubmit(false);
  }, [dispatch, submitSuccess, errorOnSubmit]);
  return (
    <>
      <LaoITDevCardAdd title={t('add_payment_voucher')}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" plain>
            {t('payment_voucher_detail')}
          </Divider>
          <Row gutter={[24, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('document_date')}
                name="document_date"
                required
                initialValue={moment()}
                rules={[
                  { required: true, message: t('document_date_required') },
                ]}
              >
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_document_date')}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('document_rank')} name="document_rank_id">
                <Select>
                  {document_rank &&
                    document_rank.document_ranks.map((document_rank) => (
                      <Option key={document_rank.id} value={document_rank.id}>
                        {document_rank.name}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('payment_voucher_date')} name="date_spent_money">
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_payment_voucher_date')}
                />
              </Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            {t('accountant_financial_detail')}
          </Divider>
          <Row gutter={[24, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                name="payment_method"
                label={t('payment_method')}
                initialValue={'cash'}
                required
              >
                <Select>
                  {payment_methods.map((payment_method) => (
                    <Option
                      key={payment_method.value}
                      value={payment_method.value}
                    >
                      {t(payment_method.label)}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('currency')} name="currency_id" required>
                <Select>
                  {currency &&
                    currency.currencies.map((currency) => (
                      <Option key={currency.id} value={currency.id}>
                        {currency.code}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('payment_voucher_amount')}
                name="amount"
                initialValue={0}
              >
                <InputNumber
                  placeholder={t('input_payment_voucher_amount')}
                  style={{ width: '100%' }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('bank')} name="bank_name">
                <Select placeholder={t('select_bank')}>
                  {bank_in_laos.map((bank, index) => (
                    <Option key={index} value={bank}>
                      {bank}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('account_name')} name="account_name">
                <Input placeholder={t('input_account_name')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('account_number')} name="account_number">
                <Input placeholder={t('input_account_number')} />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('payment_voucher_cheque_date')} name="cheque_date">
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_payment_voucher_cheque_date')}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('cheque_number')} name="cheque_number">
                <Input placeholder={t('input_cheque_number')} />
              </Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            {t('list')}
          </Divider>
          <AddPaymentVoucherItem handleBlurPrice={handleBlurPrice} />
          <div className="summary-payment">
            <Row gutter={[16, 0]}>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{t('grand_total')}</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{formatMoney(total)}</Item>
              </Col>
            </Row>
          </div>
          <Divider />
          <Row justify="space-between">
            <Col>
              <Button onClick={() => history.back()} size="large" loading={submit}>
                {t('cancel', { ns: 'button' })}
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={submit}
              >
                {t('save', { ns: 'button' })}
              </Button>
            </Col>
          </Row>
        </Form>
      </LaoITDevCardAdd>
    </>
  );
};
