import * as types from './type';
export const docoutInitialState: types.IDocoutState = {
  category: {
    external_documents: [],
    advance_clearance_documents: [],
    advance_request_documents: [],
    payment_voucher_documents: [],
    purchase_order_documents: [],
  },
  category_detail: {},
  loading: false,
};
export const docoutReducer = (
  state = docoutInitialState,
  action: types.DocoutActions
): types.IDocoutState => {
  switch (action.type) {
    case types.DocoutActionTypes.GET_DOCOUT_BY_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case types.DocoutActionTypes.GET_DOCOUT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        category: { ...state.category, ...action.payload },
        loading: false,
      };
    case types.DocoutActionTypes.GET_DOCOUT_BY_ID:
      return {
        ...state,
        category_detail: {},
        loading: true,
      };
    case types.DocoutActionTypes.GET_DOCOUT_BY_ID_SUCCESS:
      return {
        ...state,
        category_detail: { ...action.payload },
        loading: false,
      };
    default:
      return state;
  }
};
