import {
  ExternalUserId,
  IGetExternalAction,
  IGetExternalSuccessAction,
  OneSignalActionTypes
} from './type';

export const getExternalUserIdAction = (): IGetExternalAction => ({
  type: OneSignalActionTypes.GET_EXTERNAL_USER_ID,
});
export const getExternalUserIdSuccessAction = (
  payload: ExternalUserId
): IGetExternalSuccessAction => ({
  type: OneSignalActionTypes.GET_EXTERNAL_USER_ID_SUCCESS,
  payload,
});
