import { Reducer } from 'redux';
import { Action, UserState, IUser, IExistUser, IAssignee } from './type';

const initialState: UserState = {
  users: [],
  assignees: [],
  loading: false,
  exist: {
    email: false,
    username: false,
  },
};
export const reducer: Reducer<UserState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'RECEIVE_IUSER':
      return {
        ...state,
        users: action.payload as IUser[],
        loading: false,
      };
    case 'FETCH_USER':
      return {
        ...state,
        loading: true,
        users: [],
      };
    case 'UPDATE_STATUS_USER':
      return { ...state, loading: true };
    case 'RECEIVE_EXIST_USER':
      return {
        ...state,
        exist: { ...state.exist, ...(action.payload as IExistUser) },
      };
    case 'FETCH_USER_BY_ID':
      return {
        ...state,
        loading: true,
        users: [],
      };
    case 'RECEIVE_IASSIGNEES':
      const data = action.payload as IAssignee[];
      const ReceivePayload: IAssignee[] = data.map((assignee: IAssignee) => {
        return {
          ...assignee,
          signer: true,
        }
      }) as IAssignee[];

      return {
        ...state,
        assignees: ReceivePayload,
        loading: false,
      };
    case 'FETCH_ASSIGNEES':
      return {
        ...state,
        loading: true,
        assignees: [],
      };
    default:
      return state;
  }
};
