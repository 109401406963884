import { Moment } from 'moment';
import { ICategory } from '../category';
import { IPublicCompany } from '../company/type';
import { IPublicUser } from '../user/type';

export type DocumentStatus =
  | 'pending'
  | 'sending'
  | 'signing'
  | 'completed'
  | 'received'
  | 'rejected'
  | 'signed';
export interface IDocument {
  id: string;
  document_category: ICategory;
  document_rank: IDocumentRank;
  document_date: Moment;
  from_company: IPublicCompany;
  file?: string;
  document_status: DocumentStatus;
  created_at: Moment;
  created_by: string;
  created_by_name: string;
  created_by_phone: number;
  updated_at?: Moment;
  updated_by?: string;
  pdf_path?: string;
}
export interface IExternal {
  document_from: string;
  document_refer: string;
  document_title: string;
  contact_number?: string;
}
export interface IExternalDocument extends IExternal, IDocument {}

export interface IAdvanceClearance {
  clearer: IPublicUser;
  department_name: string;
  phone: string;
  advance_clearance_amount: number;
  payment: number;
  payment_balance: number;
  currency: ICurrency;
  advance_clearance_document_items?: IAdvanceClearanceDocumentItem[];
}
export interface IAdvanceClearanceDocument
  extends IAdvanceClearance,
    IDocument {}
export interface IAdvanceClearanceDocumentItem {
  id: number;
  advance_clearance_document_date: Moment;
  detail?: string;
  amount: number;
  remark?: string;
  currency_code: string;
}

export interface IAdvanceRequestDocument extends IAdvanceRequest, IDocument {}
export interface IAdvanceRequest {
  applicant: IPublicUser;
  amount: number;
  reason: string;
  repayment_date?: Moment;
  borrow_money_before: number;
  borrow_money_addition: number;
  total: number;
  // payment_method: PaymentMethod;
  condition_1?: string;
  condition_2?: string;
  condition_3?: string;
  currency: ICurrency;
}

export type MoneyTreasury = 'sml' | 'smg' | 'sub_cash';
export type PaymentMethod = 'cash' | 'transfer' | 'cheque';
export interface IPaymentVoucher {
  payer: IPublicUser;
  department_name: string;
  amount: number;
  date_spent_money?: Moment;
  payment_method: PaymentMethod;
  money_treasury?: MoneyTreasury;
  bank_name?: string;
  account_number?: string;
  cheque_number?: string;
  cheque_date?: Moment;
  total: number;
  payment_voucher_document_items?: IPaymentVoucherDocumentItem[];
  currency: ICurrency;
}
export interface IPaymentVoucherDocument extends IPaymentVoucher, IDocument {}
export interface IPaymentVoucherDocumentItem {
  id: number;
  detail?: string;
  amount: number;
  sub_total: number;
  price: number;
}
export interface IPurchaseOrder {
  purchase_date: Moment;
  debt_time?: Moment;
  payment_date?: Moment;
  receive_date?: Moment;
  purchaser: IPublicUser;
  supplier_name?: string;
  supplier_code?: string;
  supplier_address?: string;
  supplier_phone?: number;
  payment_method: PaymentMethod;
  bank_name?: string;
  account_number?: string;
  total: number;
  discount: number;
  net_price: number;
  vat: number;
  grand_total: number;
  currency: ICurrency;
  purchase_order_items: IPurchaseOrderDocumentItem[];
}
export interface IPurchaseOrderDocument extends IPurchaseOrder, IDocument {}
export interface IPurchaseOrderDocumentItem {
  id: number;
  quantity: number;
  price: number;
  sub_total: number;
  purchase_purpose?: string;
  detail: string;
}
export interface ISavePurchaseOrderDocumentItem
  extends IPurchaseOrderDocumentItem {}

export interface IDocumentRank {
  id: string;
  name: string;
}
export interface ICurrency {
  code: string;
  symbol: string;
  id: number;
  name: string;
}

export interface IDocumentState {
  readonly currency: {
    readonly currencies: ICurrency[];
    readonly loading: boolean;
  };
  readonly document_rank: {
    readonly document_ranks: IDocumentRank[];
    readonly loading: boolean;
  };
}
export enum DocumentActionTypes {
  GET_CURRENCIES = '@document/GET_CURRENCIES',
  GET_CURRENCIES_SUCCESS = '@document/GET_CURRENCIES_SUCCESS',
  GET_DOCUMENT_RANKS = '@document/GET_DOCUMENT_RANKS',
  GET_DOCUMENT_RANKS_SUCCESS = '@document/GET_DOCUMENT_RANKS_SUCCESS',
}

export interface IGetCurrenciesAction {
  type: DocumentActionTypes.GET_CURRENCIES;
}
export interface IGetCurrenciesSuccessAction {
  type: DocumentActionTypes.GET_CURRENCIES_SUCCESS;
  payload: ICurrency[];
}
export interface IGetDocumentRanksAction {
  type: DocumentActionTypes.GET_DOCUMENT_RANKS;
}
export interface IGetDocumentRanksSuccessAction {
  type: DocumentActionTypes.GET_DOCUMENT_RANKS_SUCCESS;
  payload: IDocumentRank[];
}

export type DocumentActions =
  | IGetCurrenciesAction
  | IGetCurrenciesSuccessAction
  | IGetDocumentRanksAction
  | IGetDocumentRanksSuccessAction;

export const payment_methods: { value: PaymentMethod; label: string }[] = [
  {
    value: 'cash',
    label: 'cash',
  },
  {
    value: 'transfer',
    label: 'transfer',
  },
  {
    value: 'cheque',
    label: 'cheque',
  },
];
