import { Reducer } from 'redux';
import * as types from './type';
export const oneSignalInitialState: types.OneSignalState = {
  loading: false,
};

export const oneSignalReducer: Reducer<
  types.OneSignalState,
  types.OneSignalActions
> = (state = oneSignalInitialState, action) => {
  switch (action.type) {
    case types.OneSignalActionTypes.GET_EXTERNAL_USER_ID:
      return {
        ...state,
        loading: true,
      };
    case types.OneSignalActionTypes.GET_EXTERNAL_USER_ID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
