import { AxiosError, AxiosResponse } from 'axios';
import { channel } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { AppState } from '..';
import { subscribeErrorMessage, subscribeSuccessMessage } from '../saga';
import * as services from '../../service/Company';
import { handleCompany } from './action';
import * as types from './type';
import { history } from '../../config/configureStore';
import { Logout } from '../../Utils';
function* handlerFetch(action: types.Action<'FETCH_COMPANY'>) {
  try {
    const res = (yield call(services.fetchCompany)) as AxiosResponse<
      types.ICompany[]
    >;
    if (res.status === 200) {
      yield put(handleCompany({ type: 'RECEIVE_ICOMPANY', payload: res.data }));
    }
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 403) {
      history.push('/unauthorized');
    } else if (err.response?.status === 401) {
      Logout();
    }
  }
}

function* handlerSave({ payload }: types.Action<'SAVE_COMPANY'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.saveCompany,
      payload
    )) as AxiosResponse<types.ICompany>;
    if (res.status === 201) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'ADD_COMPANY', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(
        handleCompany({
          type: 'FETCH_COMPANY',
          payload: payload,
        })
      );
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlerUpdate({ payload }: types.Action<'UPDATE_COMPANY'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateCompany,
      payload
    )) as AxiosResponse<types.ICompany>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'EDIT_COMPANY', status: true });
      yield fork(subscribeSuccessMessage, chan);
      // yield put(
      //   handleCompany({
      //     type: 'FETCH_COMPANY',
      //     payload: payload,
      //   })
      // );
      const companies = (yield select(
        (app: AppState) => app.company.companies
      )) as types.ICompany[];
      const idx = yield companies.findIndex((i) => i.id === payload.id);
      companies[idx] = { ...companies[idx], ...payload };
      yield put(
        handleCompany({
          type: 'RECEIVE_ICOMPANY',
          payload: [...companies] as types.ICompany[],
        })
      );
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlerUpdateStatus({
  payload,
}: types.Action<'UPDATE_STATUS_COMPANY'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateStatusCompany,
      payload
    )) as AxiosResponse<types.ICompany>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'EDIT_STATUS_COMPANY', status: true });
      yield fork(subscribeSuccessMessage, chan);
      const companies = (yield select(
        (app: AppState) => app.company.companies
      )) as types.ICompany[];
      const idx = yield companies.findIndex((i) => i.id === payload.id);
      companies[idx] = {
        ...companies[idx],
        status: payload.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      };
      yield put(
        handleCompany({
          type: 'RECEIVE_ICOMPANY',
          payload: [...companies],
        })
      );
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* watchHandlerFetch() {
  yield takeLatest('FETCH_COMPANY', handlerFetch);
}
function* watchHandlerSave() {
  yield takeLatest('SAVE_COMPANY', handlerSave);
}
function* watchHandlerUpdate() {
  yield takeLatest('UPDATE_COMPANY', handlerUpdate);
}
function* watchhandlerUpdateStatus() {
  yield takeLatest('UPDATE_STATUS_COMPANY', handlerUpdateStatus);
}
export function* companySaga() {
  yield all([
    fork(watchHandlerFetch),
    fork(watchHandlerSave),
    fork(watchHandlerUpdate),
    fork(watchhandlerUpdateStatus),
  ]);
}
