/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CustomerDetail } from '../../components/customer/Detail';
import { AdminTemplate } from '../../components/layout/AdminTemplate';
import { LoansList } from '../../components/loans/List';
import { SettingRoute } from '../../components/setting/SettingRoute';
import { docoutRoute } from '../../components/docout';
import { inboxRoute } from '../../components/inbox/inboxRoute';
import { docinRoute } from '../../components/docin/DocinRoute';
import { ProfileRoute } from '../../components/profile/ProfileRoute';
import { archiveRoute } from '../../components/archive/archiveRoute';
import { ReportRoute } from '../../components/report/ReportRoute';
import { Unauthorized } from '../../components/status_code/Unauthorized';

const Dashboard = React.lazy(
  () => import('./../../components/dashboard/Dashboard')
);

export const AdminConfig = () => {
  let routes = useRoutes([
    {
      path: '/',
      element: <AdminTemplate />,
      children: [
        { index: true, element: <Dashboard /> },
        { path: '/loans', element: <LoansList /> },
        { path: '/customer', element: <CustomerDetail /> },
        ...SettingRoute,
        ...docoutRoute,
        ...inboxRoute,
        ...docinRoute,
        ...ProfileRoute,
        ...archiveRoute,
        ...ReportRoute,
        { path: '/unauthorized', element: <Unauthorized /> },
      ],
    },
  ]);
  return routes;
};
