import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as services from '../../service/OneSignal';
import { getExternalUserIdSuccessAction } from './action';
import * as types from './type';

function* handleGetExternalUserId(): Generator {
  try {
    const { data } = (yield call(services.fetchExternalUserId)) as AxiosResponse<
      types.ExternalUserId
    >;
    yield put(getExternalUserIdSuccessAction(data));
  } catch (err) { }
}

function* watchHandlerGetExternalUserId(): Generator {
  yield takeLatest(
    types.OneSignalActionTypes.GET_EXTERNAL_USER_ID,
    handleGetExternalUserId
  );
}

export function* oneSignalSaga() {
  yield all([fork(watchHandlerGetExternalUserId)]);
}
