import {
  ICategory,
  IGetCategoriesAction,
  CategoryActionTypes,
  IGetCategoriesSuccessAction,
} from './type';

export const getCategoriesAction = (): IGetCategoriesAction => ({
  type: CategoryActionTypes.GET_CATEGORIES,
});
export const getCategoriesSuccessAction = (
  payload: ICategory[]
): IGetCategoriesSuccessAction => ({
  type: CategoryActionTypes.GET_CATEGORIES_SUCCESS,
  payload,
});
