import { IPublicCompany } from '../company';
import { IPublicUser } from '../user/type';
import { Moment } from 'moment';
import { TableForms } from '../category';
export type CommentType = 'text' | 'image' | 'audio' | 'file';
export interface Comment {
  id: string;
  company: IPublicCompany;
  commenter: IPublicUser;
  comment_type: CommentType;
  content: string;
  link?: string;
  created_at: Moment;
  updated_at?: Moment;
}

export interface Comments {
  comments: Comment[];
}

export interface ICommentState {
  readonly comment?: Comment;
}

export enum CommentActionsType {
  GET_COMMENT_SUCCESS = 'GET_COMMENT_SUCCESS',
  POST_COMMENT_TEXT = `@comment/POST_COMMENT_TEXT`,
  POST_COMMENT_IMAGE = `@comment/POST_COMMENT_IMAGE`,
  POST_COMMENT_AUDIO = `@comment/POST_COMMENT_AUDIO`,
  POST_COMMENT_FILE = `@comment/POST_COMMENT_FILE`,
}

export interface IGetCommentSuccessAction {
  type: CommentActionsType.GET_COMMENT_SUCCESS;
  payload: Comment;
}

export interface IPostCommentTextAction {
  type: CommentActionsType.POST_COMMENT_TEXT;
  payload: {
    id: string;
    table_forms: TableForms;
    content: string | FormData;
    content_type: CommentType;
  };
}

export type CommentActions = IGetCommentSuccessAction | IPostCommentTextAction;
