import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { Audio, SetRecordings } from '../../../../../store/record/type';

export default function useRecordingsList(audio: string | null, blob: any) {
  const [recordings, setRecordings] = useState<Audio[]>([]);
  function deleteAudio(audioKey: string, setRecordings: SetRecordings) {
    setRecordings((prevState) =>
      prevState.filter((record) => record.key !== audioKey)
    );
  }
  useEffect(() => {
    if (audio) {
      setRecordings((prevState: Audio[]) => {
        return [{ key: uuid(), audio, blob }];
      });
    }
  }, [audio, blob]);

  return {
    recordings,
    deleteAudio: (audioKey: string) => deleteAudio(audioKey, setRecordings),
  };
}
