import { lazy } from 'react';
import { NavLink } from '../../config/navigation';
import AddDocin from './AddDocin';
import Detail from './detail/Detail';
import EditDocin from './edit/EditDocin';
export const docoutLink = {
  list: '/docin',
};
const Docin = lazy(() => import('./Docin'));
export const docinRoute = [
  {
    path: '/docin/',
    children: [
      {
        path: '',
        element: <Docin />,
        index: true,
      },
      {
        path: 'add/',
        children: [
          {
            path: ':id/category/',
            children: [
              {
                path: ':table_forms',
                element: <AddDocin />,
              },
            ],
          },
        ],
      },
      {
        path: ':id/detail/:category/category/:table_form',
        element: <Detail />,
      },
      {
        path: ':docin_id/edit/:category/category/:table_form',
        element: <EditDocin />,
      },
    ],
  },
];

export const docinNavigator: NavLink[] = [
  {
    label: 'docin',
    name: '/docin',
    link: '/docin',
    domain: 'docin',
  },
];
