import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import commom_en from "./locale/en.json";
// import commom_la from "./locale/la.json";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
// const resources = {
//   en: {
//     translation: commom_en,
//   },
//   la: {
//     translation: commom_la,
//   },
// };
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // resources,
    // lng: "en",
    // keySeparator: false,

    // // fallbackLng: 'en',
    // // debug: true,

    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // },
    lng: 'en',
    fallbackLng: 'la', // use et if detected lng is not available
    saveMissing: true, // send not translated keys to endpoint
    debug:
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? true
        : false,
  });
export default i18n;
