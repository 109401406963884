import { SetRecorder } from '../../../../../store/record/type';
import { initialState } from './../hook/use-recorder';
export async function startRecording(setRecorderState: SetRecorder) {
  try {
    const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    setRecorderState((prevState) => {
      return {
        ...prevState,
        initRecording: true,
        mediaStream: stream,
      };
    });
  } catch (err) {
    // console.log(err);
  }
}

export function saveRecording(recorder: any) {
  if (recorder.state !== 'inactive') recorder.stop();
}

export async function setRecording(setRecorderState: SetRecorder) {
  try {
    setRecorderState((prevState) => {
      return {
        ...initialState,
      };
    });
  } catch (err) {
    // console.log(err);
  }
}
