import { Card, Col, Comment, Row, Avatar, Skeleton } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { history } from '../../../config/configureStore';
import { AppState } from '../../../store';
import { TableForms } from '../../../store/category';
import { AdvanceClearanceDetail } from './AdvanceClearanceDetail';
import { AdvanceRequestDetail } from './AdvanceRequestDetail';
import { AssignDetail } from './AssignDetail';
import { ExternalDetail } from './ExternalDetail';
import { PaymentVoucherDetail } from './PaymentVoucherDetail';
import { PurchaseOrderDetail } from './PurchaseOrderDetail';
import * as comment from '../../../store/comment';
import { trim } from 'lodash';
import { Assign, File } from '../../../store/inbox/type';
import { FileDetail } from '../../inbox/detail/file/FileDetail';
import {
  ErrorState,
  handleError,
  handleSuccess,
  initialSuccessState,
  SuccessState,
} from '../../../store/utility';
import { Editor } from '../../inbox/detail/Editor';
import { CommentList } from '../../inbox/detail/CommentList';
import { getDocoutByIdAction, IDocoutState } from '../../../store/docout';
interface DetailData {
  document_id?: string;
  table_forms?: TableForms;
  comments?: comment.Comment[];
  assigns: Assign[];
  files?: File[];
}
const Detail: React.FC = () => {
  const { t } = useTranslation(['document', 'button']);
  const dispatch = useDispatch();
  const { id, category, table_form } = useParams<{
    id: string;
    category: string;
    table_form: TableForms;
  }>();
  const [detailData, setDetailData] = React.useState<DetailData>({
    comments: [],
    assigns: [],
    files: [],
  });
  const [submit, setSubmit] = React.useState(false);
  const [segmentValue, setSegmentValue] =
    React.useState<comment.CommentType>('text');
  const submitSuccess = useSelector<AppState, SuccessState>(
    ({ success }) => success
  );
  const errorOnSubmit = useSelector<AppState, ErrorState>(({ error }) => error);
  const newComment = useSelector<AppState, comment.Comment | undefined>(
    ({ comment }) => comment.comment
  );
  const { loading, category_detail } = useSelector<AppState, IDocoutState>(
    (state) => state.docout
  );
  const docoutCategory = category_detail;
  const { me } = useSelector((state: AppState) => state.profile);

  const documentDetail = (): JSX.Element => {
    if (!table_form) {
      history.push('/docout');
    }
    if (table_form && docoutCategory[table_form]) {
      const obj = docoutCategory[table_form];
      if (obj && Object.values(obj).length > 0) {
        switch (table_form) {
          case 'external_documents':
            return <ExternalDetail />;
          case 'advance_clearance_documents':
            return <AdvanceClearanceDetail />;
          case 'advance_request_documents':
            return <AdvanceRequestDetail />;
          case 'payment_voucher_documents':
            return <PaymentVoucherDetail />;
          case 'purchase_order_documents':
            return <PurchaseOrderDetail />;
          default:
            return <></>;
        }
      }
    }
    return <></>;
  };
  React.useEffect(() => {
    if (id && category) {
      dispatch(getDocoutByIdAction(id, category));
    }
  }, [dispatch, id, category]);
  React.useEffect(() => {
    if (table_form && docoutCategory[table_form]) {
      const obj = docoutCategory[table_form];
      if (obj && Object.values(obj).length > 0) {
        setDetailData({
          assigns: obj.assigns,
          comments: obj.comments,
          document_id: obj.id,
          table_forms: obj.document_category.table_forms,
          files: obj.files,
        });
      }
    }
  }, [table_form, docoutCategory]);
  const handleFinish = (value: any) => {
    const { document_id, table_forms } = detailData;
    let content: string | FormData;
    if (segmentValue === 'text') {
      content = trim(value.content);
      if (content === '') {
        return false;
      }
    } else {
      content = value;
    }
    setSubmit(true);
    if (document_id && table_forms && value) {
      dispatch(
        comment.postCommentTextAction({
          id: document_id,
          table_forms,
          content,
          content_type: segmentValue,
        })
      );
    }
  };
  React.useEffect(() => {
    if (submitSuccess.status && submitSuccess.name === 'ADD_COMMENT') {
      if (newComment) {
        setDetailData((current) => {
          if (current.comments) {
            return { ...current, comments: [...current.comments, newComment] };
          }
          return { ...current, comments: [newComment] };
        });
        setSegmentValue('text');
      }
      dispatch(
        handleSuccess({
          type: 'SET_SUCCESS',
          payload: initialSuccessState,
        })
      );
    }
    if (errorOnSubmit.status && errorOnSubmit.name === 'ADD_COMMENT') {
      dispatch(
        handleError({
          type: 'SET_ERROR',
          payload: initialSuccessState,
        })
      );
    }
    setSubmit(false);
  }, [dispatch, submitSuccess, errorOnSubmit, newComment]);
  return (
    <>
      {loading && <Skeleton active />}
      {!loading && (
        <Row gutter={[24, 24]}>
          <Col xxl={14} lg={14} md={24} sm={24} xs={24}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Card
                  headStyle={{ alignItems: 'baseline' }}
                  title={t('document_details')}
                >
                  {documentDetail()}
                </Card>
              </Col>
              <Col span={24}>
                <Card title={t('user_list')}>
                  {id && detailData.table_forms && (
                    <AssignDetail
                      assigns={detailData.assigns}
                      id={id}
                      table_forms={detailData.table_forms}
                    />
                  )}
                </Card>
              </Col>
              <Col span={24}>
                {id && detailData.document_id && (
                  <FileDetail
                    files={detailData.files ? detailData.files : []}
                    deleteFile={(id: string) => {
                      setDetailData((item) => {
                        return {
                          ...item,
                          files: item.files?.filter((file) => file.id !== id),
                        };
                      });
                    }}
                    postFile={(file: File) => {
                      setDetailData((item) => {
                        return {
                          ...item,
                          files: [...(item.files ? item.files : []), file],
                        };
                      });
                    }}
                    documentID={detailData.document_id}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col xxl={10} lg={10} md={24} sm={24} xs={24}>
            <Card title={t('comment')}>
              <Comment
                avatar={
                  <Avatar
                    src={me?.avatar}
                    alt="profile"
                  />
                }
                content={
                  <Editor
                    submit={submit}
                    segmentValue={segmentValue}
                    setSegmentValue={setSegmentValue}
                    handleFinish={handleFinish}
                  />
                }
              />
              <CommentList
                comments={detailData.comments ? detailData.comments : []}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Detail;
