import { combineReducers } from 'redux';
import { RouterState } from 'connected-react-router';
import * as utility from './utility';
import * as company from './company';
import * as department from './department';
import * as position from './position';
import * as auth from './auth';
import * as user from './user';
import * as category from './category';
import * as docout from './docout';
import * as document from './document';
import * as inbox from './inbox';
import * as comment from './comment';
import * as docin from './docin';
import * as profile from './profile';
import * as report from './report';
import * as one_signal from './onesignal';
import * as dashboard from './dashboard';

import { all, fork } from 'redux-saga/effects';
const rootReducer = (history: any) =>
  combineReducers({
    router: history,
    error: utility.reducer,
    company: company.reducer,
    success: utility.successReducer,
    modal_dialog: utility.modalDialogReducer,
    notification: utility.notificationReducer,
    modal: utility.modalReducer,
    department: department.reducer,
    position: position.reducer,
    user: user.reducer,
    category: category.categoryReducer,
    docout: docout.docoutReducer,
    document: document.documentReducer,
    inbox: inbox.inboxReducer,
    comment: comment.commentReducer,
    docin: docin.docinReducer,
    file: inbox.fileReducer,
    profile: profile.reducer,
    report: report.reducer,
    one_signal: one_signal.oneSignalReducer,
    dashboard: dashboard.reducer,
  });
export interface AppState {
  router: RouterState;
  error: utility.ErrorState;
  company: company.CompanyState;
  success: utility.SuccessState;
  modal_dialog: utility.ModalDialogState;
  notification: utility.NotificationState;
  modal: utility.ModalState<any>;
  department: department.DepartmentState;
  position: position.PositionState;
  user: user.UserState;
  category: category.ICategoryState;
  docout: docout.IDocoutState;
  document: document.IDocumentState;
  inbox: inbox.IInboxState;
  comment: comment.ICommentState;
  docin: docin.IDocinState;
  file: inbox.IFileState;
  profile: profile.ProfileState;
  report: report.ReportState;
  one_signal: one_signal.OneSignalState;
  dashboard: dashboard.IDashboardState;
}

export default rootReducer;

export function* rootSaga() {
  yield all([
    fork(company.companySaga),
    fork(department.departmentSaga),
    fork(position.positionSaga),
    fork(auth.authSaga),
    fork(user.userSaga),
    fork(category.categorySaga),
    fork(docout.docoutSaga),
    fork(document.documentSaga),
    fork(inbox.inboxSaga),
    fork(comment.commentSaga),
    fork(docin.docinSaga),
    fork(profile.profileSaga),
    fork(report.reportSaga),
    fork(one_signal.oneSignalSaga),
    fork(dashboard.DocumentCategorySaga),
  ]);
}
