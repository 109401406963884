import { Reducer } from 'redux';
import { Action, CompanyState, ICompany } from './type';

const initialState: CompanyState = {
  companies: [],
  loading: false,
};
export const reducer: Reducer<CompanyState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'RECEIVE_ICOMPANY':
      return {
        ...state,
        companies: action.payload as ICompany[],
        loading: false,
      };
    case 'FETCH_COMPANY':
      return {
        ...state,
        loading: true,
      };
    case 'UPDATE_STATUS_COMPANY':
      return { ...state, loading: true };
    default:
      return state;
  }
};
