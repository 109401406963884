import { Card } from 'antd'
import React from 'react'

export const LoansList:React.FC = ():JSX.Element => {
  return (
    <Card>
      <div className="card-add">
        
      </div>
      hello
    </Card>
  )
}