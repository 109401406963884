import React, { useState } from 'react';
import { Image, Layout, Menu, message, Switch } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Avatar from 'antd/lib/avatar/avatar';
import { GlobalOutlined } from '@ant-design/icons';
import { navigator } from '../../config/navigation';
import { NavbarMenu } from './NavabarMenu';
import darkVars from './../../dark.json';
import lightVars from './../../light.json';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { history } from '../../config/configureStore';
import i18n from '../../i18n';
import moment from 'moment';
import { Logout } from '../../Utils';

import ImgLogo from '../archive/docin/gen-pdf_docin/ImgLogo';
const { Header } = Layout;
export default function Navbar() {
  const { t } = useTranslation('navbar');
  const { pathname } = useLocation();
  let themeName = localStorage.getItem('theme-name') || 'light';
  const [switchThemeValue, setSwitchThemeValue] = useState<boolean>(
    themeName === 'light' ? false : true
  );
  let initialValue = lightVars;
  let vars: any = {};
  try {
    vars = localStorage.getItem('app-theme');
    if (!vars) {
      vars = initialValue;
    } else {
      vars = Object.assign({}, JSON.parse(vars));
    }
  } catch (e) {
    vars = initialValue;
  } finally {
    window.less
      .modifyVars(vars)
      .then(() => {
        // setThemeApplied(true);
      })
      .catch((error: any) => {
        message.error(`Failed to update theme`);
      });
  }
  const switchTheme = (value: string) => {
    if (value === 'light') {
      setSwitchThemeValue(false);
    } else {
      setSwitchThemeValue(true);
    }
    let vars: any = value === 'light' ? lightVars : darkVars;
    vars = { ...vars, '@white': '#fff', '@black': '#000' };
    localStorage.setItem('app-theme', JSON.stringify(vars));
    localStorage.setItem('theme-name', value);
    window.less.modifyVars(vars).catch((error: any) => {});
  };

  function handleLogout() {
    Logout();
  }

  function handleShowProfile() {
    history.push('/profile');
  }

  function handleChangePassword() {
    history.push('/profile/change_password');
  }

  const onChangeLanguage = (lang: string) => {
    localStorage.setItem('lang', lang);
    const momentLocale = lang === 'la' ? 'lo' : 'en-gb';
    i18n.changeLanguage(lang);
    moment.locale(momentLocale);
  };

  const { me } = useSelector((state: AppState) => state.profile);

  const menuItems: ItemType[] = [
    {
      key: 'profileSubMenu',
      icon: (
        <>
          <Avatar
            shape="circle"
            size="small"
            src={me ? me.avatar : ''}
            style={{ marginInline: '5px', padding: '0 0px' }}
          />
          {me ? `${me.first_name} ${me.last_name}` : 'User'}
        </>
      ),
      children: [
        {
          key: 'profile',
          onClick: handleShowProfile,
          label: t('profile'),
        },
        {
          key: 'change_password',
          onClick: handleChangePassword,
          label: t('change_password'),
        },
        {
          key: 'logout',
          onClick: handleLogout,
          label: t('logout'),
        },
      ],
    },
    {
      key: 'changeLanguageSubMenu',
      icon: <GlobalOutlined style={{ fontSize: 20 }} />,
      children: [
        {
          key: 'en_lang',
          onClick: () => onChangeLanguage('en'),
          icon: (
            <span
              role="img"
              aria-label="english"
              style={{ marginRight: '8px' }}
            >
              EN
            </span>
          ),
        },
        {
          key: 'la_lang',
          onClick: () => onChangeLanguage('la'),
          icon: (
            <span role="img" aria-label="lao" style={{ marginRight: '8px' }}>
              LA
            </span>
          ),
        },
      ],
    },
    {
      key: 'switchTheme',
      icon: (
        <Switch
          checkedChildren={t('dark')}
          unCheckedChildren={t('light')}
          onChange={() => switchTheme(!switchThemeValue ? 'dark' : 'light')}
          defaultChecked={themeName === 'light' ? false : true}
        />
      ),
    },
  ];

  const all_menu_items =
    me &&
    me.permissions &&
    navigator
      .map((i, index) => NavbarMenu(i, me.permissions, t))
      .concat(menuItems);

  return (
    <>
      <Header className="navbar">
        <div className="logo">
          <Link to="/">
            <Image
              style={{ width: 'auto' }}
              src={`${process.env.PUBLIC_URL}/LOGO_SMG.png`}
              preview={false}
            />
          </Link>
        </div>
        <Menu
          mode="horizontal"
          items={all_menu_items}
          selectedKeys={[pathname]}
        />
      </Header>
    </>
  );
}
