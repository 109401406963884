import { axiosInstant } from '../axiosInstant';
import { IDepartment } from '../store/department';

export const fetchDepartment = () =>
  axiosInstant.get('/departments').then((res) => res);

export const saveDepartment = (data: IDepartment) =>
  axiosInstant
    .post('/departments', {
      name: data.name,
      company_id: data.company.id,
    })
    .then((res) => res);

export const updateDepartment = (data: IDepartment) =>
  axiosInstant
    .put(`/departments/${data.id}`, {
      name: data.name,
      company_id: data.company.id,
    })
    .then((res) => res);
    
export const updateStatusDepartment = (data: IDepartment) =>
  axiosInstant
    .patch(
      `/departments/${data.id}/status/${
        data.status === 'ACTIVE' ? 'inactive' : 'active'
      }`,
      data
    )
    .then((res) => res);

export const fetchDepartmentByCompany = (id: string) =>
  axiosInstant.get(`/companies/${id}/departments`).then((res) => res);
