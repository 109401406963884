import { Button, Form } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CommentType } from '../../../../store/comment';
import { UseRecorder } from '../../../../store/record/type';
import { LaoITDevModal } from '../../../utility';
import useRecorder from './hook/use-recorder';
import RecorderControls from './recorder-control/RecorderControls';
import RecordingsList from './recording-list/RecordingList';
import useRecordingsList from './recording-list/use-recordings-list';
interface Props {
  visible: boolean;
  handleCloseModal: () => void;
  handleFinish: (value: FormData) => void;
  submit: boolean;
  name: CommentType;
}
export const Record: React.FC<Props> = ({
  visible,
  handleCloseModal,
  handleFinish,
  submit,
  name,
}): JSX.Element => {
  const { t } = useTranslation(['document', 'button']);
  const { recorderState, ...handlers }: UseRecorder = useRecorder();
  const { audio, blob } = recorderState;
  const { recordings } = useRecordingsList(audio, blob);
  const [form] = Form.useForm();
  const handleSubmit = () => {
    const form = new FormData();
    form.append(
      'content',
      recordings[0].blob as any,
      `${recordings[0].key}.acc`
    );
    handleFinish(form);
  };
  return (
    <LaoITDevModal
      destroyOnClose={true}
      title={<>{t('recorder', { ns: 'button' })}</>}
      visible={visible && name === 'audio'}
      closable={false}
      footer={[
        <Button
          key="back"
          onClick={() => {
            handlers.setRecording();
            handleCloseModal();
          }}
          size="large"
          loading={submit}>
          {t('cancel', { ns: 'button' })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          size="large"
          onClick={form.submit}
          loading={submit}
          disabled={!recordings.length}>
          {t('upload')}
        </Button>,
      ]}>
      <Form
        onFinish={() => {
          handleSubmit();
        }}
        form={form}>
        <section className="voice-recorder">
          <div className="recorder-container">
            <RecorderControls
              recorderState={recorderState}
              handlers={handlers}
            />
            <RecordingsList audio={audio} blob={blob} />
          </div>
        </section>
      </Form>
    </LaoITDevModal>
  );
};
