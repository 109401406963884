import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as types from './type';
import * as api from './../../service/Document';
import {
  getCurrenciesSuccessAction,
  getDocumentRanksSuccessAction,
} from './action';
import { AxiosResponse } from 'axios';
function* handleGetCurrencies() {
  try {
    const res = (yield call(api.getCurrencies)) as AxiosResponse<
      types.ICurrency[]
    >;
    yield put(getCurrenciesSuccessAction(res.data));
  } catch (err) {
    // console.log(err);
  }
}

function* watchHandlerGetCurrencies() {
  yield takeLatest(
    types.DocumentActionTypes.GET_CURRENCIES,
    handleGetCurrencies
  );
}

function* handleGetDocumentRanks() {
  try {
    const res = (yield call(api.getDocumentRanks)) as AxiosResponse<
      types.IDocumentRank[]
    >;
    yield put(getDocumentRanksSuccessAction(res.data));
  } catch (err) {
    // console.log(err);
  }
}

function* watchHandlerGetDocumentRanks() {
  yield takeLatest(
    types.DocumentActionTypes.GET_DOCUMENT_RANKS,
    handleGetDocumentRanks
  );
}

export function* documentSaga() {
  yield all([
    fork(watchHandlerGetCurrencies),
    fork(watchHandlerGetDocumentRanks),
  ]);
}
