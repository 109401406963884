import { TableForms } from '../category';
import * as types from './type';

export const getDocoutByCategory = (
  category_id: string,
  table_form: TableForms,
  query_params?: types.DocoutGetQueryParams,
): types.IGetDocoutByCategory => ({
  type: types.DocoutActionTypes.GET_DOCOUT_BY_CATEGORY,
  payload: {
    category_id: category_id,
    query_params: query_params,
    table_form: table_form,
  },
});

export const getDocoutByCategorySuccessAction = (
  payload: types.IGetDocoutByCategorySuccessAction['payload']
): types.IGetDocoutByCategorySuccessAction => ({
  type: types.DocoutActionTypes.GET_DOCOUT_BY_CATEGORY_SUCCESS,
  payload,
});

export const postPurchaseOrderDocoutAction = (
  category_id: string,
  form: types.IPostPurchaseOrderDocout
): types.IPostPurchaseOrderDocoutAction => ({
  type: types.DocoutActionTypes.POST_PURCHASE_ORDER_DOCOUT,
  payload: {
    category_id,
    form,
  },
});

export const postPaymentVoucherDocoutAction = (
  category_id: string,
  form: types.IPostPaymentVoucher
) => ({
  type: types.DocoutActionTypes.POST_PAYMENT_VOUCHER_DOCOUT,
  payload: {
    category_id,
    form,
  },
});

export const postAdvanceRequestDocoutAction = (
  category_id: string,
  form: types.IPostAdvanceRequest
) => ({
  type: types.DocoutActionTypes.POST_ADVANCE_REQUEST_DOCOUT,
  payload: {
    category_id,
    form,
  },
});

export const postExternalDocoutAction = (
  category_id: string,
  form: types.IPostExternalDocument
) => ({
  type: types.DocoutActionTypes.POST_EXTERNAL_DOCOUT,
  payload: {
    category_id,
    form,
  },
});

export const postAdvanceClearanceDocoutAction = (
  category_id: string,
  form: types.IPostAdvanceClearanceDocument
) => ({
  type: types.DocoutActionTypes.POST_ADVANCE_CLEARANCE_DOCOUT,
  payload: {
    category_id,
    form,
  },
});

export const postSignDocoutAction = (
  docout_id: string,
  category_id: string,
  table_form: TableForms,
  form: types.IPostSignDocument[]
): types.IPostSignDocoutAction => ({
  type: types.DocoutActionTypes.POST_SIGN_DOCOUT,
  payload: {
    docout_id,
    category_id,
    table_form,
    form,
  },
});



export const updateSignDocoutAction = (
  docout_id: string,
  category_id: string,
  table_form: TableForms,
  form: types.IUpdateSignDocument[]
): types.IUpdateSignDocoutAction => ({
  type: types.DocoutActionTypes.PUT_SIGN_DOCOUT,
  payload: {
    docout_id,
    category_id,
    table_form,
    form,
  },
});

export const deleteSignDocoutAction = (
  payload: types.IDeleteSignDocoutAction['payload']
): types.IDeleteSignDocoutAction => ({
  type: types.DocoutActionTypes.DELETE_SIGN_DOCOUT,
  payload,
});

export const sendDocumentAction = (
  payload: types.ISendDocumentAction['payload']
): types.ISendDocumentAction => ({
  type: types.DocoutActionTypes.SEND_DOCUMENT,
  payload,
});

export const getDocoutByIdAction = (
  id: string,
  category_id: string
): types.IGetDocumentByIdAction => ({
  type: types.DocoutActionTypes.GET_DOCOUT_BY_ID,
  payload: {
    id,
    category_id,
  },
});


export const getDocoutByIdSuccessAction = (
  category: types.DocoutDetailCategory
): types.IGetDocumentByIdSuccessAction => ({
  type: types.DocoutActionTypes.GET_DOCOUT_BY_ID_SUCCESS,
  payload: category,
});
export const patchAdvanceRequestDocoutAction = (
  form: types.IPatchAdvanceRequest,
  category_id: string,
  id: string,
  docout_id: string
) => ({
  type: types.DocoutActionTypes.PATCH_ADVANCE_REQUEST_DOCOUT,
  payload: {
    form,
    category_id,
    id,
    docout_id
  },
});

export const patchAdvanceClearanceDocoutAction = (
  form: types.IPatchAdvanceClearanceDocument,
  category_id: string,
  id: string,
  docout_id: string
) => ({
  type: types.DocoutActionTypes.PATCH_ADVANCE_CLEARANCE_DOCOUT,
  payload: {
    form,
    category_id,
    id,
    docout_id
  },
});

export const patchPurchaseOrderDocoutAction = (
  form: types.IPatchPurchaseOrder,
  category_id: string,
  id: string,
  docout_id: string
) => ({
  type: types.DocoutActionTypes.PATCH_PURCHASE_ORDER_DOCOUT,
  payload: {
    form,
    category_id,
    id,
    docout_id
  },
});

export const patchPaymentVoucherDocoutAction = (
  form: types.IPatchPaymentVoucher,
  category_id: string,
  id: string,
  docout_id: string
) => ({
  type: types.DocoutActionTypes.PATCH_PAYMENT_VOUCHER_DOCOUT,
  payload: {
    form,
    category_id,
    id,
    docout_id
  },
});

export const patchExternalDocoutAction = (
  form: types.IPatchExternalDocument,
  category_id: string,
  id: string,
  docout_id: string
) => ({
  type: types.DocoutActionTypes.PATCH_EXTERNAL_DOCOUT,
  payload: {
    form,
    category_id,
    id,
    docout_id
  },
});

export const patchDocoutStatusAction = (
  payload: types.IPatchDocoutStatusAction['payload']
): types.IPatchDocoutStatusAction => ({
  type: types.DocoutActionTypes.PATCH_DOCOUT_STATUS,
  payload,
});