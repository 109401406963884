import { Avatar, List } from 'antd';
import * as React from 'react';
import { Assign } from '../../../store/inbox';
import { TableForms } from '../../../store/category';
interface Props {
  assigns: Assign[];
  id: string;
  table_forms: TableForms;
}
export const AssignDetail: React.FC<Props> = ({ assigns }) => {
  return (
    <>
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={assigns}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={<Avatar size="large" src={item.assigned.avatar} />}
              title={`${item.assigned.first_name} ${item.assigned.last_name}`}
              description={item.assigned.email}
            />
          </List.Item>
        )}
      />
    </>
  );
};
