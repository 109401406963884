import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as services from '../../service/Report';
import { handleReport } from "./action";
import * as types from './type';

function* handlerFetchDocinCategoryReport() {
  try {
    const res = (yield call(services.getDocinCategoryReport)) as AxiosResponse<
      types.ICategoryReport[]
    >;
    if (res.status === 200) {
      yield put(
        handleReport({ type: 'RECEIVE_DOCIN_CATEGORY_REPORT', payload: res.data })
      );
    }
  } catch (e) { }
}

function* handlerFetchDocoutCategoryReport() {
  try {
    const res = (yield call(services.getDocinCategoryReport)) as AxiosResponse<
      types.ICategoryReport[]
    >;
    if (res.status === 200) {
      yield put(
        handleReport({ type: 'RECEIVE_DOCOUT_CATEGORY_REPORT', payload: res.data })
      );
    }
  } catch (e) { }
}

function* handlerFetchDocinDocumentRankReport() {
  try {
    const res = (yield call(services.getDocinDocumentRankReport)) as AxiosResponse<
      types.IDocumentRankReport[]
    >;
    if (res.status === 200) {
      yield put(
        handleReport({ type: 'RECEIVE_DOCIN_DOCUMENT_RANK_REPORT', payload: res.data })
      );
    }
  } catch (e) { }
}

function* handlerFetchDocoutDocumentRankReport() {
  try {
    const res = (yield call(services.getDocoutDocumentRankReport)) as AxiosResponse<
      types.IDocumentRankReport[]
    >;
    if (res.status === 200) {
      yield put(
        handleReport({ type: 'RECEIVE_DOCOUT_DOCUMENT_RANK_REPORT', payload: res.data })
      );
    }
  } catch (e) { }
}

function* handlerFetchDocinCompanyReport() {
  try {
    const res = (yield call(services.getDocinCompanyReport)) as AxiosResponse<
      types.ICompanyReport[]
    >;
    if (res.status === 200) {
      yield put(
        handleReport({ type: 'RECEIVE_DOCIN_COMPANY_REPORT', payload: res.data })
      );
    }
  } catch (e) { }
}

function* handlerFetchDocoutCompanyReport() {
  try {
    const res = (yield call(services.getDocoutCompanyReport)) as AxiosResponse<
      types.ICompanyReport[]
    >;
    if (res.status === 200) {
      yield put(
        handleReport({ type: 'RECEIVE_DOCOUT_COMPANY_REPORT', payload: res.data })
      );
    }
  } catch (e) { }
}

function* handlerFetchUserReport() {
  try {
    const res = (yield call(services.getUserReport)) as AxiosResponse<
      types.IUserReport[]
    >;
    if (res.status === 200) {
      yield put(
        handleReport({ type: 'RECEIVE_USER_REPORT', payload: res.data })
      );
    }
  } catch (e) { }
}

function* watchHandlerFetchDocinCategoryReport() {
  yield takeLatest('FETCH_DOCIN_CATEGORY_REPORT', handlerFetchDocinCategoryReport);
}

function* watchHandlerFetchDocoutCategoryReport() {
  yield takeLatest('FETCH_DOCOUT_CATEGORY_REPORT', handlerFetchDocoutCategoryReport);
}

function* watchHandlerFetchDocinDocumentRankReport() {
  yield takeLatest('FETCH_DOCIN_DOCUMENT_RANK_REPORT', handlerFetchDocinDocumentRankReport);
}

function* watchHandlerFetchDocoutDocumentRankReport() {
  yield takeLatest('FETCH_DOCOUT_DOCUMENT_RANK_REPORT', handlerFetchDocoutDocumentRankReport);
}

function* watchHandlerFetchDocinCompanyReport() {
  yield takeLatest('FETCH_DOCIN_COMPANY_REPORT', handlerFetchDocinCompanyReport);
}

function* watchHandlerFetchDocoutCompanyReport() {
  yield takeLatest('FETCH_DOCOUT_COMPANY_REPORT', handlerFetchDocoutCompanyReport);
}

function* watchHandlerFetchUserReport() {
  yield takeLatest('FETCH_USER_REPORT', handlerFetchUserReport);
}

export function* reportSaga() {
  yield all([
    fork(watchHandlerFetchDocinCategoryReport),
    fork(watchHandlerFetchDocoutCategoryReport),
    fork(watchHandlerFetchDocinDocumentRankReport),
    fork(watchHandlerFetchDocoutDocumentRankReport),
    fork(watchHandlerFetchDocinCompanyReport),
    fork(watchHandlerFetchDocoutCompanyReport),
    fork(watchHandlerFetchUserReport),
  ]);
}
