import { IPublicCompany } from '../company';
import { Moment } from 'moment';
import {
  DocumentStatus,
  IAdvanceClearanceDocument,
  IAdvanceRequestDocument,
  IExternalDocument,
  IPaymentVoucherDocument,
  IPurchaseOrderDocument,
  PaymentMethod,
} from '../document/type';
import { TableForms } from '../category';
import {
  IPostAdvanceClearanceDocument,
  IPostAdvanceRequest,
  IPostExternalDocument,
  IPostPaymentVoucher,
  IPostPurchaseOrderDocout,
  IPostSignDocument,
} from '../docout';
import { Comments } from '../comment';
import { Assigns, Files } from '../inbox';
import {
  IPatchAdvanceClearanceDocument,
  IPatchAdvanceRequest,
  IPatchExternalDocument,
  IPatchPaymentVoucher,
  IPatchPurchaseOrder,
  IUpdateSignDocument,
} from '../docout/type';
export interface IDocIn {
  id: string;
  company: IPublicCompany;
  reference_no?: string;
  created_at: Moment;
  created_by: string;
  created_by_name: string;
  docin_status?: DocumentStatus;
  doc_out: boolean;
  pdf_path?: string;
}
export interface IExternalDocin
  extends IExternalDocument,
    Comments,
    Files,
    Assigns {
  doc_in: IDocIn;
  files;
  assigns;
}
export interface IAdvanceClearanceDocin
  extends IAdvanceClearanceDocument,
    Comments,
    Files,
    Assigns {
  doc_in: IDocIn;
}
export interface IAdvanceRequestDocin
  extends IAdvanceRequestDocument,
    Comments,
    Files,
    Assigns {
  doc_in: IDocIn;
}
export interface IPaymentVoucherDocin
  extends IPaymentVoucherDocument,
    Comments,
    Files,
    Assigns {
  doc_in: IDocIn;
}
export interface IPurchaseOrderDocin
  extends IPurchaseOrderDocument,
    Comments,
    Files,
    Assigns {
  doc_in: IDocIn;
}

export interface IDocinCategory {
  external_documents: IExternalDocin[];
  advance_clearance_documents: IAdvanceClearanceDocin[];
  advance_request_documents: IAdvanceRequestDocin[];
  payment_voucher_documents: IPaymentVoucherDocin[];
  purchase_order_documents: IPurchaseOrderDocin[];
}

export interface DocinDetailCategory {
  external_documents?: IExternalDocin;
  advance_clearance_documents?: IAdvanceClearanceDocin;
  advance_request_documents?: IAdvanceRequestDocin;
  payment_voucher_documents?: IPaymentVoucherDocin;
  purchase_order_documents?: IPurchaseOrderDocin;
}

export interface DocinGetQueryParams {
  document_status?: DocumentStatus;
  reference_no?: string;
  applicant?: string;
  supplier_name?: string;
  payment_method?: PaymentMethod;
  currency?: number;
  total_money?: number;
  account_number?: string;
}

export interface IDocinState {
  readonly category: IDocinCategory;
  readonly category_detail: DocinDetailCategory;
  readonly loading: boolean;
}

export interface ICreatePDFState {
  type: DocinActionTypes.CREATE_PDF;
  payload: IPurchaseOrderDocin;
}

export enum DocinActionTypes {
  CREATE_PDF = `@CREATE_PDF`,
  GET_DOCIN_BY_CATEGORY = `@docin/GET_BY_CATEGORY`,
  GET_DOCIN_BY_CATEGORY_SUCCESS = `@docin/GET_BY_CATEGORY_SUCCESS`,
  RECEIVE_DOCIN_BY_DOCUMENT_DOCIN = `@docin/RECEIVE_DOCIN_BY_DOCUMENT_DOCIN`,
  POST_SIGN_DOCIN = `@docin/POST_SIGN_DOCIN`,
  PUT_SIGN_DOCIN = `@docin/PUT_SIGN_DOCIN`,
  DELETE_SIGN_DOCIN = `@docin/DELETE_SIGN_DOCIN`,
  PATCH_DOCIN_STATUS = `@docin/PATCH_DOCIN_STATUS`,
  POST_PURCHASE_ORDER_DOCIN = `@docin/POST_PURCHASE_ORDER_DOCIN`,
  POST_PAYMENT_VOUCHER_DOCIN = `@docin/POST_PAYMENT_VOUCHER_DOCIN`,
  POST_ADVANCE_REQUEST_DOCIN = `@docin/POST_ADVANCE_REQUEST_DOCIN`,
  POST_EXTERNAL_DOCIN = `@docin/POST_EXTERNAL_DOCIN`,
  POST_ADVANCE_CLEARANCE_DOCIN = `@docin/POST_ADVANCE_CLEARANCE_DOCIN`,
  GET_DOCIN_BY_ID = '@docin/GET_DOCIN_BY_ID',
  GET_DOCIN_BY_ID_SUCCESS = '@docin/GET_DOCIN_BY_ID_SUCCESS',
  POST_DOCIN_TO_DOCOUT = '@docin/POST_DOCIN_TO_DOCOUT',
  PATCH_ADVANCE_REQUEST_DOCIN = `@docin/PATCH_ADVANCE_REQUEST_DOCIN`,
  PATCH_ADVANCE_CLEARANCE_DOCIN = `@docin/PATCH_ADVANCE_CLEARANCE_DOCIN`,
  PATCH_PURCHASE_ORDER_DOCIN = `@docin/PATCH_PURCHASE_ORDER_DOCIN`,
  PATCH_PAYMENT_VOUCHER_DOCIN = '@docout/PATCH_PAYMENT_VOUCHER_DOCIN',
  PATCH_EXTERNAL_DOCIN = '@docout/PATCH_EXTERNAL_DOCIN',
}
export interface IGetDocinByCategory {
  type: DocinActionTypes.GET_DOCIN_BY_CATEGORY;
  payload: {
    category_id: string;
    table_form: TableForms;
    query_params?: DocinGetQueryParams;
  };
}

export interface IGetDocinByCategorySuccessAction {
  type: DocinActionTypes.GET_DOCIN_BY_CATEGORY_SUCCESS;
  payload: IDocinCategory;
}
export interface IReceiveDocinByDocumentDocinAction {
  type: DocinActionTypes.RECEIVE_DOCIN_BY_DOCUMENT_DOCIN;
  payload: {
    docin_id: string;
    document_id: string;
    document_category: string;
    query_params?: DocinGetQueryParams;
    table_forms: TableForms;
  };
}
export interface IPatchDocinStatusAction {
  type: DocinActionTypes.PATCH_DOCIN_STATUS;
  payload: {
    docin_id: string;
    document_category: string;
    table_forms: TableForms;
    query_params?: DocinGetQueryParams;
  };
}
export interface IPostSignDocinAction {
  type: DocinActionTypes.POST_SIGN_DOCIN;
  payload: {
    form: IPostSignDocument[];
    category_id: string;
    table_form: TableForms;
    docin_id: string;
  };
}

export interface IUpdateSignDocinAction {
  type: DocinActionTypes.PUT_SIGN_DOCIN;
  payload: {
    form: IUpdateSignDocument[];
    category_id: string;
    table_form: TableForms;
    docin_id: string;
  };
}

export interface IDeleteSignDocinAction {
  type: DocinActionTypes.DELETE_SIGN_DOCIN;
  payload: {
    docin_id: string;
    assigned_id: string;
  };
}

export interface IPostPurchaseOrderDocinAction {
  type: DocinActionTypes.POST_PURCHASE_ORDER_DOCIN;
  payload: {
    form: IPostPurchaseOrderDocout;
    category_id: string;
  };
}
export interface IPostPaymentVoucherDocinAction {
  type: DocinActionTypes.POST_PAYMENT_VOUCHER_DOCIN;
  payload: {
    form: IPostPaymentVoucher;
    category_id: string;
  };
}
export interface IPostAdvanceRequestDocinAction {
  type: DocinActionTypes.POST_ADVANCE_REQUEST_DOCIN;
  payload: {
    form: IPostAdvanceRequest;
    category_id: string;
  };
}
export interface IPostExternalDocinAction {
  type: DocinActionTypes.POST_EXTERNAL_DOCIN;
  payload: {
    form: IPostExternalDocument;
    category_id: string;
  };
}

export interface IPostAdvanceClearanceDocinAction {
  type: DocinActionTypes.POST_ADVANCE_CLEARANCE_DOCIN;
  payload: {
    form: IPostAdvanceClearanceDocument;
    category_id: string;
  };
}

export interface IGetDocumentByIdAction {
  type: DocinActionTypes.GET_DOCIN_BY_ID;
  payload: {
    id: string;
    category_id: string;
  };
}
export interface IGetDocumentByIdSuccessAction {
  type: DocinActionTypes.GET_DOCIN_BY_ID_SUCCESS;
  payload: DocinDetailCategory;
}
export interface IPostDocinToDocoutAction {
  type: DocinActionTypes.POST_DOCIN_TO_DOCOUT;
  payload: {
    docin_id: string;
    table_forms: TableForms;
  };
}
export interface IPatchAdvanceRequestDocinAction {
  type: DocinActionTypes.PATCH_ADVANCE_REQUEST_DOCIN;
  payload: {
    form: IPatchAdvanceRequest;
    category_id: string;
    id: string;
    docin_id: string;
  };
}
export interface IPatchAdvanceClearanceDocinAction {
  type: DocinActionTypes.PATCH_ADVANCE_CLEARANCE_DOCIN;
  payload: {
    form: IPatchAdvanceClearanceDocument;
    category_id: string;
    id: string;
    docin_id: string;
  };
}
export interface IPatchPurchaseOrderDocinAction {
  type: DocinActionTypes.PATCH_PURCHASE_ORDER_DOCIN;
  payload: {
    form: IPatchPurchaseOrder;
    category_id: string;
    id: string;
    docin_id: string;
  };
}
export interface IPatchPaymentVoucherDocinAction {
  type: DocinActionTypes.PATCH_PAYMENT_VOUCHER_DOCIN;
  payload: {
    form: IPatchPaymentVoucher;
    category_id: string;
    id: string;
    docin_id: string;
  };
}
export interface IPatchExternalDocinAction {
  type: DocinActionTypes.PATCH_EXTERNAL_DOCIN;
  payload: {
    form: IPatchExternalDocument;
    category_id: string;
    id: string;
    docin_id: string;
  };
}

export type DocinActions =
  | IGetDocinByCategory
  | IGetDocinByCategorySuccessAction
  | IReceiveDocinByDocumentDocinAction
  | IPostSignDocinAction
  | IPatchDocinStatusAction
  | IPostPurchaseOrderDocinAction
  | IPostPaymentVoucherDocinAction
  | IPostAdvanceRequestDocinAction
  | IPostExternalDocinAction
  | IPostAdvanceClearanceDocinAction
  | IGetDocumentByIdAction
  | IGetDocumentByIdSuccessAction
  | IPostDocinToDocoutAction
  | IPatchAdvanceRequestDocinAction
  | IPatchAdvanceClearanceDocinAction
  | IPatchPurchaseOrderDocinAction
  | IPatchPaymentVoucherDocinAction
  | IPatchExternalDocinAction;
