import { axiosInstant } from '../axiosInstant';
import { IUser } from '../store/user';
import { IUploadProfileImageForAdmin, IUploadSignatureImageForAdmin } from "../store/user/type";

export const fetchUser = () => axiosInstant.get('/users').then((res) => res);

export const saveUser = (data: IUser) =>
  axiosInstant
    .post('/users', {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      gender: data.gender,
      email: data.email,
      username: data.username,
      password: data.password,
      signer: data.signer,
      position_id: data.position.id,
      company_id: data.company.id,
      department_id: data.department.id,
    })
    .then((res) => res);

export const updateUser = (data: IUser) =>
  axiosInstant
    .put(`/users/${data.id}`, {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      gender: data.gender,
      email: data.email,
      username: data.username,
      password: data.password,
      signer: data.signer,
      position_id: data.position.id,
      company_id: data.company.id,
      department_id: data.department.id,
    })
    .then((res) => res);
export const updateStatusUser = (data: IUser) =>
  axiosInstant
    .patch(
      `/users/${data.id}/status/${data.status === 'ACTIVE' ? 'inactive' : 'active'
      }`,
      data
    )
    .then((res) => res);
export const fetchUserByEmail = (email: string) =>
  axiosInstant.get(`/user/exist`, { params: email }).then((res) => res);

export const fetchUserByUsername = (username: string) =>
  axiosInstant.get(`/user/exist`, { params: username }).then((res) => res);

export const fetchUserByID = (id: string) =>
  axiosInstant.get(`/users/${id}`).then((res) => res);

export const fetchUserBySearch = (search: string) =>
  axiosInstant
    .get(`/users/search`, { params: { search: search } })
    .then((res) => res);

export const updateProfileImage = (data: IUploadProfileImageForAdmin) =>
  axiosInstant
    .patch(`/users/avatar/${data.id}`, data.avatar)
    .then((res) => res);

export const updateSignatureImage = (payload: IUploadSignatureImageForAdmin) =>
  axiosInstant
    .patch(`/users/signature/${payload.id}`, payload.signature)
    .then((res) => res);

export const fetchAssignees = () => axiosInstant.get('/users/assignee').then((res) => res);