import { lazy } from 'react';
import { NavLink } from '../../config/navigation';
const DocinCategoryReport = lazy(() => import('./docin/CategoryReport'));
const DocoutCategoryReport = lazy(() => import('./docout/CategoryReport'));
const DocinDocumentRankReport = lazy(
  () => import('./docin/DocumentRankReport')
);
const DocoutDocumentRankReport = lazy(
  () => import('./docout/DocumentRankReport')
);
const DocinCompanyDocumentReport = lazy(
  () => import('./docin/CompanyDocumentReport')
);
const DocoutCompanyDocumentReport = lazy(
  () => import('./docout/CompanyDocumentReport')
);
const UserReport = lazy(() => import('./UserReport'));

export const reportLink = {
  docin_category_report: '/report/docin-category',
  docout_category_report: '/report/docout-category',
  docin_document_rank: '/report/docin-document-rank',
  docout_document_rank: '/report/docout-document-rank',
  docin_company_document: '/report/docin-company-document',
  docout_company_document: '/report/docout-company-document',
  user: '/report/user',
};

export const ReportRoute = [
  { path: '/report/docin-category', element: <DocinCategoryReport /> },
  { path: '/report/docout-category', element: <DocoutCategoryReport /> },
  { path: '/report/docin-document-rank', element: <DocinDocumentRankReport /> },
  {
    path: '/report/docout-document-rank',
    element: <DocoutDocumentRankReport />,
  },
  {
    path: '/report/docin-company-document',
    element: <DocinCompanyDocumentReport />,
  },
  {
    path: '/report/docout-company-document',
    element: <DocoutCompanyDocumentReport />,
  },
  { path: '/report/user', element: <UserReport /> },
];

export const ReportNavigator: NavLink[] = [
  {
    label: 'report',
    name: 'report_menu',
    domain: 'report',
    item: [
      {
        label: 'category',
        name: 'category',
        domain: 'report',
        item: [
          {
            label: 'docin',
            link: reportLink.docin_category_report,
            name: reportLink.docin_category_report,
            domain: 'report',
          },
          {
            label: 'docout',
            link: reportLink.docout_category_report,
            name: reportLink.docout_category_report,
            domain: 'report',
          },
        ],
      },
      {
        label: 'document_rank',
        name: 'document_rank',
        domain: 'report',
        item: [
          {
            label: 'docin',
            link: reportLink.docin_document_rank,
            name: reportLink.docin_document_rank,
            domain: 'report',
          },
          {
            label: 'docout',
            link: reportLink.docout_document_rank,
            name: reportLink.docout_document_rank,
            domain: 'report',
          },
        ],
      },
      {
        label: 'company_document',
        name: 'company_document',
        domain: 'report',
        item: [
          {
            label: 'docin',
            link: reportLink.docin_company_document,
            name: reportLink.docin_company_document,
            domain: 'report',
          },
          {
            label: 'docout',
            link: reportLink.docout_company_document,
            name: reportLink.docout_company_document,
            domain: 'report',
          },
        ],
      },
      {
        label: 'user_report',
        name: 'user_report',
        link: reportLink.user,
        domain: 'report',
      },
    ],
  },
];
