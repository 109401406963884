import { TableForms } from '../category';
import {
  IPostAdvanceClearanceDocument,
  IPostAdvanceRequest,
  IPostExternalDocument,
  IPostPaymentVoucher,
  IPostPurchaseOrderDocout,
  IPostSignDocument,
} from '../docout';
import {
  IPatchAdvanceClearanceDocument,
  IPatchAdvanceRequest,
  IPatchExternalDocument,
  IPatchPaymentVoucher,
  IPatchPurchaseOrder,
  IUpdateSignDocument,
} from '../docout/type';
import * as types from './type';

export const getDocinByCategory = (
  category_id: string,
  table_form: TableForms,
  query_params?: types.DocinGetQueryParams
): types.IGetDocinByCategory => ({
  type: types.DocinActionTypes.GET_DOCIN_BY_CATEGORY,
  payload: {
    category_id: category_id,
    query_params: query_params,
    table_form: table_form,
  },
});

export const getDocinByCategorySuccessAction = (
  payload: types.IDocinCategory
): types.IGetDocinByCategorySuccessAction => ({
  type: types.DocinActionTypes.GET_DOCIN_BY_CATEGORY_SUCCESS,
  payload,
});

export const receiveDocinByDocumentDocinAction = (
  payload: types.IReceiveDocinByDocumentDocinAction['payload']
): types.IReceiveDocinByDocumentDocinAction => ({
  type: types.DocinActionTypes.RECEIVE_DOCIN_BY_DOCUMENT_DOCIN,
  payload,
});
export const patchDocinStatusAction = (
  payload: types.IPatchDocinStatusAction['payload']
): types.IPatchDocinStatusAction => ({
  type: types.DocinActionTypes.PATCH_DOCIN_STATUS,
  payload,
});

export const postSignDocinAction = (
  docin_id: string,
  category_id: string,
  table_form: TableForms,
  form: IPostSignDocument[],
): types.IPostSignDocinAction => ({
  type: types.DocinActionTypes.POST_SIGN_DOCIN,
  payload: {
    docin_id,
    category_id,
    table_form,
    form,
  },
});

export const deleteSignDocinAction = (
  payload: types.IDeleteSignDocinAction['payload']
): types.IDeleteSignDocinAction => ({
  type: types.DocinActionTypes.DELETE_SIGN_DOCIN,
  payload,
});

export const updateSignDocinAction = (
  docin_id: string,
  category_id: string,
  table_form: TableForms,
  form: IUpdateSignDocument[]
): types.IUpdateSignDocinAction => ({
  type: types.DocinActionTypes.PUT_SIGN_DOCIN,
  payload: {
    docin_id,
    category_id,
    table_form,
    form,
  },
});

export const createPDFAction = (record: any): types.ICreatePDFState => ({
  type: types.DocinActionTypes.CREATE_PDF,
  payload: record,
});

export const postPurchaseOrderDocinAction = (
  category_id: string,
  form: IPostPurchaseOrderDocout
): types.IPostPurchaseOrderDocinAction => ({
  type: types.DocinActionTypes.POST_PURCHASE_ORDER_DOCIN,
  payload: {
    category_id,
    form,
  },
});

export const postPaymentVoucherDocinAction = (
  category_id: string,
  form: IPostPaymentVoucher
) => ({
  type: types.DocinActionTypes.POST_PAYMENT_VOUCHER_DOCIN,
  payload: {
    category_id,
    form,
  },
});

export const postAdvanceRequestDocinAction = (
  category_id: string,
  form: IPostAdvanceRequest
) => ({
  type: types.DocinActionTypes.POST_ADVANCE_REQUEST_DOCIN,
  payload: {
    category_id,
    form,
  },
});

export const postExternalDocinAction = (
  category_id: string,
  form: IPostExternalDocument
) => ({
  type: types.DocinActionTypes.POST_EXTERNAL_DOCIN,
  payload: {
    category_id,
    form,
  },
});

export const postAdvanceClearanceDocinAction = (
  category_id: string,
  form: IPostAdvanceClearanceDocument
) => ({
  type: types.DocinActionTypes.POST_ADVANCE_CLEARANCE_DOCIN,
  payload: {
    category_id,
    form,
  },
});

export const getDocinByIdAction = (
  id: string,
  category_id: string
): types.IGetDocumentByIdAction => ({
  type: types.DocinActionTypes.GET_DOCIN_BY_ID,
  payload: {
    id,
    category_id,
  },
});

export const getDocinByIdSuccessAction = (
  category: types.DocinDetailCategory
): types.IGetDocumentByIdSuccessAction => ({
  type: types.DocinActionTypes.GET_DOCIN_BY_ID_SUCCESS,
  payload: category,
});

export const postDocinToDocoutAction = (
  payload: types.IPostDocinToDocoutAction['payload']
): types.IPostDocinToDocoutAction => ({
  type: types.DocinActionTypes.POST_DOCIN_TO_DOCOUT,
  payload,
});

export const patchAdvanceRequestDocinAction = (
  form: IPatchAdvanceRequest,
  category_id: string,
  id: string,
  docin_id: string
) => ({
  type: types.DocinActionTypes.PATCH_ADVANCE_REQUEST_DOCIN,
  payload: {
    form,
    category_id,
    id,
    docin_id,
  },
});

export const patchAdvanceClearanceDocinAction = (
  form: IPatchAdvanceClearanceDocument,
  category_id: string,
  id: string,
  docin_id: string
) => ({
  type: types.DocinActionTypes.PATCH_ADVANCE_CLEARANCE_DOCIN,
  payload: {
    form,
    category_id,
    id,
    docin_id,
  },
});

export const patchPurchaseOrderDocinAction = (
  form: IPatchPurchaseOrder,
  category_id: string,
  id: string,
  docin_id: string
) => ({
  type: types.DocinActionTypes.PATCH_PURCHASE_ORDER_DOCIN,
  payload: {
    form,
    category_id,
    id,
    docin_id,
  },
});

export const patchPaymentVoucherDocinAction = (
  form: IPatchPaymentVoucher,
  category_id: string,
  id: string,
  docin_id: string
) => ({
  type: types.DocinActionTypes.PATCH_PAYMENT_VOUCHER_DOCIN,
  payload: {
    form,
    category_id,
    id,
    docin_id,
  },
});

export const patchExternalDocinAction = (
  form: IPatchExternalDocument,
  category_id: string,
  id: string,
  docin_id: string
) => ({
  type: types.DocinActionTypes.PATCH_EXTERNAL_DOCIN,
  payload: {
    form,
    category_id,
    id,
    docin_id,
  },
});
