import * as types from './type';
import * as services from './../../service/Docin';
import * as actions from './action';
import { put, call, all, fork, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { subscribeErrorMessage, subscribeSuccessMessage } from '../saga';
import { channel } from 'redux-saga';
import { AppState } from '..';

function* handleDocinByCategory({
  payload,
}: types.IGetDocinByCategory): Generator {
  try {
    const { data } = (yield call(
      services.getDocinByCategory,
      payload.category_id,
      payload.query_params
    )) as AxiosResponse;
    const category = (yield select(
      (app) => app.docin.category
    )) as types.IDocinCategory;
    category[payload.table_form] = data;
    yield put(actions.getDocinByCategorySuccessAction({ ...category }));
  } catch (err) {}
}
function* handleReceiveDocinByDocumentDocinAction({
  payload,
}: types.IReceiveDocinByDocumentDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.receiveDocinBydocinId,
      payload.document_id,
      payload.docin_id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(
        actions.getDocinByCategory(
          payload.document_category,
          payload.table_forms
        )
      );
      yield put(chan, { name: 'RECEIVE_DOCUMENT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePatchDocinStatusAction({
  payload,
}: types.IPatchDocinStatusAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocinStatus,
      payload.docin_id,
      payload.document_category
    )) as AxiosResponse;
    if (status === 200) {
      yield put(
        actions.getDocinByCategory(
          payload.document_category,
          payload.table_forms
        )
      );
      yield put(chan, { name: 'ADD_ADVANCE_CLEARANCE_DOCIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostSignDocin({ payload }: types.IPostSignDocinAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postSignDocin,
      payload.docin_id,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      const category = (yield select(
        (app) => app.docin.category
      )) as types.IDocinCategory;
      const idx = yield category[payload.table_form].findIndex(
        (item) => item?.doc_in.id === payload.docin_id
      ) as number;
      category[payload.table_form][idx].document_status = 'signing';
      yield put(actions.getDocinByCategorySuccessAction({ ...category }));

      yield put(chan, { name: 'ADD_SIGN_DOCIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handleUpdateSignDocin({ payload }: types.IUpdateSignDocinAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.updateSignDocin,
      payload.docin_id,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 200) {
      const category = (yield select(
        (app: AppState) => app.docin.category
      )) as types.IDocinCategory;
      const idx = category[payload.table_form].findIndex(
        (item: any) => item.doc_in.id === payload.docin_id
      );
      category[payload.table_form][idx].document_status = 'signing';
      yield put(actions.getDocinByCategorySuccessAction({ ...category }));
      yield put(chan, { name: 'UPDATE_SIGN_DOCIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handleDeleteSignDocin({ payload }: types.IDeleteSignDocinAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.deleteSignDocin,
      payload.docin_id,
      payload.assigned_id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'DELETE_SIGN_DOCIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlePostPurchaseOrderDocin({
  payload,
}: types.IPostPurchaseOrderDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocin,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_PURCHASE_DOCIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostPaymentVoucherDocin({
  payload,
}: types.IPostPaymentVoucherDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocin,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_PAYMENT_DOCIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostAdvanceRequestDocin({
  payload,
}: types.IPostAdvanceRequestDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocin,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_ADVANCE_REQUEST_DOCIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostExternalDocument({
  payload,
}: types.IPostExternalDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocin,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_EXTERNAL_DOCIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostAdvanceClearanceDocument({
  payload,
}: types.IPostAdvanceClearanceDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocin,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_ADVANCE_CLEARANCE_DOCIN', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handleGetDocinById(action: types.IGetDocumentByIdAction) {
  try {
    const { status, data } = (yield call(
      services.getDocinById,
      action.payload.id,
      action.payload.category_id
    )) as AxiosResponse;
    if (status === 200) {
      const category = (yield select(
        (app: AppState) => app.docin.category_detail
      )) as types.DocinDetailCategory;
      const newCategory = {
        ...category,
        [data.document_category.table_forms]: data,
      };
      yield put(actions.getDocinByIdSuccessAction(newCategory));
    }
  } catch (error) {}
}

function* handlePostDocinToDocout({ payload }: types.IPostDocinToDocoutAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocinToDocout,
      payload.docin_id
    )) as AxiosResponse;
    if (status === 201) {
      const category = (yield select(
        (app: AppState) => app.docin.category
      )) as types.IDocinCategory;
      const idx = yield category[payload.table_forms].findIndex(
        (item) => item.doc_in.id === payload.docin_id
      ) as number;
      category[payload.table_forms][idx].document_status = 'completed';
      category[payload.table_forms][idx].doc_in.docin_status = 'completed';
      category[payload.table_forms][idx].doc_in.doc_out = true;
      yield put(actions.getDocinByCategorySuccessAction({ ...category }));
      yield put(chan, { name: 'ADD_DOCIN_TO_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    const category = (yield select(
      (app: AppState) => app.docin.category
    )) as types.IDocinCategory;
    yield put(actions.getDocinByCategorySuccessAction({ ...category }));
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePatchAdvanceRequestDocin({
  payload,
}: types.IPatchAdvanceRequestDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocin,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_ADVANCE_REQUEST_DOCIN', status: true });
      yield put(
        actions.getDocinByIdAction(payload.docin_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePatchAdvanceClearanceDocin({
  payload,
}: types.IPatchAdvanceClearanceDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocin,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_ADVANCE_CLEARANCE_DOCIN', status: true });
      yield put(
        actions.getDocinByIdAction(payload.docin_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlePatchPurchaseOrderDocin({
  payload,
}: types.IPatchPurchaseOrderDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocin,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_PURCHASE_ORDER_DOCIN', status: true });
      yield put(
        actions.getDocinByIdAction(payload.docin_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePatchPaymentVoucherDocin({
  payload,
}: types.IPatchPaymentVoucherDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocin,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_PAYMENT_VOUCHER_DOCIN', status: true });
      yield put(
        actions.getDocinByIdAction(payload.docin_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePatchExternalDocin({
  payload,
}: types.IPatchExternalDocinAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocin,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_EXTERNAL_DOCIN', status: true });
      yield put(
        actions.getDocinByIdAction(payload.docin_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handleCreatePDF({ payload }: types.ICreatePDFState): Generator {}

function* watchHandlerDocinByCategory(): Generator {
  yield takeLatest(
    types.DocinActionTypes.GET_DOCIN_BY_CATEGORY,
    handleDocinByCategory
  );
}
function* watchHandlerReceiveDocinByDocumentDocinAction(): Generator {
  yield takeLatest(
    types.DocinActionTypes.RECEIVE_DOCIN_BY_DOCUMENT_DOCIN,
    handleReceiveDocinByDocumentDocinAction
  );
}
function* watchHandlerPostSignDocin(): Generator {
  yield takeLatest(types.DocinActionTypes.POST_SIGN_DOCIN, handlePostSignDocin);
}
function* watchHandlerUpdateSignDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.PUT_SIGN_DOCIN,
    handleUpdateSignDocin
  );
}
function* watchHandlerDeleteSignDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.DELETE_SIGN_DOCIN,
    handleDeleteSignDocin
  );
}
function* watchHandlerPatchDocinStatusAction(): Generator {
  yield takeLatest(
    types.DocinActionTypes.PATCH_DOCIN_STATUS,
    handlePatchDocinStatusAction
  );
}

function* watchHandlerPostPurchaseOrderDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.POST_PURCHASE_ORDER_DOCIN,
    handlePostPurchaseOrderDocin
  );
}
function* watchHandlerPostPaymentVoucherDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.POST_PAYMENT_VOUCHER_DOCIN,
    handlePostPaymentVoucherDocin
  );
}
function* watchHandlerPostAdvanceRequestDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.POST_ADVANCE_REQUEST_DOCIN,
    handlePostAdvanceRequestDocin
  );
}
function* watchHandlerPostExternalDocument(): Generator {
  yield takeLatest(
    types.DocinActionTypes.POST_EXTERNAL_DOCIN,
    handlePostExternalDocument
  );
}

function* watchHandlerPostAdvanceClearanceDocument(): Generator {
  yield takeLatest(
    types.DocinActionTypes.POST_ADVANCE_CLEARANCE_DOCIN,
    handlePostAdvanceClearanceDocument
  );
}
function* watchHandlerGetDocinByID(): Generator {
  yield takeLatest(types.DocinActionTypes.GET_DOCIN_BY_ID, handleGetDocinById);
}
function* watchHandlerPostDocinToDocout(): Generator {
  yield takeLatest(
    types.DocinActionTypes.POST_DOCIN_TO_DOCOUT,
    handlePostDocinToDocout
  );
}
function* watchHandlerPatchAdvanceRequestDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.PATCH_ADVANCE_REQUEST_DOCIN,
    handlePatchAdvanceRequestDocin
  );
}
function* watchHandlerPatchAdvanceClearanceDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.PATCH_ADVANCE_CLEARANCE_DOCIN,
    handlePatchAdvanceClearanceDocin
  );
}
function* watchHandlerPatchPurchaseOrderDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.PATCH_PURCHASE_ORDER_DOCIN,
    handlePatchPurchaseOrderDocin
  );
}
function* watchHandlerPatchPaymentVoucherDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.PATCH_PAYMENT_VOUCHER_DOCIN,
    handlePatchPaymentVoucherDocin
  );
}
function* watchHandlerPatchExternalDocin(): Generator {
  yield takeLatest(
    types.DocinActionTypes.PATCH_EXTERNAL_DOCIN,
    handlePatchExternalDocin
  );
}

function* watchHandlerPatchCreatePDF(): Generator {
  yield takeLatest(types.DocinActionTypes.CREATE_PDF, handleCreatePDF);
}

export function* docinSaga() {
  yield all([
    fork(watchHandlerPatchCreatePDF),
    fork(watchHandlerDocinByCategory),
    fork(watchHandlerReceiveDocinByDocumentDocinAction),
    fork(watchHandlerPostSignDocin),
    fork(watchHandlerUpdateSignDocin),
    fork(watchHandlerDeleteSignDocin),
    fork(watchHandlerPatchDocinStatusAction),
    fork(watchHandlerPostPurchaseOrderDocin),
    fork(watchHandlerPostPaymentVoucherDocin),
    fork(watchHandlerPostAdvanceRequestDocin),
    fork(watchHandlerPostExternalDocument),
    fork(watchHandlerPostAdvanceClearanceDocument),
    fork(watchHandlerGetDocinByID),
    fork(watchHandlerPostDocinToDocout),
    fork(watchHandlerPatchAdvanceRequestDocin),
    fork(watchHandlerPatchAdvanceClearanceDocin),
    fork(watchHandlerPatchPurchaseOrderDocin),
    fork(watchHandlerPatchPaymentVoucherDocin),
    fork(watchHandlerPatchExternalDocin),
  ]);
}
