import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as services from '../../service/Dashboard';
import { handleDocumentCategory } from './action';
import * as types from './type';

function* handlerFetchDocumentCategory() {
  try {
    const res = (yield call(services.getDocumentCategory)) as AxiosResponse<
      types.IDocCateStatusCount[]
    >;
    if (res.status === 200) {
      yield put(
        handleDocumentCategory({
          type: 'RECEIVE_DOCUMENT_CATEGORY_STATUS_COUNT',
          payload: res.data,
        })
      );
    }
  } catch (e) {}
}

function* handlerFetchDocumentMountsCount() {
  try {
    const res = (yield call(services.getMonthsCount)) as AxiosResponse<
      types.IDocMonthCount[]
    >;
    if (res.status === 200) {
      yield put(
        handleDocumentCategory({
          type: 'RECEIVE_DOCUMENT_MONTHS_COUNT',
          payload: res.data,
        })
      );
    }
  } catch (e) {}
}

function* watchHandlerFetchDocumentCategory() {
  yield takeLatest(
    'FETCH_DOCUMENT_CATEGORY_STATUS_COUNT',
    handlerFetchDocumentCategory
  );
}

function* watchHandlerFetchDocumentAllYears() {
  yield takeLatest(
    'FETCH_DOCUMENT_MONTHS_COUNT',
    handlerFetchDocumentMountsCount
  );
}

export function* DocumentCategorySaga() {
  yield all([fork(watchHandlerFetchDocumentCategory)]);
  yield all([fork(watchHandlerFetchDocumentAllYears)]);
}
