import * as types from './type';
import * as services from './../../service/Docout';
import * as actions from './action';
import { put, takeLatest, call, all, fork, select } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { subscribeErrorMessage, subscribeSuccessMessage } from '../saga';
import { AppState } from '..';

function* handleDocoutByCategory({
  payload,
}: types.IGetDocoutByCategory): Generator {
  try {
    const { data } = (yield call(
      services.getDocoutByCategory,
      payload.category_id,
      payload.query_params
    )) as AxiosResponse;
    const category = (yield select(
      (app: AppState) => app.docout.category
    )) as types.IDocoutCategory;
    category[payload.table_form] = data;
    yield put(actions.getDocoutByCategorySuccessAction({ ...category }));
  } catch (err) {}
}

function* handlePostPurchaseOrderDocout({
  payload,
}: types.IPostPurchaseOrderDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocout,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_PURCHASE_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostPaymentVoucherDocout({
  payload,
}: types.IPostPaymentVoucherDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocout,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_PAYMENT_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostAdvanceRequestDocout({
  payload,
}: types.IPostAdvanceRequestDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocout,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_ADVANCE_REQUEST_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostExternalDocument({
  payload,
}: types.IPostExternalDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocout,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_EXTERNAL_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePostAdvanceClearanceDocument({
  payload,
}: types.IPostAdvanceClearanceDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postDocout,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      yield put(chan, { name: 'ADD_ADVANCE_CLEARANCE_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlePostSignDocout({ payload }: types.IPostSignDocoutAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.postSignDocout,
      payload.docout_id,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 201) {
      const category = (yield select(
        (app: AppState) => app.docout.category
      )) as types.IDocoutCategory;
      const idx = category[payload.table_form].findIndex(
        (item: any) => item.doc_out.id === payload.docout_id
      );
      category[payload.table_form][idx].document_status = 'signing';
      yield put(actions.getDocoutByCategorySuccessAction({ ...category }));
      yield put(chan, { name: 'ADD_SIGN_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handleUpdateSignDocout({ payload }: types.IUpdateSignDocoutAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.updateSignDocout,
      payload.docout_id,
      payload.category_id,
      payload.form
    )) as AxiosResponse;
    if (status === 200) {
      const category = (yield select(
        (app: AppState) => app.docout.category
      )) as types.IDocoutCategory;
      const idx = category[payload.table_form].findIndex(
        (item: any) => item.doc_out.id === payload.docout_id
      );
      category[payload.table_form][idx].document_status = 'signing';
      yield put(actions.getDocoutByCategorySuccessAction({ ...category }));
      yield put(chan, { name: 'UPDATE_SIGN_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handleDeleteSignDocout({ payload }: types.IDeleteSignDocoutAction) {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.deleteSignDocout,
      payload.docout_id,
      payload.assigned_id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'DELETE_SIGN_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handleSendDocument({ payload }: types.ISendDocumentAction) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.SendingDocument,
      payload.document_id,
      payload.company_id
    )) as AxiosResponse;
    if (res.status === 201) {
      const category = (yield select(
        (app: AppState) => app.docout.category
      )) as types.IDocoutCategory;
      const idx = category[payload.table_forms].findIndex(
        (item: any) => item.id === payload.document_id
      );
      category[payload.table_forms][idx].document_status = 'sending';
      yield put(actions.getDocoutByCategorySuccessAction({ ...category }));
      yield put(chan, { name: 'SEND_DOCUMENT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handleGetDocoutById(action: types.IGetDocumentByIdAction) {
  try {
    const { status, data } = (yield call(
      services.getDocoutById,
      action.payload.id,
      action.payload.category_id
    )) as AxiosResponse;
    if (status === 200) {
      const category_detail = (yield select(
        (app: AppState) => app.docout.category_detail
      )) as types.DocoutDetailCategory;
      const newCategory = {
        ...category_detail,
        [data.document_category.table_forms]: data,
      };
      yield put(actions.getDocoutByIdSuccessAction(newCategory));
    }
  } catch (error) {}
}
function* handlePatchAdvanceRequestDocout({
  payload,
}: types.IPatchAdvanceRequestDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocout,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_ADVANCE_REQUEST_DOCOUT', status: true });
      yield put(
        actions.getDocoutByIdAction(payload.docout_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePatchAdvanceClearanceDocout({
  payload,
}: types.IPatchAdvanceClearanceDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocout,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_ADVANCE_CLEARANCE_DOCOUT', status: true });
      yield put(
        actions.getDocoutByIdAction(payload.docout_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePatchPurchaseOrderDocout({
  payload,
}: types.IPatchPurchaseOrderDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocout,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_PURCHASE_ORDER_DOCOUT', status: true });
      yield put(
        actions.getDocoutByIdAction(payload.docout_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePatchPaymentVoucherDocout({
  payload,
}: types.IPatchPaymentVoucherDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocout,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_PAYMENT_VOUCHER_DOCOUT', status: true });
      yield put(
        actions.getDocoutByIdAction(payload.docout_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlePatchExternalDocout({
  payload,
}: types.IPatchExternalDocoutAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocout,
      payload.form,
      payload.category_id,
      payload.id
    )) as AxiosResponse;
    if (status === 200) {
      yield put(chan, { name: 'EDIT_EXTERNAL_DOCOUT', status: true });
      yield put(
        actions.getDocoutByIdAction(payload.docout_id, payload.category_id)
      );
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlePatchDocoutStatusAction({
  payload,
}: types.IPatchDocoutStatusAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const { status } = (yield call(
      services.patchDocoutStatus,
      payload.docout_id,
      payload.document_category
    )) as AxiosResponse;
    if (status === 200) {
      yield put(
        actions.getDocoutByCategory(
          payload.document_category,
          payload.table_forms
        )
      );
      yield put(chan, { name: 'ADD_ADVANCE_CLEARANCE_DOCOUT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* watchHandlerDocoutByCategory(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.GET_DOCOUT_BY_CATEGORY,
    handleDocoutByCategory
  );
}
function* watchHandlerPostPurchaseOrderDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.POST_PURCHASE_ORDER_DOCOUT,
    handlePostPurchaseOrderDocout
  );
}
function* watchHandlerPostPaymentVoucherDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.POST_PAYMENT_VOUCHER_DOCOUT,
    handlePostPaymentVoucherDocout
  );
}
function* watchHandlerPostAdvanceRequestDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.POST_ADVANCE_REQUEST_DOCOUT,
    handlePostAdvanceRequestDocout
  );
}
function* watchHandlerPostExternalDocument(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.POST_EXTERNAL_DOCOUT,
    handlePostExternalDocument
  );
}

function* watchHandlerPostAdvanceClearanceDocument(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.POST_ADVANCE_CLEARANCE_DOCOUT,
    handlePostAdvanceClearanceDocument
  );
}
function* watchHandlerPostSignDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.POST_SIGN_DOCOUT,
    handlePostSignDocout
  );
}
function* watchHandlerUpdateSignDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.PUT_SIGN_DOCOUT,
    handleUpdateSignDocout
  );
}
function* watchHandlerDeleteSignDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.DELETE_SIGN_DOCOUT,
    handleDeleteSignDocout
  );
}
function* watchHandlerSendDocument(): Generator {
  yield takeLatest(types.DocoutActionTypes.SEND_DOCUMENT, handleSendDocument);
}
function* watchHandlerGetDocoutByID(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.GET_DOCOUT_BY_ID,
    handleGetDocoutById
  );
}
function* watchHandlerPatchAdvanceRequestDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.PATCH_ADVANCE_REQUEST_DOCOUT,
    handlePatchAdvanceRequestDocout
  );
}
function* watchHandlerPatchAdvanceClearanceDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.PATCH_ADVANCE_CLEARANCE_DOCOUT,
    handlePatchAdvanceClearanceDocout
  );
}
function* watchHandlerPatchPurchaseOrderDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.PATCH_PURCHASE_ORDER_DOCOUT,
    handlePatchPurchaseOrderDocout
  );
}
function* watchHandlerPatchPaymentVoucherDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.PATCH_PAYMENT_VOUCHER_DOCOUT,
    handlePatchPaymentVoucherDocout
  );
}
function* watchHandlerPatchExternalDocout(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.PATCH_EXTERNAL_DOCOUT,
    handlePatchExternalDocout
  );
}
function* watchHandlerPatchDocoutStatusAction(): Generator {
  yield takeLatest(
    types.DocoutActionTypes.PATCH_DOCOUT_STATUS,
    handlePatchDocoutStatusAction
  );
}

export function* docoutSaga() {
  yield all([
    fork(watchHandlerDocoutByCategory),
    fork(watchHandlerPostPurchaseOrderDocout),
    fork(watchHandlerPostPaymentVoucherDocout),
    fork(watchHandlerPostAdvanceRequestDocout),
    fork(watchHandlerPostExternalDocument),
    fork(watchHandlerPostAdvanceClearanceDocument),
    fork(watchHandlerPostSignDocout),
    fork(watchHandlerUpdateSignDocout),
    fork(watchHandlerDeleteSignDocout),
    fork(watchHandlerSendDocument),
    fork(watchHandlerGetDocoutByID),
    fork(watchHandlerPatchAdvanceRequestDocout),
    fork(watchHandlerPatchAdvanceClearanceDocout),
    fork(watchHandlerPatchPurchaseOrderDocout),
    fork(watchHandlerPatchPaymentVoucherDocout),
    fork(watchHandlerPatchExternalDocout),
    fork(watchHandlerPatchDocoutStatusAction),
  ]);
}
