import { axiosInstant } from '../axiosInstant';
import {
  IPostPurchaseOrderDocout,
  IPostPaymentVoucher,
  IPostAdvanceRequest,
  IPostExternalDocument,
  IPostAdvanceClearanceDocument,
  IPostSignDocument,
} from '../store/docout';
import { DocoutGetQueryParams, IPatchAdvanceRequest } from "../store/docout/type";

export const getDocoutByCategory = (category_id: string, query_params?: DocoutGetQueryParams) =>
  axiosInstant
    .get(`/documents/docout/${category_id}/category`, {
      params: query_params
    })
    .then((res) => res);

export const postDocout = (
  category_id: string,
  form:
    | IPostPurchaseOrderDocout
    | IPostPaymentVoucher
    | IPostAdvanceRequest
    | IPostExternalDocument
    | IPostAdvanceClearanceDocument
) => axiosInstant.post(`/documents/docout/${category_id}/category`, form);

export const postSignDocout = (docout_id: string, category_id: string, form: IPostSignDocument[]) =>
  axiosInstant.post(`/assigns/${docout_id}/docout/${category_id}`, form).then((res) => res);

export const updateSignDocout = (
  docout_id: string,
  category_id: string,
  form: IPostSignDocument[]
) =>
  axiosInstant
    .put(`/assigns/${docout_id}/docout/${category_id}`, form)
    .then((res) => res);


export const deleteSignDocout = (docout_id: string, assigned_id: string) =>
  axiosInstant
    .delete(`/assigns/${docout_id}/docout/${assigned_id}`)
    .then((res) => res);

export const SendingDocument = (document_id: string, company_id) =>
  axiosInstant
    .post(`/documents/send/${document_id}/document`, { company_id })
    .then((res) => res);

export const getDocoutById = (id: string, category_id: string) =>
  axiosInstant
    .get(`/documents/docout/${id}/detail/${category_id}/category`)
    .then((res) => res);

export const patchDocout = (
  form:
    | IPostPurchaseOrderDocout
    | IPostPaymentVoucher
    | IPatchAdvanceRequest
    | IPostExternalDocument
    | IPostAdvanceClearanceDocument,
  category_id: string,
  id: string
) => axiosInstant.patch(`/documents/${id}/${category_id}/category`, form);

export const patchDocoutStatus = (docout_id: string, category: string) =>
  axiosInstant
    .patch(`/documents/docout/${docout_id}/completed`)
    .then((res) => res);