import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as types from './type';
import * as actions from './action';
import * as services from './../../service/Comment';
import { AxiosResponse } from 'axios';
import { channel } from 'redux-saga';
import { subscribeErrorMessage, subscribeSuccessMessage } from '../saga';
function* handlePostComment({
  payload,
}: types.IPostCommentTextAction): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.postCommentText,
      payload
    )) as AxiosResponse<types.Comment>;
    if (res.status === 201) {
      yield put(actions.getCommentSuccessAction(res.data));
      yield put(chan, { name: 'ADD_COMMENT', status: true });
      yield fork(subscribeSuccessMessage, chan);
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* watchHandlerPostCommentText(): Generator {
  yield takeLatest(
    types.CommentActionsType.POST_COMMENT_TEXT,
    handlePostComment
  );
}

export function* commentSaga(): Generator {
  yield all([fork(watchHandlerPostCommentText)]);
}
