import * as types from './type';
export const initialDocumentState: types.IDocumentState = {
  currency: {
    currencies: [],
    loading: false,
  },
  document_rank: {
    document_ranks: [],
    loading: false,
  },
};

export const documentReducer = (
  state = initialDocumentState,
  action: types.DocumentActions
): types.IDocumentState => {
  switch (action.type) {
    case types.DocumentActionTypes.GET_CURRENCIES:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: true,
        },
      };
    case types.DocumentActionTypes.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currency: {
          ...state.currency,
          currencies: action.payload,
          loading: false,
        },
      };
    case types.DocumentActionTypes.GET_DOCUMENT_RANKS:
      return {
        ...state,
        document_rank: {
          ...state.document_rank,
          loading: true,
        },
      };
    case types.DocumentActionTypes.GET_DOCUMENT_RANKS_SUCCESS:
      return {
        ...state,
        document_rank: {
          ...state.document_rank,
          document_ranks: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};
