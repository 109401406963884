import OneSignal from 'react-onesignal';
import LocalStorageService from "../../LocalStorageService";
import { ExternalUserId } from "../../store/onesignal";

const app_id = process.env.REACT_APP_ONE_SIGNAL_APP_ID;

type RunOneSignal = (data: ExternalUserId) => Promise<void>;

export const runOneSignal: RunOneSignal = async (data) => {

  await OneSignal.init({
    appId: app_id ? app_id : "",
    allowLocalhostAsSecureOrigin: true,
    autoResubscribe: true,
  });
  OneSignal.showSlidedownPrompt();

  OneSignal.on('subscriptionChange', function (isSubscribed) {
    console.warn("The user's subscription state is now:", isSubscribed);
  });

  if (data) {
    await OneSignal.setExternalUserId(data.external_id, data.external_id_hash);
  }

  await OneSignal.getTags((receivedTags) => {
    console.log(receivedTags);

    if (LocalStorageService.getAccessToken() && receivedTags.login !== "1") {
      OneSignal.sendTag('login', 1)
    }
  })
}

export const oneSignal: RunOneSignal = async (data) => {

}
