import { axiosInstant } from '../axiosInstant';
import { IPosition } from '../store/position';
import { SavePermission } from '../store/position/type';

export const fetchPosition = () =>
  axiosInstant.get('/positions').then((res) => res);

export const savePosition = (data: IPosition) =>
  axiosInstant
    .post('/positions', {
      name: data.name,
      company_id: data.company.id,
    })
    .then((res) => res);

export const updatePosition = (data: IPosition) =>
  axiosInstant
    .put(`/positions/${data.id}`, {
      name: data.name,
      company_id: data.company.id,
    })
    .then((res) => res);
export const updateStatusPosition = (data: IPosition) =>
  axiosInstant
    .patch(
      `/positions/${data.id}/status/${
        data.status === 'ACTIVE' ? 'inactive' : 'active'
      }`,
      data
    )
    .then((res) => res);

export const fetchPositionByCompany = (id: string) =>
  axiosInstant.get(`/companies/${id}/positions`).then((res) => res);

export const fetchPermissionByPosition = (id: string) =>
  axiosInstant.get(`/positions/${id}/permission`).then((res) => res);

export const savePermissionByPosition = (data: SavePermission) =>
  axiosInstant
    .post(`/positions/${data.PositionID}/permission`, {
      docin: data.docin,
      docout: data.docout,
      archive: data.archive,
      report: data.report,
      dashboard: data.dashboard,
      inbox: data.inbox,
    })
    .then((res) => res);
