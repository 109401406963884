import { AxiosError, AxiosResponse } from 'axios';
import { channel } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { AppState } from '..';
import { subscribeErrorMessage, subscribeSuccessMessage } from '../saga';
import * as services from '../../service/Position';
import { handlePosition } from './action';
import * as types from './type';
import { history } from "../../config/configureStore";
import { Logout } from "../../Utils";
function* handlerFetch() {
  try {
    const res = (yield call(services.fetchPosition)) as AxiosResponse<
      types.IPosition[]
    >;
    if (res.status === 200) {
      yield put(
        handlePosition({ type: 'RECEIVE_IPOSITION', payload: res.data })
      );
    }
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 403) {
      history.push('/unauthorized')
    } else if (err.response?.status === 401) {
      Logout()
    }
  }
}

function* handlerSave({ payload }: types.Action<'SAVE_POSITION'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.savePosition,
      payload
    )) as AxiosResponse<types.IPosition>;
    if (res.status === 201) {
      yield put(chan, { name: 'ADD_POSITION', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(handlePosition({ type: 'FETCH_POSITION', payload: payload }));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlerUpdate({ payload }: types.Action<'UPDATE_POSITION'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updatePosition,
      payload
    )) as AxiosResponse<types.IPosition>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'EDIT_POSITION', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(handlePosition({ type: 'FETCH_POSITION', payload: payload }));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlerUpdateStatus({
  payload,
}: types.Action<'UPDATE_STATUS_POSITION'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateStatusPosition,
      payload
    )) as AxiosResponse<types.IPosition>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'EDIT_STATUS_POSITION', status: true });
      yield fork(subscribeSuccessMessage, chan);
      const positions = (yield select(
        (app: AppState) => app.position.positions
      )) as types.IPosition[];
      const idx = yield positions.findIndex((i) => i.id === payload.id);
      positions[idx] = {
        ...positions[idx],
        status: payload.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      };
      yield put(
        handlePosition({
          type: 'RECEIVE_IPOSITION',
          payload: [...positions],
        })
      );
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}
function* handlerFetchByCompany({
  payload,
}: types.Action<'FETCH_POSITION_BY_COMPANY'>) {
  try {
    const res = (yield call(
      services.fetchPositionByCompany,
      payload
    )) as AxiosResponse<types.IPosition[]>;
    if (res.status === 200) {
      yield put(
        handlePosition({ type: 'RECEIVE_IPOSITION', payload: res.data })
      );
    }
  } catch (e) { }
}

function* handlerFetchPermissionByPosition({
  payload,
}: types.Action<'FETCH_PERMISSION_BY_POSITION'>) {
  try {
    const res = (yield call(
      services.fetchPermissionByPosition,
      payload
    )) as AxiosResponse<types.IPosition[]>;
    if (res.status === 200) {
      yield put(handlePosition({ type: 'RECEIVE_PERMISSION_BY_POSITION', payload: res.data }))
    }
  } catch (e) { }
}

function* handlerSavePermissionByPosition({ payload }: types.Action<'SAVE_PERMISSION_BY_POSITION'>): Generator {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(services.savePermissionByPosition, payload)) as AxiosResponse<types.SavePermission>

    if (res.data.PositionID && res.status === 200) {
      yield put(chan, { name: 'UPDATE_PERMISSION_BY_POSITION', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(
        handlePosition({
          type: 'FETCH_PERMISSION_BY_POSITION',
          payload: res.data.PositionID,
        })
      );
    }
  } catch (err) {

  }
}

function* watchHandlerFetch() {
  yield takeLatest('FETCH_POSITION', handlerFetch);
}
function* watchHandlerSave() {
  yield takeLatest('SAVE_POSITION', handlerSave);
}
function* watchHandlerUpdate() {
  yield takeLatest('UPDATE_POSITION', handlerUpdate);
}
function* watchhandlerUpdateStatus() {
  yield takeLatest('UPDATE_STATUS_POSITION', handlerUpdateStatus);
}
function* watchHandlerFetchByCompany() {
  yield takeLatest('FETCH_POSITION_BY_COMPANY', handlerFetchByCompany);
}
function* watchHandlerFetchPermissionByPosition() {
  yield takeLatest('FETCH_PERMISSION_BY_POSITION', handlerFetchPermissionByPosition);
}
function* watchHandlerSavePermissionByPosition() {
  yield takeLatest('SAVE_PERMISSION_BY_POSITION', handlerSavePermissionByPosition);
}
export function* positionSaga() {
  yield all([
    fork(watchHandlerFetch),
    fork(watchHandlerSave),
    fork(watchHandlerUpdate),
    fork(watchhandlerUpdateStatus),
    fork(watchHandlerFetchByCompany),
    fork(watchHandlerFetchPermissionByPosition),
    fork(watchHandlerSavePermissionByPosition),
  ]);
}
