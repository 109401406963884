import React from 'react';
import { Link } from 'react-router-dom';
import { AuthPermission } from '../../store/auth/type';
import { NavLink } from './../../config/navigation';
import { TFunction } from 'react-i18next';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export const NavbarMenu = (
  nav: NavLink,
  permission: AuthPermission | undefined,
  t: TFunction<'navbar', undefined>
): ItemType => {
  const showMenu = (menu: NavLink): any => {
    const { name, link, label, item, domain } = menu;
    if (permission) {
      if (domain in permission || '*' in permission) {
    if (!item && link) {
      return {
        key: name,
        icon: <Link to={link}>{t(label)}</Link>,
      };
    } else {
      return {
        key: name,
        icon: t(label),
        children: item?.map((i) => {
          return (
            NavbarMenu(i, permission, t)
          );
        }),
      };
    }
  };
    }
  };
  return showMenu(nav);
};
