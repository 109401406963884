import { Reducer } from 'redux';
import { Action, PositionState, IPosition, Permission } from './type';

const initialState: PositionState = {
  positions: [],
  permissions: [],
  loading: false,
};
export const reducer: Reducer<PositionState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'RECEIVE_IPOSITION':
      return {
        ...state,
        positions: action.payload as IPosition[],
        loading: false,
      };
    case 'FETCH_POSITION':
      return {
        ...state,
        loading: true,
      };
    case 'UPDATE_STATUS_POSITION':
      return { ...state, loading: true };
    case 'FETCH_POSITION_BY_COMPANY':
      return { ...state, loading: true };
    case 'FETCH_PERMISSION_BY_POSITION':
      return { ...state, loading: true };
    case 'RECEIVE_PERMISSION_BY_POSITION':
      return {
        ...state,
        permissions: action.payload as Permission[],
        loading: false
      }
    default:
      return state;
  }
};
