import useRecordingsList from './use-recordings-list';
import { RecordingsListProps } from '../../../../../store/record/type';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { useTranslation } from 'react-i18next';
export default function RecordingsList({ audio, blob }: RecordingsListProps) {
  const { recordings, deleteAudio } = useRecordingsList(audio, blob);
  const { t } = useTranslation('button');
  return (
    <div className="recordings-container">
      {recordings.length > 0 ? (
        <>
          <div className="recordings-list">
            <List
              dataSource={recordings}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta title={<audio controls src={item.audio} />} />
                  <Button
                    type="ghost"
                    className="delete-button"
                    title="Delete this audio"
                    onClick={() => deleteAudio(item.key)}>
                    <DeleteOutlined />
                  </Button>
                </List.Item>
              )}
            />
          </div>
        </>
      ) : (
        <div className="no-records">
          <ExclamationCircleOutlined />
          &nbsp;
          <span>{t('no_recordings')}</span>
        </div>
      )}
    </div>
  );
}
