import { ArchiveNavigator } from '../components/archive/archiveRoute';
import { docinNavigator } from '../components/docin/DocinRoute';
import { docoutNavigator } from '../components/docout/DocoutRoute';
import { ReportNavigator } from '../components/report/ReportRoute';
import { SettingNavigator } from '../components/setting/SettingRoute';
import { AuthPermission } from "../store/auth/type";

export interface NavLink {
  name: string;
  label: string;
  link?: string;
  disable?: boolean;
  item?: NavLink[];
  domain: keyof AuthPermission
}

export const navigator: NavLink[] = [
  { label: 'dashboard', link: '/', name: '/', domain: 'dashboard' },
  { label: 'inbox', link: 'inbox', name: '/inbox', domain: 'inbox' },
  ...docinNavigator,
  ...docoutNavigator,
  ...ArchiveNavigator,
  ...ReportNavigator,
  ...SettingNavigator,
];
