import { axiosInstant } from '../axiosInstant';

export const getDocinCategoryReport = () =>
  axiosInstant.get('/report/document_status/document_categories/doc_in').then((res) => res);

export const getDocoutCategoryReport = () =>
  axiosInstant.get('/report/document_status/document_categories/doc_out').then((res) => res);

export const getDocinDocumentRankReport = () =>
  axiosInstant.get('/report/document_status/document_rank/doc_in').then((res) => res);

export const getDocoutDocumentRankReport = () =>
  axiosInstant.get('/report/document_status/document_rank/doc_out').then((res) => res);

export const getDocinCompanyReport = () =>
  axiosInstant.get('/report/document_status/companies/doc_in').then((res) => res);

export const getDocoutCompanyReport = () =>
  axiosInstant.get('/report/document_status/companies/doc_out').then((res) => res);

export const getUserReport = () =>
  axiosInstant.get('/users').then((res) => res);
