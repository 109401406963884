import { Reducer } from 'redux';
import * as types from './type';
export const categoryInitialState: types.ICategoryState = {
  categories: [],
  loading: false,
};

export const categoryReducer: Reducer<
  types.ICategoryState,
  types.CategoryActions
> = (state = categoryInitialState, action) => {
  switch (action.type) {
    case types.CategoryActionTypes.GET_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case types.CategoryActionTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
