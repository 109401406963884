import { Button, Form, Input, Segmented } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FileTextOutlined,
  FileImageOutlined,
  AudioOutlined,
} from '@ant-design/icons';
import { UploadFile } from './upload/UploadFile';
import { CommentType } from './../../../store/comment';
import { Record } from './record/Record';
const { TextArea } = Input;
interface Props {
  submit: boolean;
  handleFinish: (values: any) => void;
  segmentValue: CommentType;
  setSegmentValue: (value: CommentType) => void;
}
export const Editor: React.FC<Props> = ({
  submit,
  handleFinish,
  segmentValue,
  setSegmentValue,
}): JSX.Element => {
  const { t } = useTranslation('button');
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [name, setName] = React.useState<CommentType>('text');
  const handleForm = (value) => {
    handleFinish(value);
    form.resetFields();
  };
  const handleSegmented = (value: string | number) => {
    if (['image', 'file', 'audio'].includes(value.toString())) {
      setVisible(true);
      setName(value as CommentType);
      setSegmentValue(value as CommentType);
    }
  };
  const handleCloseModal = () => {
    setSegmentValue('text');
  };
  React.useEffect(() => {
    if (segmentValue === 'text') {
      setVisible(false);
    }
  }, [segmentValue]);
  return (
    <>
      <Segmented
        value={segmentValue}
        onChange={handleSegmented}
        options={[
          {
            label: t('Text'),
            value: 'text',
          },
          {
            label: t('File'),
            value: 'file',
            icon: <FileTextOutlined />,
          },
          {
            label: t('Image'),
            value: 'image',
            icon: <FileImageOutlined />,
          },
          {
            label: t('Audio'),
            value: 'audio',
            icon: <AudioOutlined />,
          },
        ]}
      />
      <Form form={form} onFinish={handleForm}>
        <Form.Item name="content">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={submit}>
            {t('add_comment')}
          </Button>
        </Form.Item>
      </Form>
      <UploadFile
        segmentValue={segmentValue}
        submit={submit}
        visible={visible}
        name={name}
        handleFinish={handleFinish}
        handleCloseModal={handleCloseModal}
      />
      <Record
        handleCloseModal={handleCloseModal}
        submit={submit}
        visible={visible}
        name={name}
        handleFinish={handleFinish}
      />
    </>
  );
};
