import { SignStatus } from '../store/inbox';
import { IboxGetQueryParams, IDeleteFileAction, IPostFileAction } from '../store/inbox/type';
import { axiosInstant } from './../axiosInstant';
export const getInbox = (query_params?: IboxGetQueryParams) =>
  axiosInstant.get('/documents/me/', {
    params: { ...query_params, sort_schedule: 'desc' }
  }
  ).then((res) => res);

export const getInboxById = (id: string, category_id: string) => {
  return axiosInstant
    .get(`/documents/me/${id}/detail/${category_id}/category`)
    .then((res) => res);
};

export const patchInboxByID = (id: string, sign_status: SignStatus) => {
  return axiosInstant
    .patch(`/documents/me/${sign_status}/${id}/id`)
    .then((res) => res);
};

export const deleteFile = (payload: IDeleteFileAction['payload']) =>
  axiosInstant.delete(`/file/${payload.id}`).then((res) => res);

export const postFile = (payload: IPostFileAction['payload']) =>
  axiosInstant
    .post(`/file/${payload.document_id}`, payload.form)
    .then((res) => res);
