import * as types from './type';

export const getCurrenciesAction = (): types.IGetCurrenciesAction => ({
  type: types.DocumentActionTypes.GET_CURRENCIES,
});

export const getCurrenciesSuccessAction = (
  payload: types.ICurrency[]
): types.IGetCurrenciesSuccessAction => ({
  type: types.DocumentActionTypes.GET_CURRENCIES_SUCCESS,
  payload,
});

export const getDocumentRanksAction = (): types.IGetDocumentRanksAction => ({
  type: types.DocumentActionTypes.GET_DOCUMENT_RANKS,
});

export const getDocumentRanksSuccessAction = (
  payload: types.IDocumentRank[]
): types.IGetDocumentRanksSuccessAction => ({
  type: types.DocumentActionTypes.GET_DOCUMENT_RANKS_SUCCESS,
  payload,
});
