import * as React from 'react';

const Inbox = React.lazy(() => import('./Inbox'));
const Detail = React.lazy(() => import('./detail/Detail'));

export const inboxRoute = [
  {
    path: '/inbox/',
    children: [
      {
        path: '',
        element: <Inbox />,
        index: true,
      },
      {
        path: ':id/detail/:category/category/:table_form',
        element: <Detail />,
      },
    ],
  },
];
