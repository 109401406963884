import { notification } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { NotificationState } from '../../store/utility';

export const Notification: React.FC = () => {
  const { t } = useTranslation('notification');
  const showNotification = useSelector<AppState, NotificationState>(
    ({ notification }) => notification
  );
  React.useEffect(() => {
    if (showNotification.show) {
      notification[showNotification.data.icon]({
        message: showNotification.data.message
          ? t(showNotification.data.message)
          : '',
        description: showNotification.data.description
          ? t(showNotification.data.description)
          : '',
        duration: 2,
      });
    }
  }, [showNotification, t]);
  return <></>;
};
