import { Avatar, Button, Comment, Image, List } from 'antd';
import * as React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from "react-i18next";
import * as comment from '../../../store/comment';
import { formatDatetimeToInformal } from '../../../Utils';
interface Props {
  comments: comment.Comment[];
}

export const CommentList: React.FC<Props> = ({ comments }) => {
  const { t } = useTranslation('document');
  const content = (cm: comment.Comment): React.ReactNode => {
    switch (cm.comment_type) {
      case 'image':
        return <Image width={200} src={cm.link} alt={cm.content} />;
      case 'text':
        return cm.content;
      case 'file':
        return (
          <Button href={cm.link} type="link" target="_blank">
            {cm.content}
          </Button>
        );
      case 'audio':
        return <ReactAudioPlayer src={cm.link} controls />;
      default:
        break;
    }
  };

  return (
    <List
      dataSource={comments}
      header={`${comments.length} ${comments.length > 1 ? t('replies') : t('reply')}`}
      itemLayout="horizontal"
      renderItem={(props) => (
        <Comment
          datetime={formatDatetimeToInformal(props.created_at)}
          author={`${props.commenter.first_name} ${props.commenter.last_name}`}
          content={content(props)}
          avatar={
            props.commenter.avatar ? (
              props.commenter.avatar
            ) : (
              <Avatar src={props.commenter.avatar} alt="Profile" />
            )
          }
        />
      )}
    />
  );
};
