import { axiosInstant } from '../axiosInstant';
import { IChangePassword, IPatchProfile, IUploadProfileImage, IUploadSignatureImage } from "../store/profile/type";

export const fetchMe = () => axiosInstant.get('/me').then(({ data }) => data);

export const updateProfile = (data: IPatchProfile) =>
  axiosInstant
    .patch(`/me`, {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone
    })
    .then((res) => res);

export const updateProfileImage = (data: IUploadProfileImage) =>
  axiosInstant
    .patch(`/users/avatar`, data.avatar)
    .then((res) => res);

export const updateSignatureImage = (payload: IUploadSignatureImage) =>
  axiosInstant
    .patch(`/users/signature`, payload.signature)
    .then((res) => res);

export const changePassword = (data: IChangePassword) =>
  axiosInstant
    .post(`/users/change-password`, {
      new_password: data.new_password,
      old_password: data.old_password,
    })
    .then((res) => res);