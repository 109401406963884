import { AxiosResponse } from 'axios';
import { channel } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { subscribeErrorMessage, subscribeSuccessMessage } from '../saga';
import * as services from '../../service/Profile';
import { handleProfile } from './action';
import * as types from './type';
import { IUser } from "../user/type";
import { AuthPermission } from "../auth/type";
import { Permission } from "../position/type";
import { Logout } from "../../Utils";

function* handlerUpdate({ payload }: types.Action<'UPDATE_PROFILE'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateProfile,
      payload
    )) as AxiosResponse<types.IPatchProfile>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'EDIT_PROFILE', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(handleProfile({ type: 'FETCH_ME', payload: payload }));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlerFetchMe() {
  try {
    const res = (yield call(services.fetchMe)) as any;
    if (res.status !== 'ACTIVE') {
      Logout();
    } else {
      const profile = res as IUser;
      if (res.permissions) {
        const permission = (yield res.permissions.reduce(
          (acc: any, obj: Permission): AuthPermission => {
            let action = {};
            if (obj.domain in acc === false) {
              action = res.permissions.reduce(
                (
                  accAction: any,
                  objAction: Permission
                ): AuthPermission => {
                  if (
                    objAction.action in accAction === false &&
                    obj.domain === objAction.domain
                  ) {
                    return { ...accAction, [objAction.action]: true };
                  }
                  return accAction;
                },
                {}
              );
              return { ...acc, [obj.domain]: action };
            }
            return acc;
          },
          {}
        )) as AuthPermission;
        profile.permissions = permission;
      }
      yield put(handleProfile({ type: 'RECEIVE_ME', payload: profile }));
    }
  } catch (error) {
    Logout();
  }
}

function* handlerUploadProfileImage({ payload }: types.Action<'UPLOAD_PROFILE_IMAGE'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateProfileImage,
      payload
    )) as AxiosResponse<types.IUploadProfileImage>;
    if (res.status === 201) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'UPLOAD_PROFILE_IMAGE', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(handleProfile({ type: 'FETCH_ME', payload: payload }));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlerUploadSignatureImage({ payload }: types.Action<'UPLOAD_SIGNATURE_IMAGE'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.updateSignatureImage,
      payload
    )) as AxiosResponse<types.IUploadSignatureImage>;

    if (res.status === 201) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'UPLOAD_SIGNATURE_IMAGE', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(handleProfile({ type: 'FETCH_ME', payload: payload }));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* handlerChangePassword({ payload }: types.Action<'CHANGE_PASSWORD'>) {
  const chan = (yield call(channel)) as any;
  try {
    const res = (yield call(
      services.changePassword,
      payload
    )) as AxiosResponse<types.IChangePassword>;
    if (res.status === 200) {
      const chan = (yield call(channel)) as any;
      yield put(chan, { name: 'CHANGE_PASSWORD', status: true });
      yield fork(subscribeSuccessMessage, chan);
      yield put(handleProfile({ type: 'FETCH_ME', payload: payload }));
    }
  } catch ({ response }) {
    const res = response as AxiosResponse;
    yield put(chan, res);
    yield fork(subscribeErrorMessage, chan);
  }
}

function* watchHandlerUpdate() {
  yield takeLatest('UPDATE_PROFILE', handlerUpdate);
}

function* watchHandlerFetchMe() {
  yield takeLatest('FETCH_ME', handlerFetchMe);
}

function* watchHandlerUploadProfileImage() {
  yield takeLatest('UPLOAD_PROFILE_IMAGE', handlerUploadProfileImage);
}

function* watchHandlerUploadSignatureImage() {
  yield takeLatest('UPLOAD_SIGNATURE_IMAGE', handlerUploadSignatureImage);
}

function* watchHandlerChangePassword() {
  yield takeLatest('CHANGE_PASSWORD', handlerChangePassword);
}

export function* profileSaga() {
  yield all([
    fork(watchHandlerUpdate),
    fork(watchHandlerFetchMe),
    fork(watchHandlerUploadProfileImage),
    fork(watchHandlerUploadSignatureImage),
    fork(watchHandlerChangePassword),
  ]);
}
