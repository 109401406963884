import { IPublicCompany } from '../company/type';
import { Moment } from 'moment';
import { TableForms } from '../category';
import {
  IAdvanceClearanceDocument,
  IAdvanceRequestDocument,
  IExternalDocument,
  IPaymentVoucherDocument,
  IPaymentVoucherDocumentItem,
  IPurchaseOrderDocument,
  MoneyTreasury,
  PaymentMethod,
  IAdvanceClearanceDocumentItem,
  DocumentStatus,
} from '../document/type';
import { Comments } from '../comment';
import { Assigns, Files } from '../inbox';
export interface IDocOut {
  id: string;
  company: IPublicCompany;
  reference_no: string;
  docout_status: DocumentStatus;
  created_at: Moment;
  created_by: string;
  pdf_path: string;
}
export interface IDocoutCategory {
  external_documents: IExternalDocout[];
  advance_clearance_documents: IAdvanceClearanceDocout[];
  advance_request_documents: IAdvanceRequestDocout[];
  payment_voucher_documents: IPaymentVoucherDocout[];
  purchase_order_documents: IPurchaseOrderDocout[];
}
export interface DocoutDetailCategory {
  external_documents?: IExternalDocout;
  advance_clearance_documents?: IAdvanceClearanceDocout;
  advance_request_documents?: IAdvanceRequestDocout;
  payment_voucher_documents?: IPaymentVoucherDocout;
  purchase_order_documents?: IPurchaseOrderDocout;
}
export interface DocoutGetQueryParams {
  document_status?: DocumentStatus;
  reference_no?: string;
  applicant?: string;
  supplier_name?: string;
  payment_method?: PaymentMethod;
  currency?: number;
  total_money?: number;
  account_number?: string;
}
export interface IDocoutState {
  readonly category: IDocoutCategory;
  readonly category_detail: DocoutDetailCategory;
  readonly loading: boolean;
}
export interface IPostPurchaseOrderDocoutItem {
  quantity: number;
  price: number;
  purchase_purpose?: string;
  detail: string;
}
export interface IPostPurchaseOrderDocout {
  document_date: Moment;
  document_rank_id: string;
  currency_id: number;
  purchase_date: Moment;
  debt_time?: Moment;
  payment_date?: Moment;
  supplier_name?: string;
  supplier_code?: string;
  supplier_address?: string;
  supplier_phone?: number;
  payment_method: PaymentMethod;
  bank_name?: string;
  account_name?: string;
  account_number?: string;
  discount: number;
  vat: number;
  save_purchase_order_items: IPostPurchaseOrderDocoutItem[];
}
export interface IPatchPurchaseOrder extends IPostPurchaseOrderDocout { }

export interface IPostPaymentVoucher {
  document_date: Moment;
  document_rank_id: string;
  currency_id: number;
  amount: number;
  date_spent_money?: Moment;
  payment_method: PaymentMethod;
  money_treasury?: MoneyTreasury;
  bank_name?: string;
  account_name?: string;
  account_number?: string;
  cheque_number?: string;
  cheque_date: Moment;
  save_payment_voucher_items: ISavePaymentsVoucherItem[];
}
export interface IPatchPaymentVoucher extends IPostPaymentVoucher { }

export interface IPostAdvanceRequest {
  document_date: Moment;
  document_rank_id: string;
  reason: string;
  amount: number;
  total: number;
  repayment_date?: Moment;
  borrow_money_before: number;
  borrow_money_addition: number;
  condition_1?: string;
  condition_2?: string;
  condition_3?: string;
  currency_id: number;
  // payment_method: PaymentMethod;
}
export interface IPatchAdvanceRequest extends IPostAdvanceRequest { }

export interface IPostExternalDocument {
  document_rank_id: string;
  document_date: Moment;
  document_from?: string;
  document_refer?: string;
  document_title?: string;
  contact_number?: string;
}

export interface IPatchExternalDocument extends IPostExternalDocument { }

export interface IPostAdvanceClearanceDocument {
  document_date: Moment;
  document_rank_id: string;
  advance_clearance_amount: number;
  payment: number;
  currency_id: number;
  phone?: string;
  save_advance_clearance_document_items: IAdvanceClearanceDocumentItem[];
}

export interface IPatchAdvanceClearanceDocument extends IPostAdvanceClearanceDocument { }

export interface IPostAdvanceClearanceDocumentItem
  extends IAdvanceClearanceDocumentItem { }

export interface ISavePaymentsVoucherItem extends IPaymentVoucherDocumentItem { }

export interface IExternalDocout
  extends IExternalDocument,
  Comments,
  Files,
  Assigns {
  doc_out: IDocOut;
}
export interface IAdvanceClearanceDocout
  extends IAdvanceClearanceDocument,
  Comments,
  Files,
  Assigns {
  doc_out: IDocOut;
}
export interface IAdvanceRequestDocout
  extends IAdvanceRequestDocument,
  Comments,
  Files,
  Assigns {
  doc_out: IDocOut;
}
export interface IPaymentVoucherDocout
  extends IPaymentVoucherDocument,
  Comments,
  Files,
  Assigns {
  doc_out: IDocOut;
}
export interface IPurchaseOrderDocout
  extends IPurchaseOrderDocument,
  Comments,
  Files,
  Assigns {
  doc_out: IDocOut;
}

export interface IPostSignDocument {
  assigned: string;
  signer: boolean;
}

export interface IUpdateSignDocument {
  assigned: string;
  signer: boolean;
}

export interface IDeleteSignDocin {
  assigned: string;
  signer: boolean;
  sign_document_id: string;
  payload: {
    id: string;
  };
}

export enum DocoutActionTypes {
  GET_DOCOUT_BY_CATEGORY = `@docout/GET_BY_CATEGORY`,
  GET_DOCOUT_BY_CATEGORY_SUCCESS = `@docout/GET_BY_CATEGORY_SUCCESS`,
  POST_PURCHASE_ORDER_DOCOUT = `@docout/POST_PURCHASE_ORDER_DOCOUT`,
  POST_PAYMENT_VOUCHER_DOCOUT = `@docout/POST_PAYMENT_VOUCHER_DOCOUT`,
  POST_ADVANCE_REQUEST_DOCOUT = `@docout/POST_ADVANCE_REQUEST_DOCOUT`,
  POST_EXTERNAL_DOCOUT = `@docout/POST_EXTERNAL_DOCOUT`,
  POST_ADVANCE_CLEARANCE_DOCOUT = `@docout/POST_ADVANCE_CLEARANCE_DOCOUT`,
  POST_SIGN_DOCOUT = `@docout/POST_SIGN_DOCOUT`,
  PUT_SIGN_DOCOUT = `@docout/PUT_SIGN_DOCOUT`,
  DELETE_SIGN_DOCOUT = `@docout/DELETE_SIGN_DOCOUT`,
  SEND_DOCUMENT = '@docout/SEND_DOCUMENT',
  GET_DOCOUT_BY_ID = '@docout/GET_DOCOUT_BY_ID',
  GET_DOCOUT_BY_ID_SUCCESS = '@docout/GET_DOCOUT_BY_ID_SUCCESS',
  PATCH_ADVANCE_REQUEST_DOCOUT = '@docout/PATCH_ADVANCE_REQUEST_DOCOUT',
  PATCH_ADVANCE_CLEARANCE_DOCOUT = '@docout/PATCH_ADVANCE_CLEARANCE_DOCOUT',
  PATCH_PURCHASE_ORDER_DOCOUT = '@docout/PATCH_PURCHASE_ORDER_DOCOUT',
  PATCH_PAYMENT_VOUCHER_DOCOUT = '@docout/PATCH_PAYMENT_VOUCHER_DOCOUT',
  PATCH_EXTERNAL_DOCOUT = '@docout/PATCH_EXTERNAL_DOCOUT',
  PATCH_DOCOUT_STATUS = '@docout/PATCH_DOCOUT_STATUS',
}

export interface IGetDocoutByCategory {
  type: DocoutActionTypes.GET_DOCOUT_BY_CATEGORY;
  payload: {
    category_id: string;
    table_form: TableForms;
    query_params?: DocoutGetQueryParams;
  };
}
export interface IGetDocoutByCategorySuccessAction {
  type: DocoutActionTypes.GET_DOCOUT_BY_CATEGORY_SUCCESS;
  payload: IDocoutCategory;
}
export interface IPostPurchaseOrderDocoutAction {
  type: DocoutActionTypes.POST_PURCHASE_ORDER_DOCOUT;
  payload: {
    form: IPostPurchaseOrderDocout;
    category_id: string;
  };
}
export interface IPostPaymentVoucherDocoutAction {
  type: DocoutActionTypes.POST_PAYMENT_VOUCHER_DOCOUT;
  payload: {
    form: IPostPaymentVoucher;
    category_id: string;
  };
}
export interface IPostAdvanceRequestDocoutAction {
  type: DocoutActionTypes.POST_ADVANCE_REQUEST_DOCOUT;
  payload: {
    form: IPostAdvanceRequest;
    category_id: string;
  };
}
export interface IPostExternalDocoutAction {
  type: DocoutActionTypes.POST_EXTERNAL_DOCOUT;
  payload: {
    form: IPostExternalDocument;
    category_id: string;
  };
}

export interface IPostAdvanceClearanceDocoutAction {
  type: DocoutActionTypes.POST_ADVANCE_CLEARANCE_DOCOUT;
  payload: {
    form: IPostAdvanceClearanceDocument;
    category_id: string;
  };
}

export interface IPostSignDocoutAction {
  type: DocoutActionTypes.POST_SIGN_DOCOUT;
  payload: {
    form: IPostSignDocument[];
    category_id: string;
    table_form: TableForms;
    docout_id: string;
  };
}

export interface IUpdateSignDocoutAction {
  type: DocoutActionTypes.PUT_SIGN_DOCOUT;
  payload: {
    form: IUpdateSignDocument[];
    category_id: string;
    table_form: TableForms;
    docout_id: string;
  };
}

export interface IDeleteSignDocoutAction {
  type: DocoutActionTypes.DELETE_SIGN_DOCOUT;
  payload: {
    docout_id: string;
    assigned_id: string;
  };
}

export interface ISendDocumentAction {
  type: DocoutActionTypes.SEND_DOCUMENT;
  payload: {
    document_id: string;
    company_id: string;
    table_forms: TableForms;
  };
}

export interface IGetDocumentByIdAction {
  type: DocoutActionTypes.GET_DOCOUT_BY_ID;
  payload: {
    id: string;
    category_id: string;
  };
}
export interface IGetDocumentByIdSuccessAction {
  type: DocoutActionTypes.GET_DOCOUT_BY_ID_SUCCESS;
  payload: DocoutDetailCategory;
}
export interface IPatchAdvanceRequestDocoutAction {
  type: DocoutActionTypes.PATCH_ADVANCE_REQUEST_DOCOUT;
  payload: {
    form: IPatchAdvanceRequest;
    category_id: string;
    id: string;
    docout_id: string;
  };
}
export interface IPatchAdvanceClearanceDocoutAction {
  type: DocoutActionTypes.PATCH_ADVANCE_CLEARANCE_DOCOUT;
  payload: {
    form: IPatchAdvanceClearanceDocument;
    category_id: string;
    id: string;
    docout_id: string;
  };
}
export interface IPatchPurchaseOrderDocoutAction {
  type: DocoutActionTypes.PATCH_PURCHASE_ORDER_DOCOUT;
  payload: {
    form: IPatchPurchaseOrder;
    category_id: string;
    id: string;
    docout_id: string;
  };
}
export interface IPatchPaymentVoucherDocoutAction {
  type: DocoutActionTypes.PATCH_PAYMENT_VOUCHER_DOCOUT;
  payload: {
    form: IPatchPaymentVoucher;
    category_id: string;
    id: string;
    docout_id: string;
  };
}
export interface IPatchExternalDocoutAction {
  type: DocoutActionTypes.PATCH_EXTERNAL_DOCOUT;
  payload: {
    form: IPatchExternalDocument;
    category_id: string;
    id: string;
    docout_id: string;
  };
}
export interface IPatchDocoutStatusAction {
  type: DocoutActionTypes.PATCH_DOCOUT_STATUS;
  payload: {
    docout_id: string;
    document_category: string;
    table_forms: TableForms;
    query_params?: DocoutGetQueryParams;
  };
}

export type DocoutActions =
  | IGetDocoutByCategory
  | IPostPurchaseOrderDocoutAction
  | IPostPaymentVoucherDocoutAction
  | IPostAdvanceRequestDocoutAction
  | IPostExternalDocoutAction
  | IPostAdvanceClearanceDocoutAction
  | IPostSignDocoutAction
  | ISendDocumentAction
  | IGetDocoutByCategorySuccessAction
  | IGetDocumentByIdAction
  | IGetDocumentByIdSuccessAction
  | IPatchAdvanceRequestDocoutAction
  | IPatchAdvanceClearanceDocoutAction
  | IPatchPurchaseOrderDocoutAction
  | IPatchPaymentVoucherDocoutAction
  | IPatchExternalDocoutAction
  | IPatchDocoutStatusAction;
