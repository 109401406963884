import * as React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Modal,
} from 'antd';
import { LaoITDevCardAdd } from '../../utility';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import {
  getCurrenciesAction,
  IDocumentState,
  getDocumentRanksAction,
} from '../../../store/document';
import { EditAdvanceClearanceItem } from './EditAdvanceClearanceItem';
import moment from 'moment';
import { dateInformal, formatMoney } from '../../../Utils';
import { IDocoutState, IPostAdvanceClearanceDocument } from '../../../store/docout';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { patchAdvanceClearanceDocoutAction } from '../../../store/docout/action';
import {
  ErrorState,
  handleError,
  handleSuccess,
  initialSuccessState,
  SuccessState,
} from '../../../store/utility';
import { history } from '../../../config/configureStore';
const { Item } = Form;
const { Option } = Select;
const { confirm } = Modal;
export const EditAdvanceClearance: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { docout_id, category } = useParams<{ docout_id: string, category: string }>();
  const { t } = useTranslation(['document', 'button']);

  const [total, setTotal] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [submit, setSubmit] = React.useState(false);
  const [paymentBalance, setPaymentBalance] = React.useState(0);

  const {
    category_detail: { advance_clearance_documents },
  } = useSelector<AppState, IDocoutState>((state) => state.docout);
  const { currency, document_rank } = useSelector<AppState, IDocumentState>(
    ({ document }) => document
  );
  const submitSuccess = useSelector<AppState, SuccessState>(
    ({ success }) => success
  );
  const errorOnSubmit = useSelector<AppState, ErrorState>(({ error }) => error);

  const handleBlurPrice = () => {
    const { save_advance_clearance_document_items, advance_clearance_amount } =
      form.getFieldsValue();
    const total = save_advance_clearance_document_items.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setAmount(advance_clearance_amount);
    setTotal(total);
    setPaymentBalance(advance_clearance_amount - total);
  };

  const handleFinish = (value: IPostAdvanceClearanceDocument) => {
    confirm({
      title: t('confirm_submit_document'),
      icon: <ExclamationCircleOutlined />,
      okText: t('yes', { ns: 'button' }),
      cancelText: t('no', { ns: 'button' }),
      onOk() {
        setSubmit(true);
        if (docout_id && category && advance_clearance_documents) {
          dispatch(patchAdvanceClearanceDocoutAction(value, category, advance_clearance_documents.id, docout_id));
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  React.useEffect(() => {
    if (currency.currencies.length > 0) {
      form.setFieldsValue({
        currency_id: currency.currencies[0].id,
      });
    }
    if (document_rank.document_ranks.length > 0) {
      form.setFieldsValue({
        document_rank_id: document_rank.document_ranks[0].id,
      });
    }
  }, [currency, form, document_rank]);

  React.useEffect(() => {
    dispatch(getCurrenciesAction());
    dispatch(getDocumentRanksAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (
      submitSuccess.status &&
      submitSuccess.name === 'EDIT_ADVANCE_CLEARANCE_DOCOUT'
    ) {
      dispatch(
        handleSuccess({
          type: 'SET_SUCCESS',
          payload: initialSuccessState,
        })
      );
      history.push('docout');
    }
    if (
      errorOnSubmit.status &&
      errorOnSubmit.name === 'EDIT_ADVANCE_CLEARANCE_DOCOUT'
    ) {
      dispatch(
        handleError({
          type: 'SET_ERROR',
          payload: initialSuccessState,
        })
      );
    }
    setSubmit(false);
  }, [dispatch, submitSuccess, errorOnSubmit]);

  React.useEffect(() => {
    form.setFieldsValue({
      document_date: moment(advance_clearance_documents?.document_date),
      document_rank_id: advance_clearance_documents?.document_rank.id,
      currency_id: advance_clearance_documents?.currency.id,
      advance_clearance_amount: advance_clearance_documents?.advance_clearance_amount,
      phone: advance_clearance_documents?.phone,
    });

    setAmount(advance_clearance_documents?.advance_clearance_amount ? advance_clearance_documents?.advance_clearance_amount : 0);
    setTotal(advance_clearance_documents?.payment ? advance_clearance_documents?.payment : 0);
    setPaymentBalance(advance_clearance_documents?.payment_balance ? advance_clearance_documents?.payment_balance : 0);
  }, [advance_clearance_documents, dispatch, form]);

  return (
    <>
      <LaoITDevCardAdd title={t('edit_advance_clearance')}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" plain>
            {t('advance_clearance_detail')}
          </Divider>
          <Row gutter={[24, 0]} justify="start">
            <Col xxl={6} lg={6} md={12} sm={12} xs={24}>
              <Item
                name="payment_method"
                label={t('advance_request_reference_no')}
                required
              >
                <Input placeholder={t('input_advance_request_reference_no')} />
              </Item>
            </Col>
            <Col
              xxl={4}
              lg={4}
              md={12}
              sm={12}
              xs={24}
              style={{ margin: 'auto 0' }}
            >
              <Button
                htmlType="button"
                type="primary"
                style={{ marginTop: '5px' }}
              >
                {t('search', { ns: 'button' })}
              </Button>
            </Col>
          </Row>
          <Row gutter={[24, 0]}>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('document_date')}
                name="document_date"
                required
                initialValue={moment()}
                rules={[
                  { required: true, message: t('document_date_required') },
                ]}
              >
                <DatePicker
                  format={dateInformal}
                  style={{ width: '100%' }}
                  placeholder={t('input_document_date')}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('document_rank')} name="document_rank_id">
                <Select>
                  {document_rank &&
                    document_rank.document_ranks.map((document_rank) => (
                      <Option key={document_rank.id} value={document_rank.id}>
                        {document_rank.name}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('currency')} name="currency_id">
                <Select>
                  {currency &&
                    currency.currencies.map((currency) => (
                      <Option key={currency.id} value={currency.id}>
                        {currency.code}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item
                label={t('advance_clearance_amount')}
                name="advance_clearance_amount"
                initialValue={0}
              >
                <InputNumber
                  placeholder={t('input_advance_clearance_amount')}
                  style={{ width: '100%' }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  onBlur={handleBlurPrice}
                />
              </Item>
            </Col>
            <Col xxl={6} lg={6} md={8} sm={12} xs={24}>
              <Item label={t('phone')} name="phone">
                <Input placeholder={t('input_phone')} />
              </Item>
            </Col>
          </Row>
          <Divider />
          <EditAdvanceClearanceItem
            handleBlurPrice={handleBlurPrice}
            advance_clearance_document_items={advance_clearance_documents?.advance_clearance_document_items}
          />
          <div className="summary-payment">
            <Divider />
            <Row gutter={[16, 0]}>
              <Col xxl={8} lg={8} md={8} sm={16} xs={24}>
                <Item>{t('advance_clearance_amount')}</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{formatMoney(amount)}</Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xxl={8} lg={8} md={8} sm={16} xs={24}>
                <Item>{t('total_payment')}</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{formatMoney(total)}</Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xxl={8} lg={8} md={8} sm={16} xs={24}>
                <Item>{t('total_advance_clearance_balance')}</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>-</Item>
              </Col>
              <Col xxl={4} lg={4} md={8} sm={16} xs={24}>
                <Item>{formatMoney(paymentBalance)}</Item>
              </Col>
            </Row>
          </div>
          <Divider />
          <Row justify="space-between">
            <Col>
              <Button onClick={() => history.back()} size="large" loading={submit}>
                {t('cancel', { ns: 'button' })}
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={submit}
              >
                {t('save', { ns: 'button' })}
              </Button>
            </Col>
          </Row>
        </Form>
      </LaoITDevCardAdd>
    </>
  );
};
